import React, { useState } from 'react'
import { Accordion, Card, Button, Form, Col } from 'react-bootstrap'
import './style.css'

export default function Career() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='container' style={{ height: isOpen ? '1020px' : '800px' }}>
      <div>
        <h3>We are looking for</h3>
        <h3>new team player!</h3>
      </div>
      <div className='row'>
        <div className='column'>
          <Accordion defaultActiveKey='0'>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  onClick={() => setIsOpen(!isOpen)}
                  as={Button}
                  variant='link'
                  eventKey='0'
                >
                  <span>Senior PHP Developer</span>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      class='bi bi-caret-down'
                      viewBox='0 0 16 16'
                    >
                      <path d='M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z' />
                    </svg>
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='0'>
                <Card.Body>
                  <p>Senior PHP Developer</p>
                  <p>
                    We are looking for a Full Stack Developer to produce
                    scalable software solutions. You’ll be part of a small,
                    cross-functional team that’s responsible for the full
                    software development life cycle, from conception to
                    deployment.
                  </p>
                  <p>Requirements:</p>
                  <ul>
                    <li>Proven experience as a Full Stack Developer;</li>
                    <li>
                      Ability to deduce patterns and approaches within existing
                      code bases with ease;
                    </li>
                    <li>
                      Substantial expertise in at least one programming
                      environment, preferably NodeJS;
                    </li>
                    <li>
                      Ability to translate existing design prototype into
                      functional web app;
                    </li>
                    <li>
                      Ability to demonstrate common workflows in version
                      control;
                    </li>
                    <li>Familiarity with HTML, CSS, and JavaScript;</li>
                    <li>
                      Good understanding on common techniques used in frontend
                      integration, eg. REST API, GraphQL, etc;
                    </li>
                    <li>Experienced in Agile software development process;</li>
                  </ul>

                  <p>Of Benefit</p>
                  <ul>
                    <li>
                      Expertise in Linux system administration and Docker
                      containers;
                    </li>
                    <li>Knowledge with Blockchain technology;</li>
                    <li>Expertise in IoT</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  onClick={() => {
                    setIsOpen(!isOpen)
                    window.scroll(0, 0)
                  }}
                  as={Button}
                  variant='link'
                  eventKey='1'
                >
                  Senior Python Developer
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      class='bi bi-caret-down'
                      viewBox='0 0 16 16'
                    >
                      <path d='M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z' />
                    </svg>
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='1'>
                <Card.Body>
                  <p>Senior Python Developer</p>
                  <p>
                    We are looking for a Full Stack Developer to produce
                    scalable software solutions. You’ll be part of a small,
                    cross-functional team that’s responsible for the full
                    software development life cycle, from conception to
                    deployment.
                  </p>
                  <p>Requirements:</p>
                  <ul>
                    <li>Proven experience as a Full Stack Developer;</li>
                    <li>
                      Ability to deduce patterns and approaches within existing
                      code bases with ease;
                    </li>
                    <li>
                      Substantial expertise in at least one programming
                      environment, preferably NodeJS;
                    </li>
                    <li>
                      Ability to translate existing design prototype into
                      functional web app;
                    </li>
                    <li>
                      Ability to demonstrate common workflows in version
                      control;
                    </li>
                    <li>Familiarity with HTML, CSS, and JavaScript;</li>
                    <li>
                      Good understanding on common techniques used in frontend
                      integration, eg. REST API, GraphQL, etc;
                    </li>
                    <li>Experienced in Agile software development process;</li>
                  </ul>

                  <p>Of Benefit</p>
                  <ul>
                    <li>
                      Expertise in Linux system administration and Docker
                      containers;
                    </li>
                    <li>Knowledge with Blockchain technology;</li>
                    <li>Expertise in IoT</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        <div className='column'>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId='formGridEmail'>
                <Form.Control type='email' placeholder='Email' />
              </Form.Group>
            </Form.Row>
            <br />
            <Form.Group controlId='formGridAddress1'>
              <Form.Control placeholder='First Name' />
            </Form.Group>
            <br />
            <Form.Group controlId='formGridAddress2'>
              <Form.Control placeholder='Last Name' />
            </Form.Group>
            <br />
            <Form.Group controlId='formGridAddress3'>
              <Form.Control placeholder='Phone Number' />
            </Form.Group>
            <br />
            <Form.Group controlId='formGridAddress4'>
              <Form.Control placeholder='Interested Position' />
            </Form.Group>
            <br />
            <Button variant='primary' type='submit'>
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}
