import { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import PrivateRouting from "./routes/PrivateRouting";

import HomePage from "./Pages/HomePage";
import TopBar from "./Components/TopBar/TopBar";
import Navbar from "./Components/NavBar/Navbar";
import SubFooter from "./Components/Footer/SUB/SubFooter";

import Login from "./Components/User/Login/Login";
import Signup from "./Components/User/Signup/Signup";
import Reset from "./Components/User/Reset/Reset";
import Profile from "./Components/User/Profile/Profile";
import Verification from "./Components/User/Verificartion/Verification";
import TicketHistory from "./Components/User/History/TicketHistory";

import SearchResult from "./Components/SearchResult/SearchResult";
import TicketBook from "./Components/Booking/TicketBook";

import Invoice from "./Components/Ticketing/Invoice";
import VisaComponent from "./Components/Visa/VisaComponent";
import PackageList from "./Components/Packages/PackageList";
import PackageDetails from "./Components/Packages/PackageDetails";
import ForgetPassword from "./Components/User/Forget/ForgetPassword";

import ManageBooking from "./Components/ManageBooking/ManageBooking";
import ChnagePassword from "./Components/User/Profile/ChnagePassword";

import Career from "./Components/Career/Career";
import Support from "./Components/Career/Support";
import TermCondition from "./Components/Career/TermCondition";
import PrivacyPolicy from "./Components/Career/PrivacyPolicy";
import FAQ from "./Components/FAQ/FAQ";
import OnDev from "./Components/AppOnDev/OnDev";

import FourOeroFour from "./Components/404/FourOeroFour";
import TicketPage from "./Components/TicketPag3/TicketPage";
import TicketDownload from "./Components/TicketPag3/TicketDownload";
import Cookies from "universal-cookie";

import "./custom.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./routes/RequireAuth";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
export const mainContextApi = createContext();

const App = () => {
  const cookies = new Cookies();

  const [IsLogedin, setIsLogedin] = useState(false);
  const [Redirected, setRedirected] = useState(false);
  const [userIMG, setuserIMG] = useState(null);
  const [cookie] = useCookies(["auth_info"]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (cookie.auth_info) {
      setIsLogedin(true);

      if (localStorage.getItem("_user_id_")) {
        fetch(`${process.env.REACT_APP_DEV_API_URL}/user/me`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              localStorage.setItem("ml", data.data.email);
              setuserIMG(data.data.profile_pic);
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("_user_id_");
              cookies.remove("auth_info");
              cookies.remove("_user_id_");
              cookies.remove("token");
              localStorage.removeItem("ml");
              setIsLogedin(false);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong! Please try again later.");
          });
      }
    }
  }, [cookie]);

  return (
    <BrowserRouter 
    // basename="/subah-frontend"
    >
      <mainContextApi.Provider
        value={{
          IsLogedin,
          LogIn: setIsLogedin,
          Redirected,
          red: setRedirected,
          userIMG,
        }}
      >
        <TopBar />
        <Navbar userIMG={userIMG} />

        <Routes>
          <Route path="/">
            <Route index element={<HomePage />} />
            <Route path="/" element={<PrivateRouting />}>
              <Route path="/verification" element={<Verification />} />
              <Route path="/registration" element={<Signup />} />
            </Route>
            <Route path="/" element={<RequireAuth />}>
              <Route path="/user-profile" element={<Profile />} />
              <Route path="/thistory" element={<TicketHistory />} />
            </Route>

            <Route path="login" element={<Login />} />
            {/* <PrivateRouting path="login" element={<Login />} /> */}
            <Route path="search-results/:data" element={<SearchResult />} />
            <Route path="booking" element={<TicketBook />} />
            <Route path="invoice" element={<Invoice />} />
            <Route path="visa" element={<VisaComponent />} />
            <Route path="packages" element={<PackageList />} />
            <Route path="package-details/" element={<PackageDetails />} />
            <Route path="forget" element={<ForgetPassword />} />
            <Route path="resetuserpassword/" element={<Reset />} />
            <Route path="managebooking" element={<ManageBooking />} />
            <Route path="changepwd" element={<ChnagePassword />} />
            <Route path="career" element={<Career />} />
            <Route path="support" element={<Support />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="applink" element={<OnDev />} />
            <Route path="ticket" element={<TicketPage />} />
            <Route path="term-condition" element={<TermCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="download-ticket/:pnr" component={TicketDownload} />

            <Route path="/*" element={<FourOeroFour />} />
          </Route>
        </Routes>
        <SubFooter />
      </mainContextApi.Provider>
    </BrowserRouter>
  );
};

export default App;
