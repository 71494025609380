import { useState } from "react";
import Footer from "../FOOT/Footer";
import { Link } from "react-router-dom";
import "./style.css";
import logo from "../../../Components/NavBar/footerSkyTripLogo.png";

const SubFooter = () => {
  const [addressLink, setaddress] = useState("");

  setTimeout(() => {
    let address = localStorage.getItem("skytripAddress");
    setaddress(address);
  }, 1000);
  return (
    <div id="rakesh">
      {" "}
      <footer className="main-footer text-white">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-3 col-lg-3 col-xl-3 col-6 ">
              <div className="footer-about">
                <img src={logo} alt="logo" />

                <p className="adress"> {addressLink} </p>
                {/* <div className="d-flex justify-content-start align-items-center">
                  <p>Contact Us : </p>

                  <ul className="d-flex justify-content-center align-items-center list-unstyled">
                    <li
                      style={{
                        marginLeft: '10px',
                        background: 'white',
                        width: '23px',
                        borderRadius: '7px',
                      }}
                    >
                      <a
                        href="https://www.facebook.com/skytrip.com.bd/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/Vector.png"
                          alt="Instagram_logo_2016"
                          className="Instagram_logo"
                        />
                      </a>
                    </li>
                    <li style={{ marginLeft: '10px' }}>
                      <a
                        href="https://www.instagram.com/skytrip.com.bd"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/Instagram_logo_2016.png"
                          alt="Instagram_logo_2016"
                          className="Instagram_logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-6 ">
              <ul className="footer-link list-unstyled menu footer-menu mb-0">
                <li className="mb-2">
                  <div className="font-style-css">About</div>
                </li>
                <li className="mb-2">
                  <Link to="/career" className="link d-block font-size-link">
                    Careers
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/support" className="link d-block font-size-link">
                    Support
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/term-condition"
                    className="link d-block font-size-link"
                  >
                    Terms & Condition
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/privacy-policy"
                    className="link d-block font-size-link"
                  >
                    Privacy & Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 col-6 ">
              <ul className="footer-link list-unstyled menu footer-menu mb-0">
                <li className="mb-2">
                  <div className="font-style-css">Help</div>
                </li>
                <li className="mb-2">
                  <Link to="#" className="link d-block font-size-link">
                    FAQ
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="#" className="link d-block font-size-link">
                    Payment Method
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="#" className="link d-block font-size-link">
                    Contact Us
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="#" className="link d-block font-size-link">
                    Other
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 col-6 ">
              <h4 className="text-bold text-white fs-5">Have Any Question ?</h4>
              <div className="my-3">
                <a class="call-to-action" href="tel:+8801636470161">
                  (880) 163 6470161
                </a>

                <a
                  href="mailto:support@skytrip.com"
                  className="call-to-action text-decoration-none d-block py-1"
                >
                  support@skytrip.com
                </a>
              </div>
              <ul className="footer-link list-unstyled menu footer-menu mb-0">
                <li className="mb-2 font-size-link">
                  Dedicated Customer Support
                </li>
                <li className="mb-2 font-size-link">
                  Saturday-Thursday: 10.00 am - 06.00 pm
                </li>
              </ul>
            </div>

            <div className="col-12 text-center">
              <div className="text-center collaborate_logo ">
                <img
                  src="/assets/img/icon/IATAlogo.png"
                  alt=""
                  className="iata_logo"
                />
                <img src="/assets/img/icon/gelalioLogo.png" alt="" />
                <img src="/assets/img/icon/sabreLogo.png" alt="" />
              </div>

              <img
                src="/SSLCommerz-Pay.png"
                alt="ssl_logo"
                className="ssl_logo"
              />
            </div>
          </div>
        </div>
      </footer>
      <Footer />
    </div>
  );
};

export default SubFooter;
