import { useEffect, useState } from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import OwlCarousel from "react-owl-carousel";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select, { components } from "react-select";
import "./searchbar.css";

import { MdFlightTakeoff, MdFlightLand } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

const options = require("../../Data/Airports.json");

const SearchBar = () => {
  const styles = {
    valueContainer: (base) => ({
      ...base,
      paddingLeft: "18px",
    }),
  };

  const [refData, setRefData] = useState("");
  const [FlyingFrom, setFlyingFrom] = useState("DAC");
  const [FlyingFromPlaceHolder, setFlyingFromPlaceHolder] = useState({
    label: "Dhaka, Bangladesh (DAC)",
    value: "DAC",
  });
  const [FlyingTo, setFlyingTo] = useState("CXB");
  const [FlyingToPlaceHolder, setFlyingToFlyingToPlaceHolder] = useState({
    label: "Cox's Bazar, Bangladesh (CXB)",
    value: "CXB",
  });

  const [focusedInput, setFocusedInput] = useState();
  const [DepartureDate, setDepartureDate] = useState(moment().add(1, "days"));
  const [ReturnDate, setReturnDate] = useState(moment().add(11, "days"));
  const [focused, setFocused] = useState(false);
  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfChilds, setNumberOfChilds] = useState(0);
  const [numberOfInfants, setNumberOfInfants] = useState(0);
  const [flightClass, setFlightClass] = useState("Y");
  const [flightClassFullName, setFlightClassFullName] = useState("Economy");
  const navigate = useNavigate();
  const [childAgesOneway, setChildAgesOneway] = useState([]);

  // const { data, isLoading, error } = useQuery({
  //   queryKey: "airports",
  //   queryFn: async () => {
  //     const response = await fetch(
  //       "http://139.59.69.198/admin/api/gds/airports",
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.json();
  //   },
  // });

  const searchHandlerOneWay = (eve) => {
    eve.preventDefault();
    if (FlyingFrom && FlyingTo && DepartureDate) {
      if (FlyingFrom !== FlyingTo) {
        if (numberOfChilds > 0)
          localStorage.setItem("owc", JSON.stringify(childAgesOneway));

        navigate(
          `/search-results/${FlyingFrom}-${FlyingTo}--${
            DepartureDate.year() +
            "-" +
            parseInt(DepartureDate.month() + 1) +
            "-" +
            DepartureDate.date()
          }--12-0-0--${numberOfAdults}-${numberOfChilds}-${numberOfInfants}-${flightClass}`
        );
      } else {
        toast.warning("Both Location can not be same", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Please Fill up localtion and dates", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const searchHandlerRoundTrip = (eve) => {
    eve.preventDefault();
    if (FlyingFrom && FlyingTo && DepartureDate && ReturnDate) {
      if (numberOfChilds > 0)
        localStorage.setItem("owc", JSON.stringify(childAgesOneway));

      if (FlyingFrom !== FlyingTo) {
        navigate(
          `/search-results/${FlyingFrom}-${FlyingTo}--${
            DepartureDate.year() +
            "-" +
            parseInt(DepartureDate.month() + 1) +
            "-" +
            DepartureDate.date()
          }--12-0-0--${
            ReturnDate.year() +
            "-" +
            parseInt(ReturnDate.month() + 1) +
            "-" +
            ReturnDate.date()
          }--12-0-0--${numberOfAdults}-${numberOfChilds}-${numberOfInfants}-${flightClass}`
        );
      } else {
        toast.warning("Both Location can not be same", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Please Fill up localtion and dates", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const setOnewayChildAge = (index, age) => {
    const data = [...childAgesOneway];
    data[index] = { age: age, quantity: 1 };
    setChildAgesOneway(data);
  };

  const handleSendData = () => {
    navigate(`/managebooking?refData=${refData}`);
  };

  return (
    <div>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <section
        className="search-box_content"
        style={{
          backgroundImage: "url(./assets/img/bg-9.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ height: "100%", overflow: "hidden" }}>
          <OwlCarousel
            className="owl-theme"
            loop
            items={1}
            dots={false}
            autoplay={true}
            autoplaySpeed={true}
            autoplayTimeout={3000}
            animateOut={true}
            animateIn={true}
            lazyLoad={true}
          >
            <img src="/image-1.jpg" alt="Slide 1" className="home-slider-img" />
            <img src="/image-2.jpg" alt="Slide 2" className="home-slider-img" />
            <img src="/image-3.jpg" alt="Slide 3" className="home-slider-img" />
            <img src="/image-4.jpg" alt="Slide 4" className="home-slider-img" />
          </OwlCarousel>
        </div>
        <div
          className="container search-box-container"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            className="search-box_row justify-content-center "
            style={{
              borderRadius: "50px",
            }}
          >
            <div className="search-box_col">
              <div className="search-box">
                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ minWidth: "50%" }}
                  >
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Book
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ minWidth: "50%" }}
                  >
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Manage booking
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="search-tabs d-flex flex-wrap">
                      <input
                        className="checkbox d-none"
                        id="tab1"
                        type="radio"
                        name="tabsA"
                      />
                      <label
                        className="
                        checkbox-label
                        d-inline-block
                        font-weight-500
                        me-2
                        border
                        rounded
                        fs-14
                        bg-white
                      "
                        htmlFor="tab1"
                      >
                        Round trip
                      </label>
                      <input
                        className="checkbox d-none"
                        id="tab2"
                        type="radio"
                        name="tabsA"
                        defaultChecked
                      />
                      <label
                        className="
                        checkbox-label
                        d-inline-block
                        font-weight-500
                        me-2
                        border
                        rounded
                        fs-14
                        bg-white
                      "
                        htmlFor="tab2"
                      >
                        One way
                      </label>
                      {/* Round Trip */}
                      <div
                        className="search-content d-none w-100 pt-3"
                        id="search-content1"
                      >
                        <form
                          className="modify-search"
                          onSubmit={searchHandlerRoundTrip}
                        >
                          <div className="row align-items-end">
                            <div className="col-md-12">
                              <div className="row g-2 position-relative">
                                <div className="col-md-6 col-lg-3">
                                  <div className="input-group">
                                    <div className="form-floating flight-form">
                                      <label>From</label>
                                      <Select
                                        components={{
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                          Control: ({ children, ...rest }) => (
                                            <components.Control {...rest}>
                                              <MdFlightTakeoff
                                                style={{
                                                  fontSize: "20px",
                                                  marginLeft: "20px",
                                                }}
                                              />{" "}
                                              {children}
                                            </components.Control>
                                          ),
                                        }}
                                        options={options}
                                        placeholder="Flying From"
                                        noOptionsMessage={() => "Please Search"}
                                        onChange={(e) => {
                                          setFlyingFrom(e.value);
                                          setFlyingFromPlaceHolder({
                                            label: e.label,
                                            value: e.value,
                                          });
                                        }}
                                        value={FlyingFromPlaceHolder}
                                        styles={styles}
                                        onFocus={(e) => {
                                          if (window.innerWidth < 426) {
                                            window.scroll(0, 300);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-floating flight-to">
                                    <Select
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        Control: ({ children, ...rest }) => (
                                          <components.Control {...rest}>
                                            <MdFlightLand
                                              style={{
                                                fontSize: "20px",
                                                marginLeft: "20px",
                                              }}
                                            />{" "}
                                            {children}
                                          </components.Control>
                                        ),
                                      }}
                                      options={options}
                                      id="floatingInput"
                                      placeholder="Flying To"
                                      noOptionsMessage={() => "Please Search"}
                                      styles={styles}
                                      value={FlyingToPlaceHolder}
                                      onChange={(e) => {
                                        setFlyingTo(e.value);
                                        setFlyingToFlyingToPlaceHolder({
                                          label: e.label,
                                          value: e.value,
                                        });
                                      }}
                                      onFocus={(e) => {
                                        if (window.innerWidth < 426) {
                                          window.scroll(0, 400);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                  <DateRangePicker
                                    startDate={DepartureDate}
                                    numberOfMonths={1}
                                    showDefaultInputIcon
                                    displayFormat="DD/MM/YYYY"
                                    startDateId="start-date"
                                    startDatePlaceholderText="Departure"
                                    endDatePlaceholderText="Return Date"
                                    endDate={ReturnDate}
                                    endDateId="end-date"
                                    onDatesChange={({ startDate, endDate }) => {
                                      setDepartureDate(startDate);
                                      setReturnDate(endDate);

                                      if (endDate == null) {
                                        setReturnDate(startDate);
                                      }
                                    }}
                                    focusedInput={focusedInput}
                                    onFocusChange={(focusedInput) =>
                                      setFocusedInput(focusedInput)
                                    }
                                  />
                                </div>

                                <div className="col-md-12 roundtripTravellerMobile">
                                  <div className="">
                                    <Form className="search-select d-flex">
                                      <NavDropdown
                                        onClick={() => {
                                          window.scroll(0, 300);
                                        }}
                                        title={`${
                                          numberOfAdults +
                                          numberOfChilds +
                                          numberOfInfants
                                        } Travellers, ${flightClassFullName}`}
                                        className="travellers-dropdown tra-box"
                                        style={{
                                          background: "white",
                                          minWidth: "250px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <div id="yt">
                                          <ul class="m-0 p-0">
                                            <li className="fs-14 d-flex justify-content-between align-items-center">
                                              <span>
                                                <span className="font-weight-500">
                                                  {numberOfAdults}
                                                </span>
                                                <span className="fs-14 font-weight-500">
                                                  {" "}
                                                  Adult
                                                  <span>s</span>
                                                </span>
                                              </span>
                                              <div className="spinner d-flex">
                                                <span
                                                  className="minus"
                                                  onClick={(e) => {
                                                    setNumberOfAdults(
                                                      numberOfAdults > 1
                                                        ? numberOfAdults - 1
                                                        : 1
                                                    );
                                                  }}
                                                >
                                                  -
                                                </span>
                                                <span
                                                  className="plus"
                                                  onClick={(e) => {
                                                    if (
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                      9
                                                    ) {
                                                      setNumberOfAdults(
                                                        numberOfAdults +
                                                          numberOfChilds +
                                                          numberOfInfants <
                                                          9
                                                          ? numberOfAdults + 1
                                                          : 1
                                                      );
                                                    }
                                                  }}
                                                >
                                                  +
                                                </span>
                                              </div>
                                            </li>
                                            <li className="fs-14 d-flex justify-content-between align-items-center">
                                              <span>
                                                <span className="font-weight-500">
                                                  {numberOfChilds}
                                                </span>
                                                <span className="fs-14 font-weight-500">
                                                  {" "}
                                                  Child
                                                </span>
                                                <span className="cat-info fs-12">
                                                  {" "}
                                                  2-12 years
                                                </span>
                                              </span>
                                              <div className="spinner d-flex">
                                                <span
                                                  className="minus"
                                                  onClick={(e) => {
                                                    setNumberOfChilds(
                                                      numberOfChilds > 0
                                                        ? numberOfChilds - 1
                                                        : 0
                                                    );

                                                    if (
                                                      childAgesOneway.length > 0
                                                    )
                                                      setChildAgesOneway(
                                                        childAgesOneway.splice(
                                                          0,
                                                          childAgesOneway.length -
                                                            1
                                                        )
                                                      );
                                                  }}
                                                >
                                                  -
                                                </span>
                                                <span
                                                  className="plus"
                                                  onClick={(e) => {
                                                    if (
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                      9
                                                    ) {
                                                      setNumberOfChilds(
                                                        numberOfAdults +
                                                          numberOfChilds +
                                                          numberOfInfants <
                                                          9
                                                          ? numberOfChilds + 1
                                                          : 0
                                                      );

                                                      setChildAgesOneway([
                                                        ...childAgesOneway,
                                                        {
                                                          age: "2",
                                                          quantity: 1,
                                                        },
                                                      ]);
                                                    }
                                                  }}
                                                >
                                                  +
                                                </span>
                                              </div>
                                            </li>

                                            {childAgesOneway.length > 0 && (
                                              <li className="fs-14 d-flex">
                                                {childAgesOneway.map(
                                                  (child, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          className="form-group ms-1"
                                                          key={index + 1}
                                                        >
                                                          <label htmlFor="Age">
                                                            Child {index + 1}{" "}
                                                            Age
                                                          </label>
                                                          <select
                                                            className="form-select"
                                                            style={{
                                                              width: "75px",
                                                            }}
                                                            onChange={(e) => {
                                                              console.log(
                                                                e.target.value
                                                              );
                                                              setOnewayChildAge(
                                                                index,
                                                                e.target.value
                                                              );
                                                            }}
                                                          >
                                                            <option value="2">
                                                              2
                                                            </option>
                                                            <option value="3">
                                                              3
                                                            </option>
                                                            <option value="4">
                                                              4
                                                            </option>
                                                            <option value="5">
                                                              5
                                                            </option>
                                                            <option value="6">
                                                              6
                                                            </option>
                                                            <option value="7">
                                                              7
                                                            </option>
                                                            <option value="8">
                                                              8
                                                            </option>
                                                            <option value="9">
                                                              9
                                                            </option>
                                                            <option value="10">
                                                              10
                                                            </option>
                                                            <option value="11">
                                                              11
                                                            </option>
                                                            <option value="12">
                                                              12
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </li>
                                            )}

                                            <li className="fs-14 d-flex justify-content-between align-items-center">
                                              <span>
                                                <span className="font-weight-500">
                                                  {numberOfInfants}
                                                </span>
                                                <span className="fs-14 font-weight-500 ms-1">
                                                  {" "}
                                                  Infant
                                                </span>
                                                <span className="cat-info fs-12 ms-1">
                                                  Below 2 years
                                                </span>
                                              </span>
                                              <div className="spinner d-flex">
                                                <span
                                                  className="minus"
                                                  onClick={(e) => {
                                                    setNumberOfInfants(
                                                      numberOfInfants > 0
                                                        ? numberOfInfants - 1
                                                        : 0
                                                    );
                                                  }}
                                                >
                                                  -
                                                </span>
                                                <span
                                                  className="plus"
                                                  onClick={(e) => {
                                                    if (
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                      9
                                                    ) {
                                                      setNumberOfInfants(
                                                        numberOfAdults +
                                                          numberOfChilds +
                                                          numberOfInfants <
                                                          9
                                                          ? numberOfInfants + 1
                                                          : 0
                                                      );
                                                    }
                                                  }}
                                                >
                                                  +
                                                </span>
                                              </div>
                                            </li>
                                          </ul>
                                          <div class="class-type mt-2">
                                            <div class="custom-control custom-radio pl-0">
                                              <input
                                                type="radio"
                                                id="economy"
                                                name="classType"
                                                onChange={() => {
                                                  console.log("Hitted");
                                                  setFlightClass("Y");
                                                  setFlightClassFullName(
                                                    "Economy"
                                                  );
                                                }}
                                                defaultChecked
                                              />
                                              <label class="ms-2" for="economy">
                                                Economy
                                              </label>
                                            </div>
                                            <div class="custom-control custom-radio pl-0">
                                              <input
                                                type="radio"
                                                id="business"
                                                name="classType"
                                                onChange={() => {
                                                  setFlightClass("C");
                                                  setFlightClassFullName(
                                                    "Business"
                                                  );
                                                }}
                                              />
                                              <label
                                                class="ms-2"
                                                for="business"
                                              >
                                                Business
                                              </label>
                                            </div>
                                            <div class="custom-control custom-radio pl-0">
                                              <input
                                                type="radio"
                                                id="premiumEconomy"
                                                name="classType"
                                                onChange={() => {
                                                  console.log("Hitted");
                                                  setFlightClass("J");
                                                  setFlightClassFullName(
                                                    "First Class"
                                                  );
                                                }}
                                              />
                                              <label
                                                class="ms-2"
                                                for="premiumEconomy"
                                              >
                                                First Class
                                              </label>
                                            </div>

                                            <div class="custom-control custom-radio pl-0">
                                              <input
                                                type="radio"
                                                id="premiumEconomy"
                                                name="classType"
                                                onChange={() => {
                                                  setFlightClass("S");
                                                  setFlightClassFullName(
                                                    "Premium Economy"
                                                  );
                                                }}
                                              />
                                              <label
                                                class="ms-2"
                                                for="premiumEconomy"
                                              >
                                                Premium Economy
                                              </label>
                                            </div>
                                          </div>

                                          <div className="">
                                            <button
                                              className="btn btn-dark w-100 mt-1"
                                              type="button"
                                              onClick={() => {
                                                const dropdown =
                                                  document.querySelector(
                                                    ".dropdown-menu"
                                                  );

                                                const topDropDown =
                                                  document.querySelector(
                                                    ".tra-box"
                                                  );

                                                const arialExp =
                                                  document.querySelector(
                                                    ".dropdown-toggle"
                                                  );

                                                dropdown.classList.remove(
                                                  "show"
                                                );
                                                topDropDown.classList.remove(
                                                  "show"
                                                );
                                                arialExp.setAttribute(
                                                  "aria-expanded",
                                                  "false"
                                                );
                                              }}
                                            >
                                              Confirm
                                            </button>
                                          </div>
                                        </div>
                                      </NavDropdown>
                                    </Form>
                                  </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                  <button
                                    type="submit"
                                    className="btn btn-search _lap_btn_r"
                                    style={{
                                      backgroundColor: "#001787",
                                      color: "white",
                                    }}
                                  >
                                    <span> Search </span>
                                    <span className="_hide">
                                      flights
                                      <i className="fas fa-plane-departure ms-2"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>

                              <div className="row roundtripTraveller">
                                <div className="col-md-12">
                                  <Form className="search-select d-flex ms-1 mt-3">
                                    <NavDropdown
                                      onClick={() => {
                                        window.scroll(0, 300);
                                      }}
                                      title={`${
                                        numberOfAdults +
                                        numberOfChilds +
                                        numberOfInfants
                                      } Travellers, ${flightClassFullName}`}
                                      className="travellers-dropdown tra-box"
                                      style={{
                                        background: "white",
                                        minWidth: "250px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div id="yt">
                                        <ul class="m-0 p-0">
                                          <li className="fs-14 d-flex justify-content-between align-items-center">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfAdults}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Adult<span>s</span>
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfAdults(
                                                    numberOfAdults > 1
                                                      ? numberOfAdults - 1
                                                      : 1
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  if (
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                    9
                                                  ) {
                                                    setNumberOfAdults(
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                        9
                                                        ? numberOfAdults + 1
                                                        : 1
                                                    );
                                                  }
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                          <li className="fs-14 d-flex justify-content-between align-items-center">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfChilds}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Child
                                              </span>
                                              <span className="cat-info fs-12">
                                                {" "}
                                                2-12 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfChilds(
                                                    numberOfChilds > 0
                                                      ? numberOfChilds - 1
                                                      : 0
                                                  );

                                                  if (
                                                    childAgesOneway.length > 0
                                                  )
                                                    setChildAgesOneway(
                                                      childAgesOneway.splice(
                                                        0,
                                                        childAgesOneway.length -
                                                          1
                                                      )
                                                    );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  if (
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                    9
                                                  ) {
                                                    setNumberOfChilds(
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                        9
                                                        ? numberOfChilds + 1
                                                        : 0
                                                    );

                                                    setChildAgesOneway([
                                                      ...childAgesOneway,
                                                      {
                                                        age: "2",
                                                        quantity: 1,
                                                      },
                                                    ]);
                                                  }
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>

                                          {childAgesOneway.length > 0 && (
                                            <li className="fs-14 d-flex">
                                              {childAgesOneway.map(
                                                (child, index) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className="form-group ms-1"
                                                        key={index + 1}
                                                      >
                                                        <label htmlFor="Age">
                                                          Child {index + 1} Age
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          style={{
                                                            width: "75px",
                                                          }}
                                                          onChange={(e) => {
                                                            console.log(
                                                              e.target.value
                                                            );
                                                            setOnewayChildAge(
                                                              index,
                                                              e.target.value
                                                            );
                                                          }}
                                                        >
                                                          <option value="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}

                                          <li className="fs-14 d-flex justify-content-between align-items-center">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfInfants}
                                              </span>
                                              <span className="fs-14 font-weight-500 ms-1">
                                                {" "}
                                                Infant
                                              </span>
                                              <span className="cat-info fs-12 ms-1">
                                                Below 2 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfInfants(
                                                    numberOfInfants > 0
                                                      ? numberOfInfants - 1
                                                      : 0
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  if (
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                    9
                                                  ) {
                                                    setNumberOfInfants(
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                        9
                                                        ? numberOfInfants + 1
                                                        : 0
                                                    );
                                                  }
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="class-type mt-2">
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="economy"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("Y");
                                                setFlightClassFullName(
                                                  "Economy"
                                                );
                                              }}
                                              defaultChecked
                                            />
                                            <label class="ms-2" for="economy">
                                              Economy
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="business"
                                              name="classType"
                                              onChange={() => {
                                                setFlightClass("C");
                                                setFlightClassFullName(
                                                  "Business"
                                                );
                                              }}
                                            />
                                            <label class="ms-2" for="business">
                                              Business
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("J");
                                                setFlightClassFullName(
                                                  "First Class"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              for="premiumEconomy"
                                            >
                                              First Class
                                            </label>
                                          </div>

                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy"
                                              name="classType"
                                              onChange={() => {
                                                setFlightClass("S");
                                                setFlightClassFullName(
                                                  "Premium Economy"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              for="premiumEconomy"
                                            >
                                              Premium Economy
                                            </label>
                                          </div>
                                        </div>

                                        <div className="">
                                          <button
                                            className="btn btn-dark w-100 mt-1"
                                            type="button"
                                            onClick={() => {
                                              const dropdown =
                                                document.querySelector(
                                                  ".dropdown-menu"
                                                );

                                              const topDropDown =
                                                document.querySelector(
                                                  ".tra-box"
                                                );

                                              const arialExp =
                                                document.querySelector(
                                                  ".dropdown-toggle"
                                                );

                                              dropdown.classList.remove("show");
                                              topDropDown.classList.remove(
                                                "show"
                                              );
                                              arialExp.setAttribute(
                                                "aria-expanded",
                                                "false"
                                              );
                                            }}
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </div>
                                    </NavDropdown>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* Signle Trip */}
                      <div
                        className="search-content d-none w-100 pt-3"
                        id="search-content2"
                      >
                        <form
                          className="modify-search"
                          onSubmit={searchHandlerOneWay}
                        >
                          <div className="row align-items-end">
                            <div className="col-md-12">
                              <div className="row g-2 position-relative">
                                <div className="col-md-6 col-lg-3">
                                  <div className="input-group">
                                    <div className="form-floating flight-form">
                                      <label>From</label>
                                      <Select
                                        components={{
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                          Control: ({ children, ...rest }) => (
                                            <components.Control {...rest}>
                                              <MdFlightTakeoff
                                                style={{
                                                  fontSize: "20px",
                                                  marginLeft: "20px",
                                                }}
                                              />{" "}
                                              {children}
                                            </components.Control>
                                          ),
                                        }}
                                        key={"from"}
                                        options={options}
                                        styles={styles}
                                        placeholder="Flying From"
                                        noOptionsMessage={() => "Please Search"}
                                        onChange={(e) => {
                                          setFlyingFrom(e.value);
                                          setFlyingFromPlaceHolder({
                                            label: e.label,
                                            value: e.value,
                                          });
                                        }}
                                        value={FlyingFromPlaceHolder}
                                        onFocus={(e) => {
                                          if (window.innerWidth < 426) {
                                            window.scroll(0, 300);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-floating flight-to">
                                    <Select
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        Control: ({ children, ...rest }) => (
                                          <components.Control {...rest}>
                                            <MdFlightLand
                                              style={{
                                                fontSize: "20px",
                                                marginLeft: "20px",
                                              }}
                                            />{" "}
                                            {children}
                                          </components.Control>
                                        ),
                                      }}
                                      options={options}
                                      id="floatingInput"
                                      placeholder="Flying To"
                                      noOptionsMessage={() => "Please Search"}
                                      value={FlyingToPlaceHolder}
                                      onChange={(e) => {
                                        setFlyingTo(e.value);
                                        setFlyingToFlyingToPlaceHolder({
                                          label: e.label,
                                          value: e.value,
                                        });
                                      }}
                                      onFocus={(e) => {
                                        if (window.innerWidth < 426) {
                                          window.scroll(0, 400);
                                        }
                                      }}
                                      styles={styles}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-md-6 col-lg-3 _scs"
                                  onClick={() => {
                                    document.getElementById("date").focus();
                                  }}
                                >
                                  <SingleDatePicker
                                    displayFormat="DD/MM/YYYY"
                                    date={DepartureDate}
                                    numberOfMonths={1}
                                    showDefaultInputIcon
                                    onDateChange={(date) => {
                                      setDepartureDate(date);
                                    }}
                                    focused={focused}
                                    onFocusChange={({ focused }) =>
                                      setFocused(focused)
                                    }
                                    placeholder="Departure Date"
                                    id="date"
                                  />
                                </div>

                                <div className="col-md-6 oneWayTravellerMobile">
                                  <div className="">
                                    <Form className="search-select d-flex">
                                      <NavDropdown
                                        onClick={() => {
                                          window.scroll(0, 300);
                                        }}
                                        title={`${
                                          numberOfAdults +
                                          numberOfChilds +
                                          numberOfInfants
                                        } Travellers, ${flightClassFullName}`}
                                        className="travellers-dropdown tra-boxx"
                                        style={{
                                          background: "white",
                                          minWidth: "250px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <ul class="m-0 p-0">
                                          <li className="fs-14 d-flex justify-content-between">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfAdults}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Adult<span>s</span>
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfAdults(
                                                    numberOfAdults > 1
                                                      ? numberOfAdults - 1
                                                      : 1
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  if (
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                    9
                                                  ) {
                                                    setNumberOfAdults(
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                        9
                                                        ? numberOfAdults + 1
                                                        : 1
                                                    );
                                                  }
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>

                                          <li className="fs-14 d-flex justify-content-between">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfChilds}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Child
                                              </span>
                                              <span className="cat-info fs-12">
                                                {" "}
                                                2-12 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfChilds(
                                                    numberOfChilds > 0
                                                      ? numberOfChilds - 1
                                                      : 0
                                                  );
                                                  if (
                                                    childAgesOneway.length > 0
                                                  )
                                                    setChildAgesOneway(
                                                      childAgesOneway.splice(
                                                        0,
                                                        childAgesOneway.length -
                                                          1
                                                      )
                                                    );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  if (
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                    9
                                                  ) {
                                                    setNumberOfChilds(
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                        9
                                                        ? numberOfChilds + 1
                                                        : 0
                                                    );

                                                    setChildAgesOneway([
                                                      ...childAgesOneway,
                                                      {
                                                        age: "2",
                                                        quantity: 1,
                                                      },
                                                    ]);
                                                  }
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>

                                          {childAgesOneway.length > 0 && (
                                            <li className="fs-14 d-flex">
                                              {childAgesOneway.map(
                                                (child, index) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className="form-group ms-1"
                                                        key={index + 1}
                                                      >
                                                        <label htmlFor="Age">
                                                          Child {index + 1} Age
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          style={{
                                                            width: "75px",
                                                          }}
                                                          onChange={(e) => {
                                                            console.log(
                                                              e.target.value
                                                            );
                                                            setOnewayChildAge(
                                                              index,
                                                              e.target.value
                                                            );
                                                          }}
                                                        >
                                                          <option value="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}

                                          <li className="fs-14 d-flex justify-content-between">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfInfants}
                                              </span>
                                              <span className="fs-14 font-weight-500 ms-1">
                                                {" "}
                                                Infant
                                              </span>
                                              <span className="cat-info fs-12 ms-1">
                                                Below 2 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfInfants(
                                                    numberOfInfants > 0
                                                      ? numberOfInfants - 1
                                                      : 0
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  if (
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                    9
                                                  ) {
                                                    setNumberOfInfants(
                                                      numberOfAdults +
                                                        numberOfChilds +
                                                        numberOfInfants <
                                                        9
                                                        ? numberOfInfants + 1
                                                        : 0
                                                    );
                                                  }
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                        </ul>

                                        <div class="class-type mt-2">
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="economy-s"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("Y");
                                                setFlightClassFullName(
                                                  "Economy"
                                                );
                                              }}
                                              defaultChecked
                                            />
                                            <label
                                              class="ms-2"
                                              htmlFor="economy-s"
                                            >
                                              Economy
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="business-s"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("C");
                                                setFlightClassFullName(
                                                  "Business"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              htmlFor="business-s"
                                            >
                                              Business
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy-s"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("J");
                                                setFlightClassFullName(
                                                  "First Class"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              htmlFor="premiumEconomy-s"
                                            >
                                              First Class
                                            </label>
                                          </div>

                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy"
                                              name="classType"
                                              onChange={() => {
                                                setFlightClass("S");
                                                setFlightClassFullName(
                                                  "Premium Economy"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              for="premiumEconomy"
                                            >
                                              Premium Economy
                                            </label>
                                          </div>
                                        </div>
                                        <div className="">
                                          <button
                                            className="btn btn-dark w-100 mt-1"
                                            type="button"
                                            onClick={() => {
                                              const dropdown =
                                                document.querySelector(
                                                  ".dropdown-menu"
                                                );

                                              const topDropDown =
                                                document.querySelector(
                                                  ".tra-boxx"
                                                );

                                              const arialExp =
                                                document.querySelector(
                                                  ".dropdown-toggle"
                                                );

                                              dropdown.classList.remove("show");
                                              topDropDown.classList.remove(
                                                "show"
                                              );
                                              arialExp.setAttribute(
                                                "aria-expanded",
                                                "false"
                                              );
                                            }}
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </NavDropdown>
                                    </Form>
                                  </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                  <button
                                    type="submit"
                                    className="btn btn-search w-100"
                                    style={{
                                      backgroundColor: "#001787",
                                      color: "white",
                                    }}
                                  >
                                    Search flights
                                    <i className="fas fa-plane-departure ms-2"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="row oneWayTraveller">
                                <div className="col-md-12">
                                  <Form className="search-select d-flex ms-1 mt-3">
                                    <NavDropdown
                                      onClick={() => {
                                        window.scroll(0, 300);
                                      }}
                                      title={`${
                                        numberOfAdults +
                                        numberOfChilds +
                                        numberOfInfants
                                      } Travellers, ${flightClassFullName}`}
                                      className="travellers-dropdown tra-boxx"
                                      style={{
                                        background: "white",
                                        minWidth: "250px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <ul class="m-0 p-0">
                                        <li className="fs-14 d-flex justify-content-between">
                                          <span>
                                            <span className="font-weight-500">
                                              {numberOfAdults}
                                            </span>
                                            <span className="fs-14 font-weight-500">
                                              {" "}
                                              Adult<span>s</span>
                                            </span>
                                          </span>
                                          <div className="spinner d-flex">
                                            <span
                                              className="minus"
                                              onClick={(e) => {
                                                setNumberOfAdults(
                                                  numberOfAdults > 1
                                                    ? numberOfAdults - 1
                                                    : 1
                                                );
                                              }}
                                            >
                                              -
                                            </span>
                                            <span
                                              className="plus"
                                              onClick={(e) => {
                                                if (
                                                  numberOfAdults +
                                                    numberOfChilds +
                                                    numberOfInfants <
                                                  9
                                                ) {
                                                  setNumberOfAdults(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfAdults + 1
                                                      : 1
                                                  );
                                                }
                                              }}
                                            >
                                              +
                                            </span>
                                          </div>
                                        </li>

                                        <li className="fs-14 d-flex justify-content-between">
                                          <span>
                                            <span className="font-weight-500">
                                              {numberOfChilds}
                                            </span>
                                            <span className="fs-14 font-weight-500">
                                              {" "}
                                              Child
                                            </span>
                                            <span className="cat-info fs-12">
                                              {" "}
                                              2-12 years
                                            </span>
                                          </span>
                                          <div className="spinner d-flex">
                                            <span
                                              className="minus"
                                              onClick={(e) => {
                                                setNumberOfChilds(
                                                  numberOfChilds > 0
                                                    ? numberOfChilds - 1
                                                    : 0
                                                );
                                                if (childAgesOneway.length > 0)
                                                  setChildAgesOneway(
                                                    childAgesOneway.splice(
                                                      0,
                                                      childAgesOneway.length - 1
                                                    )
                                                  );
                                              }}
                                            >
                                              -
                                            </span>
                                            <span
                                              className="plus"
                                              onClick={(e) => {
                                                if (
                                                  numberOfAdults +
                                                    numberOfChilds +
                                                    numberOfInfants <
                                                  9
                                                ) {
                                                  setNumberOfChilds(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfChilds + 1
                                                      : 0
                                                  );

                                                  setChildAgesOneway([
                                                    ...childAgesOneway,
                                                    {
                                                      age: "2",
                                                      quantity: 1,
                                                    },
                                                  ]);
                                                }
                                              }}
                                            >
                                              +
                                            </span>
                                          </div>
                                        </li>

                                        {childAgesOneway.length > 0 && (
                                          <li className="fs-14 d-flex">
                                            {childAgesOneway.map(
                                              (child, index) => {
                                                return (
                                                  <>
                                                    <div
                                                      className="form-group ms-1"
                                                      key={index + 1}
                                                    >
                                                      <label htmlFor="Age">
                                                        Child {index + 1} Age
                                                      </label>
                                                      <select
                                                        className="form-select"
                                                        style={{
                                                          width: "75px",
                                                        }}
                                                        onChange={(e) => {
                                                          console.log(
                                                            e.target.value
                                                          );
                                                          setOnewayChildAge(
                                                            index,
                                                            e.target.value
                                                          );
                                                        }}
                                                      >
                                                        <option value="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </li>
                                        )}

                                        <li className="fs-14 d-flex justify-content-between">
                                          <span>
                                            <span className="font-weight-500">
                                              {numberOfInfants}
                                            </span>
                                            <span className="fs-14 font-weight-500 ms-1">
                                              {" "}
                                              Infant
                                            </span>
                                            <span className="cat-info fs-12 ms-1">
                                              Below 2 years
                                            </span>
                                          </span>
                                          <div className="spinner d-flex">
                                            <span
                                              className="minus"
                                              onClick={(e) => {
                                                setNumberOfInfants(
                                                  numberOfInfants > 0
                                                    ? numberOfInfants - 1
                                                    : 0
                                                );
                                              }}
                                            >
                                              -
                                            </span>
                                            <span
                                              className="plus"
                                              onClick={(e) => {
                                                if (
                                                  numberOfAdults +
                                                    numberOfChilds +
                                                    numberOfInfants <
                                                  9
                                                ) {
                                                  setNumberOfInfants(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfInfants + 1
                                                      : 0
                                                  );
                                                }
                                              }}
                                            >
                                              +
                                            </span>
                                          </div>
                                        </li>
                                      </ul>

                                      <div class="class-type mt-2">
                                        <div class="custom-control custom-radio pl-0">
                                          <input
                                            type="radio"
                                            id="economy-s"
                                            name="classType"
                                            onChange={() => {
                                              console.log("Hitted");
                                              setFlightClass("Y");
                                              setFlightClassFullName("Economy");
                                            }}
                                            defaultChecked
                                          />
                                          <label
                                            class="ms-2"
                                            htmlFor="economy-s"
                                          >
                                            Economy
                                          </label>
                                        </div>
                                        <div class="custom-control custom-radio pl-0">
                                          <input
                                            type="radio"
                                            id="business-s"
                                            name="classType"
                                            onChange={() => {
                                              console.log("Hitted");
                                              setFlightClass("C");
                                              setFlightClassFullName(
                                                "Business"
                                              );
                                            }}
                                          />
                                          <label
                                            class="ms-2"
                                            htmlFor="business-s"
                                          >
                                            Business
                                          </label>
                                        </div>
                                        <div class="custom-control custom-radio pl-0">
                                          <input
                                            type="radio"
                                            id="premiumEconomy-s"
                                            name="classType"
                                            onChange={() => {
                                              console.log("Hitted");
                                              setFlightClass("J");
                                              setFlightClassFullName(
                                                "First Class"
                                              );
                                            }}
                                          />
                                          <label
                                            class="ms-2"
                                            htmlFor="premiumEconomy-s"
                                          >
                                            First Class
                                          </label>
                                        </div>

                                        <div class="custom-control custom-radio pl-0">
                                          <input
                                            type="radio"
                                            id="premiumEconomy"
                                            name="classType"
                                            onChange={() => {
                                              setFlightClass("S");
                                              setFlightClassFullName(
                                                "Premium Economy"
                                              );
                                            }}
                                          />
                                          <label
                                            class="ms-2"
                                            for="premiumEconomy"
                                          >
                                            Premium Economy
                                          </label>
                                        </div>
                                      </div>
                                      <div className="">
                                        <button
                                          className="btn btn-dark w-100 mt-1"
                                          type="button"
                                          onClick={() => {
                                            const dropdown =
                                              document.querySelector(
                                                ".dropdown-menu"
                                              );

                                            const topDropDown =
                                              document.querySelector(
                                                ".tra-boxx"
                                              );

                                            const arialExp =
                                              document.querySelector(
                                                ".dropdown-toggle"
                                              );

                                            dropdown.classList.remove("show");
                                            topDropDown.classList.remove(
                                              "show"
                                            );
                                            arialExp.setAttribute(
                                              "aria-expanded",
                                              "false"
                                            );
                                          }}
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </NavDropdown>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* *** ---- Manage Booking ---- *** */}

                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <form className="modify-search" onSubmit={handleSendData}>
                      <div className="row align-items-end">
                        <div className="col-md-4 col-lg-4 mb-3 mb-md-0">
                          <div className="form-group position-relative has-icon mb-0 w-100">
                            <label htmlFor="lname" className="search-label">
                              Last Name
                            </label>
                            <input
                              id="lname"
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              style={{ background: "white" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 mb-3 mb-md-0">
                          <div
                            className="
                            form-group
                            position-relative
                            has-icon
                            mb-0
                            w-100
                          "
                          >
                            <label
                              htmlFor="bookingReference"
                              className="search-label"
                            >
                              Booking reference
                            </label>
                            <input
                              id="bookingReference"
                              type="text"
                              className="form-control"
                              placeholder="Booking reference"
                              value={refData}
                              onChange={(e) => setRefData(e.target.value)}
                              style={{ background: "white" }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 mt-3 mt-md-0">
                          <button
                            type="submit"
                            className="btn btn-block mt-2"
                            style={{
                              backgroundColor: "#001787",
                              color: "white",
                              marginBottom: "1px",
                            }}
                          >
                            Retrieve booking
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="_temp"></div>
    </div>
  );
};

export default SearchBar;
