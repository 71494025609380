import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select from "react-select";
import "./rsearchbar.css";
import { useNavigate } from "react-router-dom";

const options = require("../Data/Airports.json");

const ReSearchBar = () => {
  const roundRef = useRef();
  const oneWayRef = useRef();

  const navigate = useNavigate();

  const [FlyingFrom, setFlyingFrom] = useState();
  const [FlyingTo, setFlyingTo] = useState();
  const [focusedInput, setFocusedInput] = useState();
  const [DepartureDate, setDepartureDate] = useState();
  const [ReturnDate, setReturnDate] = useState();
  const [focused, setFocused] = useState();
  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfChilds, setNumberOfChilds] = useState(0);
  const [numberOfInfants, setNumberOfInfants] = useState(0);
  const [flightClass, setFlightClass] = useState("Y");
  const [flightClassFullName, setFlightClassFullName] = useState("Economy");
  const [showDate, setShowDate] = useState("");
  let [allchilds, setAllchilds] = useState([]);
  const [showrbar, setshowrbar] = useState(false);
  const [childAgesOneway, setChildAgesOneway] = useState([]);

  useEffect(() => {
    function handleResize() {
      const collapse = document.getElementById("researchPanel");
      if (window.innerWidth < 1024) {
        collapse.classList.remove("show");
      } else {
        collapse.classList.add("show");
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      setshowrbar(true);
    } else {
      setshowrbar(false);
    }

    // const schedule = JSON.parse(localStorage.getItem('schD'))
    // setShowDate(
    //   moment(schedule[0].DepartureDateTime.split('T')[0]).format('DD-MM-YYYY')
    // )
    // setFlyingFrom(schedule[0].OriginLocation.LocationCode)
    // setFlyingTo(schedule[0].DestinationLocation.LocationCode)
    // setDepartureDate(moment(schedule[0].DepartureDateTime.split('T')[0]))
    // if (schedule.length > 1) {
    //   roundRef.current.checked = true
    //   setReturnDate(moment(schedule[1].DepartureDateTime.split('T')[0]))
    // } else {
    //   oneWayRef.current.checked = true
    // }
  }, []);

  const DateFormatter = (year, month, day, hour, min, sec) => {
    month = parseInt(month) + 1 + "";
    if (month.length === 1) month = "0" + month;
    if (day.length === 1) day = "0" + day;
    if (hour.length === 1) hour = "0" + hour;
    if (min.length === 1) min = "0" + min;
    if (sec.length === 1) sec = "0" + sec;

    // return year + '-' + month + '-' + day + 'T' + hour + ':' + min + ':' + sec
    return year + "-" + month + "-" + day + "T00:00:00";
  };

  useEffect(() => {
    setChildAgesOneway(JSON.parse(localStorage.getItem("owc")));
    let urlOfCurrentPage = window.location.href;
    urlOfCurrentPage = urlOfCurrentPage.replace("#!", "");
    let startIndex = null;

    for (let i = 0; i < urlOfCurrentPage.length; ++i) {
      if (
        urlOfCurrentPage[i - 1] === "/" &&
        urlOfCurrentPage[i - 2] === "s" &&
        urlOfCurrentPage[i - 3] === "t"
      ) {
        startIndex = i;
        break;
      }
    }

    let refactoredData = "";

    for (let i = startIndex; i < urlOfCurrentPage.length; ++i) {
      refactoredData += urlOfCurrentPage[i];
    }

    const splittedArray = refactoredData.split("--");

    if (splittedArray.length > 4) {
      const passengerArray = splittedArray[5].split("-");
      setNumberOfAdults(parseInt(passengerArray[0]));
      setNumberOfChilds(parseInt(passengerArray[1]));
      setNumberOfInfants(parseInt(passengerArray[2]));
      if (passengerArray[3] === "Y") {
        setFlightClass("Y");
        setFlightClassFullName("Economy");
      }
      if (passengerArray[3] === "C") {
        setFlightClass("C");
        setFlightClassFullName("Business");
      }
      if (passengerArray[3] === "J") {
        setFlightClass("J");
        setFlightClassFullName("First Class");
      }

      if (passengerArray[3] === "S") {
        setFlightClass("S");
        setFlightClassFullName("Premium Economy");
      }

      const fromToArray = splittedArray[0].split("-");
      const departArray = splittedArray[1].split("-");
      const departTimeArray = splittedArray[2].split("-");
      const returnArray = splittedArray[3].split("-");
      const returnTimeArray = splittedArray[4].split("-");

      const from = fromToArray[0];
      setFlyingFrom(from);
      const to = fromToArray[1];
      setFlyingTo(to);

      const dyear = departArray[0];
      const dmonth = parseInt(departArray[1] - 1);
      const dday = departArray[2];

      const dhour = departTimeArray[0];
      const dmin = departTimeArray[1];
      const dsec = departTimeArray[2];

      const ryear = returnArray[0];
      const rmonth = parseInt(returnArray[1] - 1);
      const rday = returnArray[2];
      const rhour = returnTimeArray[0];
      const rmin = returnTimeArray[1];
      const rsec = returnTimeArray[2];

      const schDes = [
        {
          DepartureDateTime: DateFormatter(
            dyear,
            dmonth,
            dday,
            dhour,
            dmin,
            dsec
          ),
          OriginLocation: {
            LocationCode: from,
            LocationType: "A",
          },
          DestinationLocation: {
            LocationCode: to,
            LocationType: "A",
          },
          RPH: "0",
        },
        {
          DepartureDateTime: DateFormatter(
            ryear,
            rmonth,
            rday,
            rhour,
            rmin,
            rsec
          ),
          OriginLocation: {
            LocationCode: to,
            LocationType: "A",
          },
          DestinationLocation: {
            LocationCode: from,
            LocationType: "A",
          },
          RPH: "1",
        },
      ];

      const schedule = schDes;

      setShowDate(
        moment(schedule[0].DepartureDateTime.split("T")[0]).format("DD-MM-YYYY")
      );
      setFlyingFrom(schedule[0].OriginLocation.LocationCode);
      setFlyingTo(schedule[0].DestinationLocation.LocationCode);
      setDepartureDate(moment(schedule[0].DepartureDateTime.split("T")[0]));
      if (schedule.length > 1) {
        roundRef.current.checked = true;
        setReturnDate(moment(schedule[1].DepartureDateTime.split("T")[0]));
      } else {
        oneWayRef.current.checked = true;
      }
    } else {
      oneWayRef.current.checked = true;
      const passengerArray = splittedArray[3].split("-");
      setNumberOfAdults(parseInt(passengerArray[0]));
      setNumberOfChilds(parseInt(passengerArray[1]));
      setNumberOfInfants(parseInt(passengerArray[2]));
      if (passengerArray[3] === "Y") {
        setFlightClass("Y");
        setFlightClassFullName("Economy");
      }
      if (passengerArray[3] === "C") {
        setFlightClass("C");
        setFlightClassFullName("Business");
      }
      if (passengerArray[3] === "J") {
        setFlightClassFullName("First Class");
        setFlightClass("J");
      }

      if (passengerArray[3] === "S") {
        setFlightClass("S");
        setFlightClassFullName("Premium Economy");
      }

      const fromToArray = splittedArray[0].split("-");
      const departReturnArray = splittedArray[1].split("-");
      const departTimeArray = splittedArray[2].split("-");
      //Data extraction
      const from = fromToArray[0];
      setFlyingFrom(from);
      const to = fromToArray[1];
      setFlyingTo(to);
      const dyear = departReturnArray[0];
      const dmonth = parseInt(departReturnArray[1] - 1);
      const dday = departReturnArray[2];

      const dhour = departTimeArray[0];
      const dmin = departTimeArray[1];
      const dsec = departTimeArray[2];
      const schDesR = [
        {
          DepartureDateTime: DateFormatter(
            dyear,
            dmonth,
            dday,
            dhour,
            dmin,
            dsec
          ),
          OriginLocation: {
            LocationCode: from,
            LocationType: "A",
          },
          DestinationLocation: {
            LocationCode: to,
            LocationType: "A",
          },
          RPH: "0",
        },
      ];

      const schedule = schDesR;

      setShowDate(
        moment(schedule[0].DepartureDateTime.split("T")[0]).format("DD-MM-YYYY")
      );
      setFlyingFrom(schedule[0].OriginLocation.LocationCode);
      setFlyingTo(schedule[0].DestinationLocation.LocationCode);
      setDepartureDate(moment(schedule[0].DepartureDateTime.split("T")[0]));
      if (schedule.length > 1) {
        roundRef.current.checked = true;
        setReturnDate(moment(schedule[1].DepartureDateTime.split("T")[0]));
      } else {
        oneWayRef.current.checked = true;
      }
    }
  }, []);

  const searchHandlerOneWay = (eve) => {
    eve.preventDefault();
    if (FlyingFrom && FlyingTo && DepartureDate) {
      if (numberOfChilds > 0) {
        localStorage.setItem("owc", JSON.stringify(childAgesOneway));
      } else {
        localStorage.removeItem("owc");
      }

      window.location.assign(
        `/search-results/${FlyingFrom}-${FlyingTo}--${
          DepartureDate.year() +
          "-" +
          parseInt(DepartureDate.month() + 1) +
          "-" +
          DepartureDate.date()
        }--12-0-0--${numberOfAdults}-${numberOfChilds}-${numberOfInfants}-${flightClass}`
      );
    } else {
      toast.warning("Please Fill up localtion and dates", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const searchHandlerRoundTrip = (eve) => {
    eve.preventDefault();
    if (FlyingFrom && FlyingTo && DepartureDate && ReturnDate) {
      if (numberOfChilds > 0) {
        localStorage.setItem("owc", JSON.stringify(childAgesOneway));
      } else {
        localStorage.removeItem("owc");
      }

      window.location.assign(
        `/search-results/${FlyingFrom}-${FlyingTo}--${
          DepartureDate.year() +
          "-" +
          parseInt(DepartureDate.month() + 1) +
          "-" +
          DepartureDate.date()
        }--12-0-0--${
          ReturnDate.year() +
          "-" +
          parseInt(ReturnDate.month() + 1) +
          "-" +
          ReturnDate.date()
        }--12-0-0--${numberOfAdults}-${numberOfChilds}-${numberOfInfants}-${flightClass}`
      );
    } else {
      toast.warning("Please Fill up localtion and dates", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const setOnewayChildAge = (age = "", index = "") => {
    let childElement = document.getElementsByClassName("childElement");
    let tem = {
      age: "2",
      quantity: 1,
    };

    let tem_2 = {
      age: "2",
      quantity: 1,
    };

    let datas = [];
    let lengths = 0;
    lengths = childElement.length;
    if (age == -2) {
      lengths = childElement.length - 1;
    }

    for (let index = 0; index < lengths; index++) {
      tem = {
        age: childElement[index].value,
        quantity: 1,
      };
      datas.push(tem);
    }

    if (age == 2) {
      datas.push(tem_2);
    }
    setChildAgesOneway(datas);
  };

  const totalChild = (numberOfChilds) => {
    let childElement = [];
    let childrenAge = [];

    if (childAgesOneway?.length) {
      for (let index = 0; index < childAgesOneway?.length; index++) {
        const element = childAgesOneway[index];
        for (let i = 0; i < childAgesOneway[index]?.quantity; i++) {
          childrenAge.push(element.age);
        }
      }
    }

    for (let index = 0; index < numberOfChilds; index++) {
      childElement.push(
        <div className="form-group ms-1">
          <label htmlFor="Age">Child {index + 1} Age</label>
          <select
            className="form-select childElement"
            style={{
              width: "75px",
            }}
            defaultValue={childrenAge[index]}
            onChange={(e) => {
              setOnewayChildAge("", e.target.value);
            }}
          >
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
      );
      setAllchilds([...childElement]);
    }

    if (numberOfChilds < 1) {
      setAllchilds("");
    }
  };

  useEffect(() => {
    totalChild(numberOfChilds);
  }, [numberOfChilds]);

  return (
    <div className="_main">
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      {/*  */}
      <p className="container _rs_mobile">
        <p
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <span style={{ flex: 1, fontSize: "14px" }}>
            {FlyingFrom}{" "}
            <img
              src="/assets/img/airplane.svg"
              alt=""
              class="img-fluid service-icon service-icon_flight mr-1"
              style={{
                transform: "rotate(90deg)",
                marginLeft: "10px",
                marginRight: "5px",
              }}
            />{" "}
            {FlyingTo}{" "}
            <img
              src="/assets/img/airplane.svg"
              alt=""
              class="img-fluid service-icon service-icon_flight"
            />{" "}
            {showDate}
          </span>
          {/*eslint-disable*/}
          <a
            style={{
              backgroundColor: "#001787",
              color: "white",
            }}
            class="btn "
            onClick={() => {
              setshowrbar(!showrbar);
            }}
          >
            {/*eslint-disable*/}
            <span style={{ fontSize: "14px" }}>Modified search</span>
          </a>
        </p>
      </p>
      <div
        id="researchPanel"
        style={{ display: `${showrbar ? "block" : "none"}` }}
      >
        <div className="container search-box_content_ _rs">
          <div className="__search-box-container  search-box-container ">
            <div className="search-box_row justify-content-center">
              <div className="search-box_col">
                <div className="search-box">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="search-tabs d-flex flex-wrap">
                        <input
                          className="checkbox d-none"
                          id="tab1"
                          type="radio"
                          name="tabsA"
                          ref={roundRef}
                        />
                        <label
                          className="
                        checkbox-label
                        d-inline-block
                        font-weight-500
                        me-2
                        border
                        rounded
                        fs-14
                        bg-white
                      "
                          htmlFor="tab1"
                        >
                          Round trip
                        </label>
                        <input
                          className="checkbox d-none"
                          id="tab2"
                          type="radio"
                          name="tabsA"
                          ref={oneWayRef}
                        />
                        <label
                          className="
                        checkbox-label
                        d-inline-block
                        font-weight-500
                        me-2
                        border
                        rounded
                        fs-14
                        bg-white
                      "
                          htmlFor="tab2"
                        >
                          One way
                        </label>

                        <div
                          aria-details="round-trip"
                          className="search-content d-none w-100 pt-3"
                          id="search-content1"
                        >
                          <form
                            className="modify-search"
                            onSubmit={searchHandlerRoundTrip}
                          >
                            <div className="row align-items-end">
                              <div className="col-md-12">
                                <div className="row g-2 position-relative">
                                  <div className="col-md-6 col-lg-3">
                                    <div className="input-group">
                                      <div className="form-floating flight-form">
                                        <label>From</label>
                                        <Select
                                          components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                          }}
                                          options={options}
                                          placeholder="Flying From"
                                          value={{ label: FlyingFrom }}
                                          noOptionsMessage={() =>
                                            "Please Search"
                                          }
                                          onChange={(e) => {
                                            setFlyingFrom(e.value);
                                          }}
                                          onFocus={(e) => {
                                            if (window.innerWidth < 426) {
                                              window.scroll(0, 300);
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-3">
                                    <div className="form-floating flight-to">
                                      <Select
                                        components={{
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                        options={options}
                                        id="floatingInput"
                                        placeholder="Flying To"
                                        value={{ label: FlyingTo }}
                                        noOptionsMessage={() => "Please Search"}
                                        onChange={(e) => {
                                          setFlyingTo(e.value);
                                        }}
                                        onFocus={(e) => {
                                          if (window.innerWidth < 426) {
                                            window.scroll(0, 300);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (window.innerWidth < 426) {
                                            window.scroll(0, 350);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-3">
                                    <DateRangePicker
                                      startDate={DepartureDate}
                                      numberOfMonths={1}
                                      displayFormat="DD/MM/YYYY"
                                      startDateId="start-date"
                                      startDatePlaceholderText="Departure"
                                      endDatePlaceholderText="Return Date"
                                      endDate={ReturnDate}
                                      endDateId="end-date"
                                      onDatesChange={({
                                        startDate,
                                        endDate,
                                      }) => {
                                        setDepartureDate(startDate);
                                        setReturnDate(endDate);

                                        if (endDate == null) {
                                          setReturnDate(startDate);
                                        }
                                      }}
                                      focusedInput={focusedInput}
                                      onFocusChange={(focusedInput) =>
                                        setFocusedInput(focusedInput)
                                      }
                                    />
                                  </div>

                                  <div
                                    className="_new_liner"
                                    style={{ display: "none" }}
                                  ></div>

                                  <div className="col-md-6 col-lg-3">
                                    <button
                                      style={{
                                        backgroundColor: "#001787",
                                        border: "none",
                                        color: "white",
                                      }}
                                      type="submit"
                                      className="btn btn-search  _lap_btn"
                                    >
                                      <span className="_hide"> Modify</span>{" "}
                                      Search
                                    </button>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12">
                                    <Form className="search-select d-flex mt-3">
                                      <NavDropdown
                                        title={`${
                                          numberOfAdults +
                                          numberOfChilds +
                                          numberOfInfants
                                        } Travellers, ${flightClassFullName}`}
                                        className="travellers-dropdown movePlace"
                                      >
                                        <ul class="m-0 p-0">
                                          <li className="fs-14 d-flex justify-content-between align-items-center">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfAdults}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Adult<span>s</span>
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfAdults(
                                                    numberOfAdults > 1
                                                      ? numberOfAdults - 1
                                                      : 1
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  setNumberOfAdults(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfAdults + 1
                                                      : 1
                                                  );
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                          <li className="fs-14 d-flex justify-content-between align-items-center">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfChilds}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Child
                                              </span>
                                              <span className="cat-info fs-12">
                                                {" "}
                                                2-12 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfChilds(
                                                    numberOfChilds > 0
                                                      ? numberOfChilds - 1
                                                      : 0
                                                  );
                                                  setOnewayChildAge(-2);
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  setNumberOfChilds(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfChilds + 1
                                                      : 0
                                                  );
                                                  setOnewayChildAge(2);
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>

                                          {allchilds && (
                                            <li className="fs-14 d-flex">
                                              {allchilds}
                                            </li>
                                          )}

                                          <li className="fs-14 d-flex justify-content-between align-items-center">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfInfants}
                                              </span>
                                              <span className="fs-14 font-weight-500 ms-1">
                                                {" "}
                                                Infant
                                              </span>
                                              <span className="cat-info fs-12 ms-1">
                                                Below 2 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfInfants(
                                                    numberOfInfants > 0
                                                      ? numberOfInfants - 1
                                                      : 0
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  setNumberOfInfants(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfInfants + 1
                                                      : 0
                                                  );
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="class-type mt-2">
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="economy"
                                              name="classType"
                                              defaultChecked={
                                                flightClassFullName == "Economy"
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                setFlightClass("Y");
                                                setFlightClassFullName(
                                                  "Economy"
                                                );
                                              }}
                                            />
                                            <label class="ms-2" for="economy">
                                              Economy
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="business"
                                              name="classType"
                                              defaultChecked={
                                                flightClassFullName ==
                                                "Business"
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("C");
                                                setFlightClassFullName(
                                                  "Business"
                                                );
                                              }}
                                            />
                                            <label class="ms-2" for="business">
                                              Business
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy"
                                              name="classType"
                                              defaultChecked={
                                                flightClassFullName ==
                                                "First Class"
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("J");
                                                setFlightClassFullName(
                                                  "First Class"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              for="premiumEconomy"
                                            >
                                              First Class
                                            </label>
                                          </div>

                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy"
                                              name="classType"
                                              defaultChecked={
                                                flightClassFullName ==
                                                "Premium Economy"
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("S");
                                                setFlightClassFullName(
                                                  "Premium Economy"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              for="premiumEconomy"
                                            >
                                              Premium Economy
                                            </label>
                                          </div>
                                        </div>

                                        {/* <div >
                                          <button
                                            className='btn btn-dark w-100 mt-1'
                                            type='button'
                                            onClick={() => {
                                              const dropdown =
                                                document.querySelector(
                                                  '.dropdown-menu'
                                                )

                                              const topDropDown =
                                                document.querySelector(
                                                  '.tra-boxx'
                                                )

                                              const arialExp =
                                                document.querySelector(
                                                  '.dropdown-toggle'
                                                )

                                              dropdown?.classList?.remove('show')
                                              topDropDown?.classList?.remove(
                                                'show'
                                              )
                                              arialExp.setAttribute(
                                                'aria-expanded',
                                                'false'
                                              )
                                            }}
                                          >
                                            Confirm
                                          </button>
                                        </div> */}
                                      </NavDropdown>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>

                        <p>
                          <p></p>
                        </p>
                        <div
                          aria-details="one-way-trip part"
                          className="search-content d-none w-100 pt-3"
                          id="search-content2"
                        >
                          <form
                            className="modify-search"
                            onSubmit={searchHandlerOneWay}
                          >
                            <div className="row align-items-end">
                              <div className="col-md-12">
                                <div className="row g-2 position-relative">
                                  <div className="col-md-6 col-lg-3">
                                    <div className="input-group">
                                      <div className="form-floating flight-form">
                                        <label>From</label>
                                        <Select
                                          components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                          }}
                                          options={options}
                                          placeholder="Flying From"
                                          value={{ label: FlyingFrom }}
                                          noOptionsMessage={() =>
                                            "Please Search"
                                          }
                                          onChange={(e) => {
                                            setFlyingFrom(e.value);
                                          }}
                                          onFocus={(e) => {
                                            if (window.innerWidth < 426) {
                                              window.scroll(0, 200);
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-3">
                                    <div className="form-floating flight-to">
                                      <Select
                                        components={{
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                        options={options}
                                        id="floatingInput"
                                        placeholder="Flying To"
                                        value={{ label: FlyingTo }}
                                        noOptionsMessage={() => "Please Search"}
                                        onChange={(e) => {
                                          setFlyingTo(e.value);
                                        }}
                                        onFocus={(e) => {
                                          if (window.innerWidth < 426) {
                                            window.scroll(0, 300);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (window.innerWidth < 426) {
                                            window.scroll(0, 350);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-3 w-full ">
                                    <SingleDatePicker
                                      displayFormat="DD/MM/YYYY"
                                      date={DepartureDate}
                                      numberOfMonths={1}
                                      onDateChange={(date) => {
                                        setDepartureDate(date);
                                      }}
                                      focused={focused}
                                      onFocusChange={({ focused }) =>
                                        setFocused(focused)
                                      }
                                      placeholder="Departure Date"
                                      id="date"
                                    />
                                  </div>
                                  <div className="col-md-6 col-lg-3">
                                    <button
                                      type="submit"
                                      style={{
                                        backgroundColor: "#001787",
                                        border: "none",
                                      }}
                                      className="btn btn-primary btn-search"
                                    >
                                      Modify Search
                                    </button>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12">
                                    <Form className="search-select d-flex mt-3">
                                      <NavDropdown
                                        title={`${
                                          numberOfAdults +
                                          numberOfChilds +
                                          numberOfInfants
                                        } Travellers, ${flightClassFullName}`}
                                        className="travellers-dropdown"
                                      >
                                        <ul class="m-0 p-0">
                                          <li className="fs-14 d-flex justify-content-between">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfAdults}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Adult<span>s</span>
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfAdults(
                                                    numberOfAdults > 1
                                                      ? numberOfAdults - 1
                                                      : 1
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  setNumberOfAdults(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfAdults + 1
                                                      : 1
                                                  );
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                          <li className="fs-14 d-flex justify-content-between">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfChilds}
                                              </span>
                                              <span className="fs-14 font-weight-500">
                                                {" "}
                                                Child
                                              </span>
                                              <span className="cat-info fs-12">
                                                {" "}
                                                2-12 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfChilds(
                                                    numberOfChilds > 0
                                                      ? numberOfChilds - 1
                                                      : 0
                                                  );
                                                  setOnewayChildAge(-2);
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  setNumberOfChilds(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfChilds + 1
                                                      : 0
                                                  );
                                                  setOnewayChildAge(2);
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>

                                          {allchilds && (
                                            <li className="fs-14 d-flex">
                                              {allchilds}
                                            </li>
                                          )}

                                          <li className="fs-14 d-flex justify-content-between">
                                            <span>
                                              <span className="font-weight-500">
                                                {numberOfInfants}
                                              </span>
                                              <span className="fs-14 font-weight-500 ms-1">
                                                {" "}
                                                Infant
                                              </span>
                                              <span className="cat-info fs-12 ms-1">
                                                Below 2 years
                                              </span>
                                            </span>
                                            <div className="spinner d-flex">
                                              <span
                                                className="minus"
                                                onClick={(e) => {
                                                  setNumberOfInfants(
                                                    numberOfInfants > 0
                                                      ? numberOfInfants - 1
                                                      : 0
                                                  );
                                                }}
                                              >
                                                -
                                              </span>
                                              <span
                                                className="plus"
                                                onClick={(e) => {
                                                  setNumberOfInfants(
                                                    numberOfAdults +
                                                      numberOfChilds +
                                                      numberOfInfants <
                                                      9
                                                      ? numberOfInfants + 1
                                                      : 0
                                                  );
                                                }}
                                              >
                                                +
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="class-type mt-2">
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              defaultChecked={
                                                flightClassFullName == "Economy"
                                                  ? true
                                                  : false
                                              }
                                              id="economy-s"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("Y");
                                                setFlightClassFullName(
                                                  "Economy"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              htmlFor="economy-s"
                                            >
                                              Economy
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              defaultChecked={
                                                flightClassFullName ==
                                                "Business"
                                                  ? true
                                                  : false
                                              }
                                              id="business-s"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("C");
                                                setFlightClassFullName(
                                                  "Business"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              htmlFor="business-s"
                                            >
                                              Business
                                            </label>
                                          </div>
                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              defaultChecked={
                                                flightClassFullName ==
                                                "First Class"
                                                  ? true
                                                  : false
                                              }
                                              id="premiumEconomy-s"
                                              name="classType"
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("J");
                                                setFlightClassFullName(
                                                  "First Class"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              htmlFor="premiumEconomy-s"
                                            >
                                              First Class
                                            </label>
                                          </div>

                                          <div class="custom-control custom-radio pl-0">
                                            <input
                                              type="radio"
                                              id="premiumEconomy"
                                              name="classType"
                                              defaultChecked={
                                                flightClassFullName ==
                                                "Premium Economy"
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                console.log("Hitted");
                                                setFlightClass("S");
                                                setFlightClassFullName(
                                                  "Premium Economy"
                                                );
                                              }}
                                            />
                                            <label
                                              class="ms-2"
                                              for="premiumEconomy"
                                            >
                                              Premium Economy
                                            </label>
                                          </div>
                                        </div>
                                      </NavDropdown>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReSearchBar;
