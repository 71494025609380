/* eslint-disable */
import { useContext, useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { useCookies } from "react-cookie";
import FacebookLogin from "react-facebook-login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mainContextApi } from "../../../App";
import "./login.css";
import {
  Link,
  Navigate,
  useNavigate,
  useHistory,
  useLocation,
} from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dataContext = useContext(mainContextApi);
  const location = useLocation();
  const [hideOrNot, setHideOrNot] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [cookie, setCookie] = useCookies(["auth_info"]);
  let from = location.state?.from?.pathname || "/";

  const [userLoginInfo, setUserLoginInfo] = useState({
    email: "",

    password: "",
  });

  const onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserLoginInfo({ ...userLoginInfo, [name]: value });
  };

  const onSubmitHandler = (e) => {
    setIsDisable(true);
    e.preventDefault();
    if (userLoginInfo.email === "" || userLoginInfo.password === "") {
      setIsDisable(false);
      return toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        zIndex: 2,
      });
    }
    fetch(`${process.env.REACT_APP_DEV_API_URL}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_LOGIN_X_API,
      },
      body: JSON.stringify(userLoginInfo),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.success == true) {
          toast.success("Login successful", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 2,
          });

          setCookie("auth_info", response, { path: "/" });
          setCookie("token", response?.authorization?.token, { path: "/" });
          localStorage.setItem("token", response?.authorization?.token);
          localStorage.setItem("_user_id_", response.user.id);
          dataContext.LogIn(true);
          setTimeout(() => {
            navigate(-1);
          }, 1000);

          setIsDisable(false);
        } else {
          setIsDisable(false);
          toast.error(response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [google_Client, setgoogle_Client] = useState("");
  const [Facebook_AppID, setFacebook_AppID] = useState("");

  setTimeout(() => {
    let googleClient = localStorage.getItem("googleClient");
    setgoogle_Client(googleClient);

    let FacebookAppID = localStorage.getItem("FacebookAppID");
    setFacebook_AppID(FacebookAppID);
  }, 100);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="auth-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-card">
                <div className="form-card_body">
                  <form onSubmit={onSubmitHandler}>
                    <div className="text-center">
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className="col-md-8 mt-2 mt-md-0">
                          {Facebook_AppID && (
                            <FacebookLogin
                              appId={Facebook_AppID}
                              cssClass="fb-button"
                              icon={"fa-facebook"}
                              fields="name,email,picture"
                              textButton=" Login with Facebook"
                              onClick={(e) => {
                                console.log("--------", e);
                              }}
                              callback={(e) => {
                                if (Object.keys(e).length !== 1) {
                                  const auth_info = {
                                    fb_info: e,
                                    google_info: undefined,
                                  };
                                  setCookie("auth_info", auth_info, {
                                    path: "/",
                                  });
                                  window.location.assign("/");
                                  dataContext.setIsLogedin(true);
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className="col-md-8 mt-2 mt-md-0">
                          {google_Client && (
                            <GoogleLogin
                              clientId={google_Client}
                              className="btn btn-block btn-white mb-4 google-button"
                              onSuccess={(e) => {
                                if (Object.keys(e).length !== 1) {
                                  const googleBody = {
                                    google_info: {
                                      id: e.googleId,
                                      profileObj: e.profileObj,
                                    },
                                    facebook_info: undefined,
                                  };
                                  setCookie("auth_info", googleBody, {
                                    path: "/",
                                  });
                                  window.location.assign("/");
                                  dataContext.setIsLogedin(true);
                                }
                              }}
                              onFailure={(e) => {
                                // window.location.assign('/')
                              }}
                              cookiePolicy={"single_host_origin"}
                            />
                          )}
                        </div>
                      </div>
                      <span className="divider d-flex align-items-center text-muted mb-4">
                        OR
                      </span>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.44"
                          height="16.293"
                          viewBox="0 0 24.44 16.293"
                        >
                          <path
                            id="Path_122"
                            data-name="Path 122"
                            d="M23.187,85.333H1.253A1.257,1.257,0,0,0,0,86.586v13.787a1.257,1.257,0,0,0,1.253,1.253H23.187a1.257,1.257,0,0,0,1.253-1.253V86.586A1.257,1.257,0,0,0,23.187,85.333Zm-.47.94-9.775,7.332a1.31,1.31,0,0,1-1.442,0L1.724,86.273Zm-5.222,7.816,5.327,6.58c.005.007.012.012.018.018H1.6c.006-.006.012-.011.018-.018l5.327-6.58a.47.47,0,0,0-.731-.592L.94,100.013V86.86l10,7.5a2.243,2.243,0,0,0,2.569,0l10-7.5v13.152L18.225,93.5a.47.47,0,0,0-.731.592Z"
                            transform="translate(0 -85.333)"
                            fill="gray"
                          />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          placeholder="Someone@example.com"
                          className="form-control"
                          name="email"
                          value={userLoginInfo.email}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          id="Component_2_1"
                          data-name="Component 2 – 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.115"
                          height="22.071"
                          viewBox="0 0 16.115 22.071"
                        >
                          <path
                            id="Path_123"
                            data-name="Path 123"
                            d="M172.561,296.171a.647.647,0,0,0,.647-.647v-2.678a.647.647,0,1,0-1.293,0v2.678A.647.647,0,0,0,172.561,296.171Zm0,0"
                            transform="translate(-164.503 -279.603)"
                            fill="gray"
                          />
                          <path
                            id="Path_124"
                            data-name="Path 124"
                            d="M15.468,7.092h-3.06V3.943A4.166,4.166,0,0,0,8.057,0a4.166,4.166,0,0,0-4.35,3.943v3.15H.647A.647.647,0,0,0,0,7.739v8.269a6.069,6.069,0,0,0,6.062,6.062h3.99a6.069,6.069,0,0,0,6.062-6.062V7.739A.647.647,0,0,0,15.468,7.092ZM5,3.943A2.878,2.878,0,0,1,8.057,1.293a2.879,2.879,0,0,1,3.057,2.649v3.15H5Zm9.821,12.066a4.775,4.775,0,0,1-4.769,4.769H6.062a4.775,4.775,0,0,1-4.769-4.769V8.386H14.821Zm0,0"
                            fill="gray"
                          />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type={hideOrNot ? "password" : "text"}
                          name="password"
                          placeholder="******************"
                          className="form-control password"
                          value={userLoginInfo.password}
                          onChange={onChangeHandler}
                        />
                        <i
                          className="fa fa-eye-slash"
                          onClick={() => {
                            setHideOrNot(!hideOrNot);
                          }}
                        ></i>
                      </div>
                    </div>
                    <div
                      className="
                      d-flex
                      justify-content-between
                      align-items-center
                      mb-3
                    "
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>

                      <Link className="input-label-secondary" to="/forget">
                        Forgot Password?
                      </Link>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-block btn-authentication w-100"
                      disabled={isDisable}
                    >
                      Sign in
                    </button>
                    <div className="bottom-text text-center mt-3 font-weight-500">
                      Don't have an account?&nbsp;
                      <Link to="/registration" className="fw-medium">
                        Sign Up
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
