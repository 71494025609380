import { useLocation, useParams } from "react-router-dom";

import BookTicket from "./BookTicket";

const TicketBook = () => {
  const { state } = useLocation();
  const { flight, legDescription } = state || {};

  return (
    <>
      {" "}
      <BookTicket flight={flight} legDescription={legDescription} />
    </>
  );
};

export default TicketBook;
