import React from 'react';

const InfoBox = ({ title, description, img }) => {
  return (
    <div class="col-md-4">
      <div class="text-center">
        <img
          class="feature-icon mb-4"
          src={img}
          alt={title}
          width="150px"
          height="150px"
        />
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default InfoBox;
