/* eslint-disable */
import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { NavDropdown, Dropdown } from "react-bootstrap";
import { mainContextApi } from "../../App";
import dp from "./dp.png";
import Logo from "./logo.png";
import "./navstyle.css";
import Cookies from "universal-cookie";
import { useQuery } from "@tanstack/react-query";

const Navbar = (props) => {
  const navigate = useNavigate();
  const userContext = useContext(mainContextApi);
  const [cookie, setCookie, removeCookie] = useCookies(["_jwt"]);
  const cookies = new Cookies();

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_API_URL}/user/me`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success == false) {
          localStorage.removeItem("token");
          localStorage.removeItem("_user_id_");
          cookies.remove("auth_info");
          cookies.remove("_user_id_");
          cookies.remove("token");
          localStorage.removeItem("ml");

          throw new Error("User not found!");
        } else {
          localStorage.setItem("ml", data.data.email);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-bg-white position-sticky top-0 start-0 w-100">
      <div className="container">
        <Link className="navbar-brand" to="/">
          {/* <img src='/assets/img/logo-desktop.png' alt='' /> */}
          <img src={Logo} alt="" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse __nav"
          id="navbarSupportedContent"
        >
          <div className="navbar-collapse-header">
            <div className="row">
              <div className="col-6 collapse-brand">
                <Link to="/">
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="col-6 collapse-close">
                <button
                  type="button"
                  className="navbar-toggler"
                  data-bs-toggle="collapse"
                  data-target="#navbar-collapse"
                  aria-expanded="true"
                  aria-label="Toggle navigation"
                  onClick={() => {
                    const dropdown = document.querySelector(".__nav");
                    dropdown.classList.remove("show");
                  }}
                >
                  <span></span> <span></span>
                </button>
              </div>
            </div>
          </div>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Flight
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/packages">
                Packages
              </Link>
            </li>
            {/* <li className='nav-item'>
                <a className='nav-link' href='https://blog.skytrip.com/'>
                  Blog
                </a>
              </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/visa">
                Visa
              </Link>
            </li>
          </ul>
          <hr className="d-lg-none" />
          <ul className="navbar-nav navbar-right">
            {token ? (
              <>
                <NavDropdown
                  title={
                    <img
                      alt=""
                      style={{
                        borderRadius: "50%",
                        border: "1px solid white",
                        height: "35px",
                        width: "35px",
                        marginLeft: "10px",
                      }}
                      src={props?.userIMG ? props.userIMG : dp}
                    ></img>
                  }
                  id="basic-nav-dropdown"
                  className="topbar-dropdown ml-3"
                >
                  <Dropdown.Item>
                    <Link
                      className="nav_item"
                      to="/user-profile"
                      style={{
                        color: "#333",
                        textDecoration: "none",
                      }}
                    >
                      Profile
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link
                      className="nav_item"
                      style={{
                        color: "#333",
                        textDecoration: "none",
                      }}
                      to="/thistory"
                    >
                      Ticket History
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      removeCookie("auth_info");
                      removeCookie("token");
                      localStorage.removeItem("ml");
                      localStorage.removeItem("_user_id_");
                      localStorage.removeItem("token");
                      localStorage.removeItem("_user_id_slug");
                      userContext.LogIn(false);
                    }}
                  >
                    Log Out
                  </Dropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link to={"/login"} className="btn btn-start">
                    Log in
                  </Link>
                </li>{" "}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
