import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const FlightCardSkeleton = () => {
  const card = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
  ];

  return (
    // <div className="container pt-4">
    //   <Row className="gap-3 gap-lg-0">
    //     <Col lg={3}>
    //       <SkeletonTheme
    //         baseColor="#e4e4e4"
    //         highlightColor="#d8d6d6"
    //         className="flight_card_skeleton"
    //       >
    //         <p>
    //           <Skeleton
    //             style={{
    //               height: "100vh",
    //             }}
    //           />
    //         </p>
    //       </SkeletonTheme>
    //     </Col>
    //     <Col lg={9}>
    //       <SkeletonTheme
    //         baseColor="#e4e4e4"
    //         highlightColor="#d8d6d6"
    //         className="d-flex gap-3 flex-column"
    //       >
    //         <Skeleton
    //           style={{
    //             width: "100%",
    //             height: "210px",
    //           }}
    //         />
    //         <Skeleton
    //           style={{
    //             width: "100%",
    //             height: "210px",
    //           }}
    //         />
    //         <Skeleton
    //           style={{
    //             width: "100%",
    //             height: "210px",
    //           }}
    //         />
    //       </SkeletonTheme>
    //     </Col>
    //   </Row>
    // </div>
    <div className="container">
      <Row className="gap-3 gap-lg-0 my-3" gap={4}>
        <Col lg={3}>
          <div
            style={{
              borderRadius: "10px",
              color: "#000",
              background: "#f1f1f1",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {/* airline name  */}
            <div>
              <div className="d-flex justify-content-between p-3">
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "100px",
                        height: "20px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "40px",
                        height: "13px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "210px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "210px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "210px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "210px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </div>

            {/* Stop type */}
            <div>
              <div className="d-flex justify-content-between p-3">
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "100px",
                        height: "20px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "40px",
                        height: "13px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>

                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>

                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>

                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </div>

            {/* Take off */}
            <div>
              <div className="d-flex justify-content-between p-3">
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "80px",
                        height: "20px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "40px",
                        height: "13px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>

                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>

                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={9}>
          {card?.map((card) => (
            <div
              style={{
                borderRadius: "10px",
                background: "#f1f1f1",
                color: "#000",
                height: "210px",
                marginBottom: "10px",
              }}
              className="p-1 p-md-4"
            >
              <Row className="">
                {" "}
                <Col xs={6} lg={2} className="d-inline-block">
                  <div className="pe-5 pe-md-0">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>
                <Col xs={6} lg={7} className="d-inline-block">
                  <div>
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "10px",
                        }}
                      />
                    </SkeletonTheme>
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "120px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                    <div className="d-flex flex-wrap gap-4 mt-2">
                      <SkeletonTheme
                        baseColor="#e4e4e4"
                        highlightColor="#d8d6d6"
                      >
                        <Skeleton
                          style={{
                            width: "90px",
                            height: "40px",
                          }}
                        />
                      </SkeletonTheme>
                      <SkeletonTheme
                        baseColor="#e4e4e4"
                        highlightColor="#d8d6d6"
                      >
                        <Skeleton
                          style={{
                            width: "90px",
                            height: "40px",
                          }}
                        />
                      </SkeletonTheme>
                    </div>
                    <div className="mt-2 d-none d-lg-block">
                      <SkeletonTheme
                        baseColor="#e4e4e4"
                        highlightColor="#d8d6d6"
                      >
                        <Skeleton
                          style={{
                            width: "200px",
                            height: "20px",
                          }}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>
                </Col>{" "}
                <Col className="d-inline-block">
                  <div className="d-none d-lg-flex flex-column gap-2 ">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "120px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "150px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                    <div>
                      <SkeletonTheme
                        baseColor="#e4e4e4"
                        highlightColor="#d8d6d6"
                      >
                        <Skeleton
                          style={{
                            width: "150px",
                            height: "14px",
                          }}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default FlightCardSkeleton;
