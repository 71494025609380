import { useLocation } from "react-router-dom";
import Loader from "./Loader.gif";

export default function PackageDetails(props) {
  const location = useLocation();
  const { package_name, summary, src } = location?.state;

  return (
    <div>
      <div>
        <div className="main" style={{ minHeight: "60vh", marginTop: "20px" }}>
          <div
            className="image-section"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={src}
              alt="package"
              style={{
                minHeight: "60px",
                objectFit: "cover",
                minWidth: "200px",
              }}
            />
          </div>
          <div className="container mt-4">
            <h3 className="text-center">{package_name}</h3>
            <div
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
