/* eslint-disable */
import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Cookies from "universal-cookie";
import axios from "axios";
import "./invoice.css";

const Invoice = ({ tran }) => {
  const cookies = new Cookies();

  const printRef = useRef();
  const [InvoiceInformations, setInvoiceInformations] = useState();

  const [ErrorOccured, setErrorOccured] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_CERT_GET_BOOKING_API}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": `${process.env.REACT_APP_CERT_GET_BOOKING_X_API}`,
      },
      body: JSON.stringify({
        ExistedToken: cookies.get("token"),
        RequestBody: {
          pnr_no: localStorage.getItem("pnr"),
        },
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        axios({
          url: `${process.env.REACT_APP_CERT_PNR_RESERVATION_API}`,
          method: "post",
          headers: {
            "x-api-key": `${process.env.REACT_APP_CERT_PNR_RESERVATION_X_API}`,
          },
          data: {
            invoice_id: tran,
          },
        })
          .then(({ data }) => {
            if (data.statusCode === 200) {
              setInvoiceInformations(data.body);
              fetch(`${process.env.REACT_APP_CERT_MAKE_INVOICE_API}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "x-api-key": `${process.env.REACT_APP_CERT_MAKE_INVOICE_X_API}`,
                },
                body: JSON.stringify({
                  invoice_id: tran,
                }),
              })
                .then((res) => res.json())
                .then((response) => {
                  if (localStorage.getItem("_ms") === "x*1_132") {
                    fetch(`${process.env.REACT_APP_CERT_SEND_MAIL_API}`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        invoice: response.body,
                        ticket: data.body,
                      }),
                    })
                      .then((res) => res.json())
                      .then((response) => {
                        localStorage.setItem("_ms", "x*1-132");
                      });
                  }
                });
            } else {
              setErrorOccured(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }, [tran]);

  // Send Mail Both ticket page and invoice

  return (
    <div>
      {ErrorOccured ? (
        <div className="">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              fontSize: "20px",
            }}
          >
            <p>Ticket Process has been interrupted</p>
            <p>
              Your transaction id is -{" "}
              <span style={{ color: "green" }}>{tran}</span>{" "}
            </p>
            <p>Please Contact with Skytrip team.</p>
            <p>Contact - </p>
            <p>Phone: +880 183-070-1422</p>
            <p> Address: Bdtask limited,Mannan plaza, Khilkhet</p>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="container"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-success mb-4 mt-4"
              style={{ width: "200px" }}
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
          <div
            ref={printRef}
            className=""
            style={{
              fontSize: "15px",
              backgroundColor: "#f7f7f7",
              fontWeight: "400",
              lineHeight: "1.5",
              color: "#212529",
              textAlign: "left",
            }}
          >
            <div
              style={{
                width: "100%",
                paddingRight: "15px",
                paddingLeft: "15px",
                marginRight: "auto",
                maxWidth: "1140px",
                marginLeft: "auto",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  marginRight: "-15px",
                  marginLeft: "-15px",
                  display: "flex",
                }}
              >
                <div>
                  <div className="invoice-wrap">
                    <div className="slogo" style={{ marginBottom: "1.5rem" }}>
                      <div>
                        <img
                          src={"/assets/img/logo-desktop.png"}
                          className="invoice-logo"
                          alt="logo"
                        />
                      </div>
                    </div>

                    <h6
                      style={{
                        paddingBottom: "0.5rem",
                        paddingTop: "0.5rem",
                        marginBottom: "1rem",
                        borderBottom: "1px solid #dee2e6",
                        borderColor: "#707070",
                        fontSize: "1.5 rem",
                        fontWeight: "500",
                        lineHeight: "1.2",
                        marginTop: "0",
                      }}
                    >
                      <span style={{ marginRight: "3rem" }}>
                        On{" "}
                        {InvoiceInformations
                          ? InvoiceInformations.travel_summary[0].departure_date
                          : ""}
                      </span>
                      <span className="float-end">
                        TRIP TO -{" "}
                        {InvoiceInformations
                          ? InvoiceInformations.travel_summary[0]
                              .destination_location
                          : ""}
                      </span>
                    </h6>

                    <div
                      style={{
                        marginRight: "-15px",
                        marginLeft: "-15px",
                        marginBottom: "1rem",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: "15px",
                          maxWidth: "50%",
                          flex: "0 0 50%",
                        }}
                      >
                        <div className="">PREPARED FOR</div>
                        <div style={{ fontWeight: "500" }}>
                          {InvoiceInformations
                            ? InvoiceInformations.prepared_for[0]
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        marginRight: "0",
                        marginLeft: "0",
                        paddingBottom: "1rem",
                        marginBottom: "1rem",
                        borderBottom: "1px solid #dee2e6",
                        borderColor: "#707070",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          paddingRight: "0",
                          paddingLeft: "0",
                          flex: "0 0 33.666667%",
                          maxWidth: "66.666667%",
                        }}
                      >
                        <div style={{ marginBottom: "0.25rem" }}>
                          RESERVATION CODE -{" "}
                          <span style={{ color: "red" }}>
                            {InvoiceInformations
                              ? InvoiceInformations.reservation_code
                              : ""}
                          </span>
                        </div>
                        <div>
                          AIRLINE RESERVATION CODE -{" "}
                          <span style={{ color: "red" }}>
                            {InvoiceInformations
                              ? InvoiceInformations.air_line_pnr
                              : ""}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          paddingRight: "0",
                          paddingLeft: "0",
                          flex: " 0 0 66.666667%",
                          maxWidth: "66.666667%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginRight: "-15px",
                            marginLeft: "-15px",
                          }}
                        >
                          <div
                            style={{
                              flex: "0 0 33.333333%",
                              maxWidth: "33.333333%",
                              paddingLeft: "15px",
                              paddingRight: "1px solid #dee2e6",
                              borderColor: "#707070",
                            }}
                          >
                            <div style={{ marginBottom: "0.25rem" }}>
                              Passenger name
                            </div>
                            <div style={{ fontWeight: "500" }}>
                              {InvoiceInformations
                                ? InvoiceInformations.prepared_for[0]
                                : ""}
                            </div>
                          </div>
                          <div
                            style={{
                              flex: "0 0 33.333333%",
                              maxWidth: "33.333333%",
                              paddingLeft: "15px",
                            }}
                          >
                            <div style={{ marginBottom: "0.25rem" }}>
                              Seats :
                            </div>
                            <div style={{ fontWeight: "500" }}>
                              Check-in Required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        marginBottom: "0.25rem",
                        display: "flex",
                        flexFlow: "row wrap",
                      }}
                    >
                      {InvoiceInformations
                        ? InvoiceInformations.ticket_number.map(
                            (tnoc, index) => (
                              <>
                                <div
                                  style={{
                                    flex: "0 0 33.333333%",
                                    maxWidth: "33.333333%;",
                                    paddingLeft: "15px",
                                  }}
                                  key={tnoc}
                                >
                                  eTicket Receipts(s) :
                                  <div style={{ fontWeight: "500" }}>
                                    {tnoc.ticket_number}
                                  </div>
                                </div>
                              </>
                            )
                          )
                        : ""}
                    </div>

                    <div style={{ marginBottom: "20px" }}></div>

                    {InvoiceInformations
                      ? InvoiceInformations.all_routes_info.map(
                          (infos, index) => (
                            <>
                              <div
                                key={index}
                                style={{
                                  marginRight: "-15px",
                                  marginLeft: "-15px",
                                  marginBottom: "1rem",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    flex: "0 0 33.333333%",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    maxWidth: "33.333333%",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="fas fa-plane mr-2"
                                      style={{ marginRight: "0.5rem" }}
                                    ></i>
                                    DEPARTURE :{" "}
                                    {infos.departure_date
                                      ? infos.departure_date
                                      : ""}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    flex: "0 0 66.666667%",
                                    maxWidth: "66.666667%",
                                  }}
                                >
                                  <div>
                                    Please verify flight times prior to
                                    departure
                                  </div>
                                </div>
                              </div>

                              <div
                                key={index}
                                style={{
                                  marginBottom: "0.5rem",
                                  marginRight: "0",
                                  display: "flex",
                                  marginLeft: "0",
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth: "25%",
                                    flex: "0 0 25%",
                                    backgroundColor: "rgb(199 199 199)",
                                    padding: "43px 0px 42px 48px",
                                    borderBottomLeftRadius: "100px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    {infos.flight_name}
                                    <br />
                                    {infos.flight_name} {infos.flight_number}
                                  </div>
                                  <div>Duration :</div>
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    {infos.flying_time}
                                  </div>
                                  <div>Cabin :</div>
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    {infos.cabin_class}
                                  </div>
                                  <div>Status :</div>
                                  <div style={{ fontWeight: "500" }}>
                                    Confirmed
                                  </div>
                                </div>

                                <div
                                  style={{
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                    borderTop: "1px solid #929292",
                                    borderBottom: "1px solid #929292",
                                    flex: "0 0 50%",
                                    maxWidth: "50%",
                                  }}
                                >
                                  <div
                                    style={{
                                      paddingLeft: "3rem",
                                      paddingRight: "3rem",
                                      paddingBottom: "1.5rem",
                                      paddingTop: "1.5rem",
                                      alignItems: "center",
                                      marginRight: "0",
                                      marginLeft: "0",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingRight: "0",
                                        paddingLeft: "0",
                                        flexBasis: "0",
                                        msFlexPositive: "1",
                                        flexGrow: "1",
                                        minWidth: "0",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <h4>{infos.departure_airport_code}</h4>
                                      <div>
                                        {infos.departure_country}
                                        <br />
                                        {/* {infos.departure_airport} */}
                                      </div>
                                    </div>
                                    <i className="fas fa-caret-right mx-3"></i>
                                    <div
                                      style={{
                                        paddingRight: "0",
                                        paddingLeft: "0",
                                        flexBasis: "0",
                                        msFlexPositive: "1",
                                        flexGrow: "1",
                                        minWidth: "0",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <h4>{infos.arrival_airport_code}</h4>
                                      <div>
                                        {infos.arrival_country}
                                        <br />
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      borderTop: "1px solid #dee2e6",
                                      borderColor: "#707070",
                                      marginRight: "0",
                                      marginLeft: "0",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: "3rem",
                                        paddingRight: "3rem",
                                        paddingBottom: "1.5rem",
                                        paddingTop: "1.5rem",
                                        flexBasis: "0",
                                        flexGrow: "1",
                                        borderRight: "1px solid #707070",
                                      }}
                                    >
                                      Depart. At :
                                      <h4>{infos.departure_time}</h4>
                                      Terminal :{" "}
                                      {infos.departure_terminal !== null ? (
                                        infos.departure_terminal
                                      ) : (
                                        <span style={{ fontSize: "10px" }}>
                                          N/A
                                        </span>
                                      )}
                                    </div>

                                    <div
                                      style={{
                                        paddingLeft: "3rem",
                                        paddingRight: "3rem",
                                        paddingBottom: "1.5rem",
                                        paddingTop: "1.5rem",
                                        flexBasis: "0",
                                        flexGrow: "1",
                                      }}
                                    >
                                      Arraival At :<h4>{infos.arrival_time}</h4>
                                      Terminal :{" "}
                                      {infos.arrival_terminal !== null
                                        ? infos.arrival_terminal
                                        : "N/A"}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    padding: "30px",
                                    border: "1px solid rgb(146 146 146)",
                                    flex: "0 0 25%",
                                    maxWidth: "25%",
                                  }}
                                >
                                  <div style={{ marginBottom: "1rem" }}></div>
                                  <div style={{ marginBottom: "1rem" }}>
                                    Distance (in Miles) :<br />
                                    {infos.total_distance}
                                  </div>
                                  <div style={{ marginBottom: "1rem" }}>
                                    Meal Code: {infos.meal_code[0].code}
                                  </div>
                                  <div style={{ marginBottom: "1rem" }}>
                                    Booking Code:{" "}
                                    {infos.booking_code
                                      ? infos.booking_code
                                      : "null"}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginBottom: "1.5rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "-15px",
                                  marginLeft: "-15px",
                                  fontSize: "15px",
                                }}
                              >
                                <div
                                  style={{
                                    paddingRight: "15px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Checked Baggage :{" "}
                                  {infos.checked_baggage_weight_kg} Kg
                                </div>
                                <div
                                  style={{
                                    paddingRight: "15px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Cabin Baggage :{infos.cabin_baggage_kg} Kg
                                </div>
                              </div>
                            </>
                          )
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoice;
