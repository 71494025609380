import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BookingFormSkeleton = () => {
  const card = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  return (
    <div className="container ">
      <Row className="gap-3 gap-lg-0 my-5" gap={4}>
        <Col lg={9}>
          <div
            style={{
              borderRadius: "10px",
              background: "#f1f1f1",
              color: "#000",
              height: "150px",
              marginBottom: "10px",
            }}
            className="p-1 p-md-4"
          >
            <Row className="">
              {" "}
              <Col xs={6} lg={3} className="d-inline-block">
                <div className="pe-5 pe-md-0">
                  <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                    <Skeleton
                      style={{
                        width: "110px",
                        height: "110px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </Col>
              <Col xs={6} lg={3} className="d-inline-block">
                <div>
                  <div className="gap-4 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "50px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="mt-2 d-none d-lg-block">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "50px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </Col>{" "}
              <Col
                lg={3}
                className="text-center fs-14 mt-4 mt-md-0 d-none d-lg-block"
              >
                <div
                  className="d-sm-flex align-items-center justify-content-center"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <div className="mt-2 d-none d-lg-block">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "50px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                

                <div className="d-sm-flex align-items-center justify-content-center">
                  {/* {allItineraries?.refundable ? (
                    <span className="text-danger">Refundable</span>
                  ) : (
                    <span className="text-success">Non-Refundable</span>
                  )} */}
                </div>
              </Col>
              <Col
                lg={3}
                className="d-flex flex-column align-items-center d-none d-lg-block"
              >
                <div className="gap-4 mt-2">
                  <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                    <Skeleton
                      style={{
                        width: "50px",
                        height: "40px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
                <div className="mt-2 d-none d-lg-block">
                  <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                    <Skeleton
                      style={{
                        width: "50px",
                        height: "20px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              borderRadius: "10px",
              background: "#f1f1f1",
              color: "#000",
              height: "730px",
              marginBottom: "10px",
            }}
            className="p-1 p-md-4"
          >
            <div>
              <Row className="">
                {" "}
                <div className="pe-5 pe-md-0 mt-5 d-flex gap-3">
                  <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                    <Skeleton
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </SkeletonTheme>
                  <data>
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "120px",
                          height: "12px",
                        }}
                      />
                    </SkeletonTheme>
                  </data>
                </div>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-5">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-5">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-5">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
              </Row>
            </div>
            <div className="d-none d-lg-block">
              <Row className="">
                {" "}
                <div className="pe-5 pe-md-0 mt-5 d-flex gap-3">
                  <data>
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "20px",
                        }}
                      />
                    </SkeletonTheme>
                  </data>
                </div>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-5">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-5">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-5">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
              </Row>
            </div>
            <div className="d-none d-lg-block">
              <Row className="">
                {" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
              </Row>
            </div>
            <div className="d-none d-lg-block">
              <Row className="">
                {" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
              </Row>
            </div>
            <div className="d-none d-lg-block">
              <Row>
                {" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "30px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
                <Col xs={12} lg={4} className="d-inline-block">
                  <div className="pe-5 pe-md-0 mt-2">
                    <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                      <Skeleton
                        style={{
                          width: "220px",
                          height: "40px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </Col>{" "}
              </Row>
            </div>
            <div className="pe-5 pe-md-0 mt-5">
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "250px",
                    height: "20px",
                  }}
                />
              </SkeletonTheme>
            </div>
            <div className="pe-5 pe-md-0 mt-5 float-end d-flex gap-2 flex-wrap">
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "150px",
                    height: "40px",
                  }}
                />
              </SkeletonTheme>{" "}
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "150px",
                    height: "40px",
                  }}
                />
              </SkeletonTheme>
            </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
              background: "#f1f1f1",
              color: "#000",
              height: "430px",
              marginBottom: "10px",
            }}
            className="p-1 p-md-4"
          >
            <div>
              <div className="pe-5 pe-md-0 mt-5">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
              <div className="pe-5 pe-md-0 mt-1">
                <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                  <Skeleton
                    style={{
                      width: "100%",
                      height: "15px",
                    }}
                  />
                </SkeletonTheme>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div
            style={{
              borderRadius: "10px",
              color: "#000",
              background: "#f1f1f1",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {/* airline name  */}
            <div>
              <div className="  p-3">
                <div className="">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "100px",
                        height: "20px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "80px",
                        height: "13px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </div>

              <div className="d-flex flex-column gap-4">
                <div className="d-flex gap-2 justify-content-between  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-between  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-between  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-between  px-3 ">
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                  <div className="d-inline-block">
                    <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                      <Skeleton
                        style={{
                          width: "100px",
                          height: "25px",
                        }}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div>
              <div className="d-flex justify-content-between p-3">
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "100px",
                        height: "30px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
                <div className="d-inline-block">
                  <SkeletonTheme baseColor="#e2e2e2" highlightColor="#dadada">
                    <Skeleton
                      style={{
                        width: "100px",
                        height: "30px",
                      }}
                    />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
            {/* Stop type */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BookingFormSkeleton;
