import React, { useEffect, useState } from "react";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const Reset = () => {
  const cookies = new Cookies();
  const [isDisable, setIsDisable] = React.useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    const extractedToken = params.get("token");
    const extractedEmail = params.get("email");

    setToken(extractedToken);
    setEmail(extractedEmail);
  }, [searchParams.toString()]);

  const [resetInfo, setResetInfo] = React.useState({
    email: email,
    password: "",
    password_confirmation: "",
    token: token,
  });
  useEffect(() => {
    setResetInfo({
      email: email,
      password: "",
      password_confirmation: "",
      token: token,
    });
  }, [email, token]);


  const onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setResetInfo({ ...resetInfo, [name]: value });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let pattern = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );

    if (
      resetInfo.password !== resetInfo.password_confirmation &&
      !pattern.test(resetInfo.password)
    ) {
      toast.error("Your password and confirm password do not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsDisable(true);
      fetch(`${process.env.REACT_APP_DEV_API_URL}/user/password/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(resetInfo),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success === true) {
            toast.success("Your Password Has Been Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
              pauseOnHover: true,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            localStorage.removeItem("ml");
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            setIsDisable(false);
            toast.error(response.errors.password[0], {
              position: "top-right",
              autoClose: 3000,
              pauseOnHover: true,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="login">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="login-content login-content_bg">
        <form onSubmit={onSubmitHandler}>
          <div className="text-center mb-5">
            <h1 className="mt-0 h2">Reset Password</h1>
            <div className="text-muted">
              For your protection, please verify your identity.
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <label>
                Password{" "}
                {/* <small className='text-muted'>
                  Password Must Be 8 character long, Must Contain 1 digit, 1
                  uppercase, 1 special Char
                </small>{' '} */}
              </label>
            </div>
            <input
              type="password"
              name="password"
              className="form-control fs-15px"
              placeholder="Enter your password"
              value={resetInfo.password}
              onChange={onChangeHandler}
              required
            />
          </div>

          <div className="form-group mt-1">
            <div className="d-flex">
              <label>Confirm Password</label>
            </div>
            <input
              type="password"
              name="password_confirmation"
              className="form-control fs-15px"
              placeholder="Confirm your password"
              onChange={onChangeHandler}
              value={resetInfo.password_confirmation}
              required
            />
          </div>

          {/* <div className="form-group">
            <div className="d-flex">
              <label>Verification Code</label>
            </div>
            <input
              type="text"
              name="verification_code"
              className="form-control fs-15px"
              placeholder="Varification Code"
              onChange={onChangeHandler}
              value={resetInfo.verification_code}
              required
            />
          </div> */}
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block custom-btn mb-3 mt-3"
            disabled={isDisable}
          >
            Reset
          </button>
        </form>
      </div>
    </div>
  );
};

export default Reset;
