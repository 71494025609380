import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { mainContextApi } from "../App";

const PrivateRouting = ({ component: Component, ...rest }) => {
  const state = useContext(mainContextApi);
  const userContext = useContext(mainContextApi);
  const token = localStorage.getItem("token");

  if (!token) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export default PrivateRouting;
