/* eslint-disable */
import { useLayoutEffect, useEffect, useState, useContext } from "react";
import { mainContextApi } from "../../App";
import { Link, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import Loader from "../Loader/Loader";
import ReSearchBar from "../ReSearch/ReSearchBar";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoundTripGalileo from "../SearchResultHelper/Gs+RoundTrip/RoundTripGalileo";
import OneWayGalileo from "../SearchResultHelper/Gs+OneWay/OneWayGalileo";
import OneWaySabre from "../SearchResultHelper/Sabre+OneWay/OneWaySabre";
import RoundTripSabre from "../SearchResultHelper/Sabre+RoundTrip/RoundTripSabre";
import { cheapestSort, fastestSort } from "../../utils/indexHelperSabre";
import InputRange from "react-input-range";
import "./styles.scss";
import { useQuery } from "@tanstack/react-query";
import FlightCard from "./FlightCard";
import { Col, Row } from "react-bootstrap";
import LoaderImg from "../../Components/Visa/Loader.gif";
import SearchSidebar from "./SearchSidebar";
import FlightCardSkeleton from "../Skeleton/FlightCardSkeleton";

const cookies = new Cookies();

if (window.width < 900) {
  minWidth = 200;
}

const SearchResult = () => {
  //  * Contexts
  const context = useContext(mainContextApi);
  const { pathname } = useLocation();

  // * States
  const [resetAirlines, setresetAirlines] = useState(true);
  const [valslider, setValslider] = useState({ min: 0, max: 100 });
  const [maxPrice, setmaxPrice] = useState(0);
  const [minPrice, setminPrice] = useState(0);
  const [minPriceFast, setminPriceFast] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [legDescription, setLegDescription] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [date, setDate] = useState("");
  let [refinedTickets, setRefinedTickets] = useState([]);

  const [nonStopFlight, setNonStopFlight] = useState(false);
  const [oneStopFlight, setoneStopFlight] = useState(false);
  const [oneplusStopFlight, setoneplusStopFlight] = useState(false);

  const [takeOff1, setTakeOff1] = useState(false);
  const [takeOff2, setTakeOff2] = useState(false);
  const [tabType, settabType] = useState("");
  const [genToken, setGenToken] = useState(false);

  const [filteredAirlines, setFilteredAirlines] = useState([]);

  const [airlinesCheckController, setAirlinesCheckController] = useState(
    new Map()
  );

  const airlinesMap = new Map();

  let numberOfNonStopFlights = 0;
  let numberOfOneStopFlights = 0;
  let numberOfOnePlusStopFlights = 0;

  /**
   * Reset Checkbox
   * @param {boolean} type
   */
  const ResetCheckBox = (type) => {
    if (type) {
      [...airlinesCheckController.keys()].forEach((key) => {
        setAirlinesCheckController(
          new Map(airlinesCheckController.set(key, true))
        );
      });
    } else {
      [...airlinesCheckController.keys()].forEach((key) => {
        setAirlinesCheckController(
          new Map(airlinesCheckController.set(key, false))
        );
      });
    }
  };

  /**
   * Handle Checkbox
   * @param {Event} e
   */
  const HandleCheckBox = (e) => {
    setAirlinesCheckController(
      new Map(airlinesCheckController.set(e.target.id, e.target.checked))
    );
  };

  /**
   * Date Formatter
   * @param {number} year
   * @param {number} month
   * @param {number} day
   * @param {number} hour
   * @param {number} min
   * @param {number} sec
   * @returns string // 2022-01-01T00:00:00
   */
  const DateFormatter = (year, month, day, hour, min, sec) => {
    month = parseInt(month) + 1 + "";
    if (month.length === 1) month = "0" + month;
    if (day.length === 1) day = "0" + day;
    if (hour.length === 1) hour = "0" + hour;
    if (min.length === 1) min = "0" + min;
    if (sec.length === 1) sec = "0" + sec;

    // return year + '-' + month + '-' + day + 'T' + hour + ':' + min + ':' + sec
    return year + "-" + month + "-" + day + "T00:00:00";
  };

  /**
   * Passenger Quantity Handler
   * @param {any[]} passengerArray
   * @returns any[]
   */
  const passengerQuantityHandler = (passengerArray) => {
    let arr = [];

    let tpa_extention = {
      VoluntaryChanges: {
        Match: "Info",
      },
    };

    if (passengerArray[0] != 0) {
      arr.push({
        Code: "ADT",
        Quantity: parseInt(passengerArray[0]),
        TPA_Extensions: tpa_extention,
      });
    }

    if (passengerArray[1] != 0) {
      const ages = localStorage.getItem("owc");
      const ageMap = JSON.parse(ages);

      const group = {};

      ageMap.forEach((e) => {
        const o = (group[e.age] = group[e.age] || {
          ...e,
          quantity: 0,
        });
        o.quantity += e.quantity;
      });

      const res = Object.values(group);

      localStorage.setItem("owc", JSON.stringify(res));

      res.map((e) => {
        if (parseInt(e.age) < 10) {
          arr.push({
            Code: `C0${e.age}`,
            Quantity: e.quantity,
            TPA_Extensions: tpa_extention,
          });
        } else {
          arr.push({
            Code: `C${e.age}`,
            Quantity: e.quantity,
            TPA_Extensions: tpa_extention,
          });
        }
      });
    }

    if (passengerArray[2] != 0) {
      arr.push({
        Code: "INF",
        Quantity: parseInt(passengerArray[2]),
        TPA_Extensions: tpa_extention,
      });
    }

    return arr;
  };

  const bodyHandler = () => {
    let urlOfCurrentPage = window.location.href;
    urlOfCurrentPage = urlOfCurrentPage.replace("#!", "");
    let startIndex = null;

    for (let i = 0; i < urlOfCurrentPage.length; ++i) {
      if (
        urlOfCurrentPage[i - 1] === "/" &&
        urlOfCurrentPage[i - 2] === "s" &&
        urlOfCurrentPage[i - 3] === "t"
      ) {
        startIndex = i;
        break;
      }
    }

    let refactoredData = "";

    for (let i = startIndex; i < urlOfCurrentPage.length; ++i) {
      refactoredData += urlOfCurrentPage[i];
    }

    const splittedArray = refactoredData.split("--");
    if (splittedArray.length === 4) setIsRoundTrip(false);
    else setIsRoundTrip(true);

    const ExistedToken = cookies.get("token");

    if (splittedArray.length > 4) {
      const fromToArray = splittedArray[0].split("-");
      const departArray = splittedArray[1].split("-");
      const departTimeArray = splittedArray[2].split("-");
      const returnArray = splittedArray[3].split("-");
      const returnTimeArray = splittedArray[4].split("-");
      const passengerArray = splittedArray[5].split("-");

      const from = fromToArray[0];
      setFrom(from);
      const to = fromToArray[1];
      setTo(to);

      const dyear = departArray[0];
      const dmonth = parseInt(departArray[1] - 1);
      const dday = departArray[2];

      const dhour = departTimeArray[0];
      const dmin = departTimeArray[1];
      const dsec = departTimeArray[2];

      const ryear = returnArray[0];
      const rmonth = parseInt(returnArray[1] - 1);
      const rday = returnArray[2];
      const rhour = returnTimeArray[0];
      const rmin = returnTimeArray[1];
      const rsec = returnTimeArray[2];

      const schDes = [
        {
          DepartureDateTime: DateFormatter(
            dyear,
            dmonth,
            dday,
            dhour,
            dmin,
            dsec
          ),
          OriginLocation: {
            LocationCode: from,
            LocationType: "A",
          },
          DestinationLocation: {
            LocationCode: to,
            LocationType: "A",
          },
          RPH: "0",
        },
        {
          DepartureDateTime: DateFormatter(
            ryear,
            rmonth,
            rday,
            rhour,
            rmin,
            rsec
          ),
          OriginLocation: {
            LocationCode: to,
            LocationType: "A",
          },
          DestinationLocation: {
            LocationCode: from,
            LocationType: "A",
          },
          RPH: "1",
        },
      ];

      localStorage.setItem("schD", JSON.stringify(schDes));

      if ((ryear, rmonth, rday, rhour, rmin, rsec)) {
        setDate(DateFormatter(ryear, rmonth, rday, rhour, rmin, rsec));
      }

      const data = JSON.stringify({
        ExistedToken: ExistedToken,
        RequestLOG: true,
        RequireUTILS: false,
        RequestBody: {
          OriginDestinationInformation: schDes,
          PassengerTypeQuantity: passengerQuantityHandler(passengerArray),
          TicketClass: passengerArray[passengerArray.length - 1],
          DirectFlightsOnly: false,
          AvailableFlightsOnly: true,
        },
      });

      return data;
    }
    // one way
    else {
      const fromToArray = splittedArray[0].split("-");
      const departReturnArray = splittedArray[1].split("-");
      const departTimeArray = splittedArray[2].split("-");
      const passengerArray = splittedArray[3].split("-");
      //Data extraction
      const from = fromToArray[0];
      setFrom(from);
      const to = fromToArray[1];
      setTo(to);
      const dyear = departReturnArray[0];
      const dmonth = parseInt(departReturnArray[1] - 1);
      const dday = departReturnArray[2];

      const dhour = departTimeArray[0];
      const dmin = departTimeArray[1];
      const dsec = departTimeArray[2];
      const schDesR = [
        {
          DepartureDateTime: DateFormatter(
            dyear,
            dmonth,
            dday,
            dhour,
            dmin,
            dsec
          ),
          OriginLocation: {
            LocationCode: from,
            LocationType: "A",
          },
          DestinationLocation: {
            LocationCode: to,
            LocationType: "A",
          },
          RPH: "0",
        },
      ];

      localStorage.setItem("schD", JSON.stringify(schDesR));

      const data = JSON.stringify({
        ExistedToken: ExistedToken,
        RequestLOG: true,
        RequireUTILS: false,
        RequestBody: {
          OriginDestinationInformation: schDesR,
          PassengerTypeQuantity: passengerQuantityHandler(passengerArray),
          TicketClass: passengerArray[passengerArray.length - 1],
          DirectFlightsOnly: false,
          AvailableFlightsOnly: true,
        },
      });

      return data;
    }
  };

  const handleTokenSet = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_CERT_GEN_TOKEN_API}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${process.env.REACT_APP_CERT_GEN_TOKEN_X_API}`,
        },
      }
    );
    const result = await response.json();

    if (!(result.statusCode >= 200 && result.statusCode < 300)) return false;
    cookies.set("token", result.body, { path: "/" });
    return true;
  };

  // const { data: excludedAir } = useQuery({
  //   queryKey: ["excluded_airlines"],
  //   queryFn: () =>
  //     fetch(`${process.env.REACT_APP_DEV_API_URL}/exclude-airlines`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((res) => {
  //         localStorage.setItem("excluded_airlines", JSON.stringify(res.data));
  //       }),
  // });

  const {
    data: settings,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_DEV_API_URL}/application-settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          localStorage.setItem("commission_type", res.data.commission_type);

          // if (res.body.responseData.IsProduction + '' !== mode) {
          // localStorage.setItem("isprd", res.data?.isProduction);
          localStorage.setItem("ctn", "make");
          setGenToken(true);
          // } else {
          //   setGenToken(false);
          // }
        }),
  });

  const [flight, setFlight] = useState([]);
  const [loader, setLoader] = useState(true);
  const searchTicket = async () => {
    const _excluded_airlines = localStorage.getItem("excluded_airlines");

    const response = await fetch(
      `${process.env.REACT_APP_DEV_API_URL}/gds/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyHandler(),
      }
    );
    const result = await response.json();
    if (result.success === true) {
      setLoader(false);
      setFlight(result?.data?.sortedItineraries);
    }

    // localStorage.removeItem("cheapestSortJson");
    if (!(result.success == true)) {
      setIsError(true);
      localStorage.setItem("ctn", "make");
      return false;
    }

    // IF GOT RESPONSE, DO  SOMETHING

    const searchResult = result;

    setLegDescription(result?.data?.LegDescription);
    setValslider({
      min: result?.data.sortedItineraries[0].fare_details?.total_fare,
      max: result?.data.sortedItineraries[
        result?.data.sortedItineraries.length - 1
      ].fare_details?.total_fare,
    });
    setmaxPrice(
      result?.data.sortedItineraries[result?.data.sortedItineraries.length - 1]
        .fare_details?.total_fare
    );
    setminPrice(result?.data.sortedItineraries[0].fare_details?.total_fare);
    /**
     * Start modifying search result data
     * according site setting excluded airlines
     */
    // if (_excluded_airlines) {

    // const { sortedItineraries } = result.data;

    // searchResult = sortedItineraries?.filter((itinerary) => {
    //   if (itinerary.gds === "Galileo") {
    //     console.log(itinerary);
    //     const haveExcludeAirline = [];
    //     itinerary["air:AirPricingInfo"][0]["air:FlightOptionsList"][
    //       "air:FlightOption"
    //     ][0]["air:Option"][0]["air:BookingInfo"].forEach((bookingInfo) =>
    //       haveExcludeAirline.push(
    //         excluded_airlines.includes(bookingInfo.SegmentDetails.Carrier)
    //       )
    //     );
    //     console.log({
    //       haveExcludeAirline: haveExcludeAirline.includes(false),
    //       data: haveExcludeAirline,
    //     });
    //     return !haveExcludeAirline.includes(true);
    //   } else if (itinerary.GDS === "Sabre") {
    //     const haveExcludeAirline = [];
    //     itinerary.ScheduleDescription.forEach((schedule) =>
    //       haveExcludeAirline.push(
    //         excluded_airlines.includes(schedule.Carrier.OperatingCarrierCode)
    //       )
    //     );
    //     console.log({
    //       haveExcludeAirline: haveExcludeAirline.includes(false),
    //       data: haveExcludeAirline,
    //     });
    //     return !haveExcludeAirline.includes(true);
    //   } else {
    //     return true;
    //   }
    // });

    // if (result?.data.sortedItineraries.length) {
    //   let roundTrip = result?.data.LegDescription.length == 2 ? true : false;

    //   setValslider({
    //     min: result?.data.sortedItineraries[0].TotalPrice,
    //     max: result?.data.sortedItineraries[
    //       result?.data.sortedItineraries.length - 1
    //     ].TotalPrice,
    //   });
    //   setmaxPrice(
    //     result?.data.sortedItineraries[
    //       result?.data.sortedItineraries.length - 1
    //     ].TotalPrice
    //   );
    //   setminPrice(result?.data.sortedItineraries[0].TotalPrice);

    //   let cheapestSortJson = JSON.stringify(result?.data.sortedItineraries);
    //   localStorage.setItem("cheapestSortJson", cheapestSortJson);

    //   let cheapIndex = result?.data.sortedItineraries[0];

    //   if (cheapIndex.GDS == "Sabre") {
    //     let cheapest_duration = totalDisSab(
    //       cheapIndex.ScheduleDescription,
    //       roundTrip,
    //       "cheap",
    //       cheapIndex
    //     );
    //     localStorage.setItem("cheapest_duration", cheapest_duration);
    //     localStorage.setItem("cheapest_rate", cheapIndex.TotalPrice);
    //   } else {
    //     let cheapest_duration = totalDisGal(
    //       cheapIndex["air:AirPricingInfo"][0]["air:FlightOptionsList"][
    //         "air:FlightOption"
    //       ],
    //       roundTrip,
    //       "cheap",
    //       cheapIndex
    //     );
    //     localStorage.setItem("cheapest_duration", cheapest_duration);
    //     localStorage.setItem("cheapest_rate", cheapIndex.TotalPrice);
    //   }

    //   let fastIndex = fastestSort(
    //     result?.data.sortedItineraries,
    //     to,
    //     roundTrip
    //   );
    //   fastIndex = fastIndex[0];

    //   setminPriceFast(fastIndex.TotalPrice);

    //   if (fastIndex.GDS == "sabre") {
    //     let fastest_duration = totalDisSab(
    //       fastIndex.ScheduleDescription,
    //       roundTrip,
    //       "first",
    //       fastIndex
    //     );
    //     localStorage.setItem("fastest_duration", fastest_duration);
    //     localStorage.setItem("fastest_rate", fastIndex.TotalPrice);
    //   } else {
    //     let fastest_duration = totalDisGal(
    //       fastIndex["air:AirPricingInfo"][0]["air:FlightOptionsList"][
    //         "air:FlightOption"
    //       ],
    //       roundTrip,
    //       "first",
    //       fastIndex
    //     );
    //     localStorage.setItem("fastest_duration", fastest_duration);
    //     localStorage.setItem("fastest_rate", fastIndex.TotalPrice);
    //   }

    //   let itenery = cheapestSort(result?.data.sortedItineraries, to, roundTrip);
    //   setTickets(itenery);
    // }

    // setLegDescription(searchResult.body.LegDescription);
    // searchResult.body.sortedItineraries.map((value) => {
    //   if (value.GDS === "Sabre") {
    //     setAirlinesCheckController(
    //       new Map(
    //         airlinesCheckController.set(
    //           value.ScheduleDescription[0].Carrier.OperatingCarrierCode,
    //           true
    //         )
    //       )
    //     );
    //   }

    //   if (value.GDS === "Galileo") {
    //     setAirlinesCheckController(
    //       new Map(
    //         airlinesCheckController.set(
    //           value["air:AirPricingInfo"][0].PlatingCarrier,
    //           true
    //         )
    //       )
    //     );
    //   }
    //   return true;
    // });

    // setIsLoaded(true);
    // // }

    // return true;
  };

  const fetchData = async () => {
    if (
      localStorage.getItem("token") == undefined ||
      Date.now() / 1000 > localStorage.getItem("token").expires ||
      localStorage.getItem("ctn") === "make"
    ) {
      // Un-authorized block
      // Local Storage
      localStorage.removeItem("ctn");
      localStorage.removeItem("cheapest_duration");
      localStorage.removeItem("fastest_duration");
      // Set Token
      const tokenHandleResponse = await handleTokenSet();
    } else {
      // Un-authorized block
      setGenToken(false);

      localStorage.removeItem("cheapest_duration");
      localStorage.removeItem("fastest_duration");
    }

    const responseSearch = await searchTicket();

    // responseSearch
    //   ? toast.success("Search done")
    //   : toast.error("Something went wrong!");
  };

  const DateConverterSabre = (date) => {
    let hour = date[0] + date[1];
    let min = date[3] + date[4];
    hour = hour * 60;
    min = min * 1;

    return hour + min;
  };

  const GalNonStopFlight = (value, isRoundTrip) => {
    if (isRoundTrip) {
      let totalFlight =
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][0]["air:Option"][0]["air:BookingInfo"].length +
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][1]["air:Option"][0]["air:BookingInfo"].length;

      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        totalFlight === 2
        ? value
        : null;
    } else {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][0]["air:Option"][0]["air:BookingInfo"].length === 1
        ? value
        : null;
    }
  };

  const GalOneStopFlight = (value, isRoundTrip) => {
    if (isRoundTrip) {
      let totalFlight =
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][0]["air:Option"][0]["air:BookingInfo"].length >
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][1]["air:Option"][0]["air:BookingInfo"].length
          ? value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
              "air:FlightOption"
            ][0]["air:Option"][0]["air:BookingInfo"].length
          : value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
              "air:FlightOption"
            ][1]["air:Option"][0]["air:BookingInfo"].length;

      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        totalFlight === 2
        ? value
        : null;
    } else {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][0]["air:Option"][0]["air:BookingInfo"].length === 2
        ? value
        : null;
    }
  };

  const GalOnePlusStopFlight = (value, isRoundTrip) => {
    if (isRoundTrip) {
      let totalFlight =
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][0]["air:Option"][0]["air:BookingInfo"].length >
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][1]["air:Option"][0]["air:BookingInfo"].length
          ? value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
              "air:FlightOption"
            ][0]["air:Option"][0]["air:BookingInfo"].length
          : value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
              "air:FlightOption"
            ][1]["air:Option"][0]["air:BookingInfo"].length;

      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        totalFlight > 2
        ? value
        : null;
    } else {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
          "air:FlightOption"
        ][0]["air:Option"][0]["air:BookingInfo"].length > 2
        ? value
        : null;
    }
  };

  const GalTakeOffOne = (depTime, value) => {
    return value.TotalPrice >= valslider.min &&
      value.TotalPrice <= valslider.max &&
      DateConverterSabre(depTime) >= 360 &&
      DateConverterSabre(depTime) <= 720
      ? value
      : null;
  };

  const GalTakeOftwo = (depTime, value) => {
    return value.TotalPrice >= valslider.min &&
      value.TotalPrice <= valslider.max &&
      (DateConverterSabre(depTime) <= 360 || DateConverterSabre(depTime) >= 720)
      ? value
      : null;
  };

  const SabNonStopFlight = (value, isRoundTrip) => {
    if (isRoundTrip) {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value.ScheduleDescription.length === 2
        ? value
        : null;
    } else {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value.ScheduleDescription.length === 1
        ? value
        : null;
    }
  };

  const SabOneStopFlight = (value, isRoundTrip) => {
    if (isRoundTrip) {
      if (value.ScheduleDescription.length > 2) {
        let length = 0;
        let startTrip = 0;
        let returnTrip = -1;
        let startTripStart = true;
        let returnTripStart = false;

        value.ScheduleDescription.map((d, k) => {
          if (k != value.ScheduleDescription.length - 1) {
            if (d.Arrival.Airport === to) {
              returnTripStart = true;
              startTripStart = false;
            }

            if (startTripStart) {
              startTrip++;
            } else {
              returnTrip++;
            }
          }
        });

        length = startTrip > returnTrip ? startTrip : returnTrip;

        return value.TotalPrice >= valslider.min &&
          value.TotalPrice <= valslider.max &&
          length === 1
          ? value
          : null;
      }
    } else {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value.ScheduleDescription.length === 2
        ? value
        : null;
    }
  };

  const SabOnePlusStopFlight = (value, isRoundTrip) => {
    if (isRoundTrip) {
      if (value.ScheduleDescription.length > 2) {
        let length = 0;
        let startTrip = 0;
        let returnTrip = -1;
        let startTripStart = true;
        let returnTripStart = false;

        value.ScheduleDescription.map((d, k) => {
          if (k != value.ScheduleDescription.length - 1) {
            if (d.Arrival.Airport === to) {
              returnTripStart = true;
              startTripStart = false;
            }

            if (startTripStart) {
              startTrip++;
            } else {
              returnTrip++;
            }
          }
        });

        length = startTrip > returnTrip ? startTrip : returnTrip;

        return value.TotalPrice >= valslider.min &&
          value.TotalPrice <= valslider.max &&
          length > 1
          ? value
          : null;
      }
    } else {
      return value.TotalPrice >= valslider.min &&
        value.TotalPrice <= valslider.max &&
        value.ScheduleDescription.length > 2
        ? value
        : null;
    }
  };

  const SabTakeOffOne = (depTime, value) => {
    return value.TotalPrice >= valslider.min &&
      value.TotalPrice <= valslider.max &&
      DateConverterSabre(depTime) >= 360 &&
      DateConverterSabre(depTime) <= 720
      ? value
      : null;
  };

  const SabTakeOftwo = (depTime, value) => {
    return value.TotalPrice >= valslider.min &&
      value.TotalPrice <= valslider.max &&
      (DateConverterSabre(depTime) <= 360 || DateConverterSabre(depTime) >= 720)
      ? value
      : null;
  };

  const totalDisSab = (routes, isRoundTrip) => {
    try {
      if (isRoundTrip) {
        let FlyTime = "";
        let LayoverTime = "";

        let totalMin;
        let L_totalMin;
        let time_one = 0;
        routes.forEach((stop, index) => {
          FlyTime = stop.FlyTime.split("h");
          let hr = FlyTime[0];
          let min = FlyTime[1];

          totalMin =
            parseInt(hr) * 60 + parseInt(min)
              ? parseInt(hr) * 60 + parseInt(min)
              : 0;
          LayoverTime = stop.LayoverTime.split("h");
          let L_hr = LayoverTime[0];
          let L_min = LayoverTime[1];

          L_totalMin =
            parseInt(L_hr) * 60 + parseInt(L_min)
              ? parseInt(L_hr) * 60 + parseInt(L_min)
              : 0;
          time_one += totalMin + L_totalMin;
        });

        time_one = time_one * 60;

        return secondsToDhms(time_one);
      } else {
        let FlyTime = "";
        let LayoverTime = "";

        let totalMin;
        let L_totalMin;
        let time_one = 0;
        routes.forEach((stop, index) => {
          FlyTime = stop.FlyTime.split("h");
          let hr = FlyTime[0];
          let min = FlyTime[1];

          totalMin =
            parseInt(hr) * 60 + parseInt(min)
              ? parseInt(hr) * 60 + parseInt(min)
              : 0;
          LayoverTime = stop.LayoverTime.split("h");
          let L_hr = LayoverTime[0];
          let L_min = LayoverTime[1];

          L_totalMin =
            parseInt(L_hr) * 60 + parseInt(L_min)
              ? parseInt(L_hr) * 60 + parseInt(L_min)
              : 0;
          time_one += totalMin + L_totalMin;
        });

        time_one = time_one * 60;

        return secondsToDhms(time_one);
      }
    } catch (error) {
      return "---";
    }
  };

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  const totalDisGal = (routes, isRoundTrip) => {
    try {
      if (isRoundTrip) {
        let galTime = routes[0]["air:Option"][0]["TravelTime"];

        let day = galTime.split("DT")[0];
        day = day.replace("P", "");

        galTime = galTime.split("DT")[1];
        let time = galTime.split("H")[1];
        let hr = galTime.split("H")[0];
        let min = time.split("M")[0];

        let galTime_ = routes[1]["air:Option"][0]["TravelTime"];

        let day_ = galTime_.split("DT")[0];
        day_ = day_.replace("P", "");

        galTime_ = galTime_.split("DT")[1];
        let time_ = galTime_.split("H")[1];
        let hr_ = galTime_.split("H")[0];
        let min_ = time_.split("M")[0];

        let time_one =
          (parseInt(hr * 60) +
            parseInt(min) +
            parseInt(hr_ * 60) +
            parseInt(min_) +
            parseInt(day * 24 * 60) +
            parseInt(day_ * 24 * 60)) *
          60;
        return secondsToDhms(time_one);
      } else {
        let galTime = routes[0]["air:Option"][0]["TravelTime"];

        let day = galTime.split("DT")[0];
        day = day.replace("P", "");

        galTime = galTime.split("DT")[1];
        let time = galTime.split("H")[1];
        let hr = galTime.split("H")[0];
        let min = time.split("M")[0];
        return parseInt(day)
          ? day + " Day " + hr + " hrs " + min + " min"
          : hr + " hrs " + min + " min";
      }
    } catch (error) {
      return "---";
    }
  };

  const planeCheck = (data) => {
    let code = data[0].Carrier.OperatingCarrierCode;
    let haveMultiple = false;

    for (let i = 0; i < data.length; i++) {
      if (code !== data[i].Carrier.OperatingCarrierCode) {
        haveMultiple = true;
      }
    }

    if (!haveMultiple) {
      return (
        <img
          className="img-fluid"
          src={`https://skytripb2cmedia.s3.ap-southeast-1.amazonaws.com/airlineLogo/${code}+.png`}
          onError={(eve) => {
            eve.target.src = `https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${code}.png`;
          }}
          alt="Brand Logo"
        />
      );
    } else {
      return <span>Multiple AirLines</span>;
    }
  };

  const planeCheckForGal = (data) => {
    let code = data[0]["SegmentDetails"].Carrier;
    let haveMultiple = false;

    for (let i = 0; i < data.length; i++) {
      if (code !== data[i]["SegmentDetails"].Carrier) {
        haveMultiple = true;
      }
    }

    if (!haveMultiple) {
      return (
        <img
          className="img-fluid"
          src={`https://skytripb2cmedia.s3.ap-southeast-1.amazonaws.com/airlineLogo/${code}+.png`}
          onError={(eve) => {
            eve.target.src = `https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${code}.png`;
          }}
          alt="Brand Logo"
        />
      );
    } else {
      return <span>Multiple AirLines</span>;
    }
  };

  const customFilter = async (type, to = "", e, data) => {
    settabType(type);
    setNonStopFlight(false);
    setoneStopFlight(false);
    setoneplusStopFlight(false);
    setTakeOff1(false);
    setTakeOff2(false);
    setrefresh(true);

    if (type == "") {
      let x = cheapestSort(data, to, isRoundTrip);
      setTickets([]);
      setTickets(x);
    } else {
      let x = fastestSort(data, to, isRoundTrip);
    }

    const collection = document.getElementsByClassName("nav_type");

    for (let index = 0; index < collection.length; index++) {
      collection[index].classList.remove("active");
      collection[index].style.background = null;
    }

    if (e.target.nodeName === "A") {
      e.target.classList.add("active");
    } else {
      if (e.target.parentElement.classList.value == "price-filter") {
        e.target.parentElement.parentElement.classList.add("active");
      } else {
        e.target.parentElement.classList.add("active");
      }
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    localStorage.setItem("generate", "make");
    localStorage.setItem("_ms", "x*1_132");
    localStorage.setItem("cpn", false);
    localStorage.removeItem("ticket_details");
    localStorage.removeItem("pnr");
    localStorage.removeItem("temp_PROD_mode");
    localStorage.removeItem("cabin");
    // localStorage.removeItem('schD')
    localStorage.removeItem("ml");

    if (context.Redirected) {
      toast.error("This Flight is currently unavailable", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }

    context.red(false);
  }, [context.Redirected]);

  useEffect(() => {
    setRefinedTickets(
      tickets.filter((value, k) => {
        if (refresh) {
          let price;

          if (value.GDS === "Sabre") {
            if (
              (nonStopFlight || oneStopFlight || oneplusStopFlight) &&
              (takeOff1 || takeOff2)
            ) {
              if (takeOff1) {
                let takeOffValue = SabTakeOffOne(
                  value.ScheduleDescription[0].Departure.Time,
                  value
                );

                let non;
                let oneStop;
                let onePlusStop;

                if (nonStopFlight) {
                  non = SabNonStopFlight(value, isRoundTrip);
                }

                if (oneStopFlight) {
                  oneStop = SabOneStopFlight(value, isRoundTrip);
                }

                if (oneplusStopFlight) {
                  onePlusStop = SabOnePlusStopFlight(value, isRoundTrip);
                }

                if (takeOffValue && (non || oneStop || onePlusStop)) {
                  price =
                    value.TotalPrice >= valslider.min &&
                    value.TotalPrice <= valslider.max
                      ? value
                      : null;
                }
              }

              if (takeOff2) {
                let takeOffValue = SabTakeOftwo(
                  value.ScheduleDescription[0].Departure.Time,
                  value
                );

                let non;
                let oneStop;
                let onePlusStop;

                if (nonStopFlight) {
                  non = SabNonStopFlight(value, isRoundTrip);
                }

                if (oneStopFlight) {
                  oneStop = SabOneStopFlight(value, isRoundTrip);
                }

                if (oneplusStopFlight) {
                  onePlusStop = SabOnePlusStopFlight(value, isRoundTrip);
                }

                if (takeOffValue && (non || oneStop || onePlusStop)) {
                  price =
                    value.TotalPrice >= valslider.min &&
                    value.TotalPrice <= valslider.max
                      ? value
                      : null;
                }
              }
            } else {
              if (nonStopFlight) {
                price =
                  value.TotalPrice >= valslider.min &&
                  value.TotalPrice <= valslider.max &&
                  value.ScheduleDescription.length === 1
                    ? value
                    : null;
              } else if (oneStopFlight) {
                price = SabOneStopFlight(value, isRoundTrip);
              } else if (oneplusStopFlight) {
                price = SabOnePlusStopFlight(value, isRoundTrip);
              }

              if (
                !nonStopFlight &&
                !oneStopFlight &&
                !oneplusStopFlight &&
                !takeOff1 &&
                !takeOff2
              ) {
                price =
                  value.TotalPrice >= valslider.min &&
                  value.TotalPrice <= valslider.max
                    ? value
                    : null;
              }

              if (nonStopFlight && isRoundTrip) {
                price =
                  value.TotalPrice >= valslider.min &&
                  value.TotalPrice <= valslider.max &&
                  value.ScheduleDescription.length === 2
                    ? value
                    : null;
              }

              if (takeOff1) {
                price =
                  value.TotalPrice >= valslider.min &&
                  value.TotalPrice <= valslider.max &&
                  DateConverterSabre(
                    value.ScheduleDescription[0].Departure.Time
                  ) >= 360 &&
                  DateConverterSabre(
                    value.ScheduleDescription[0].Departure.Time
                  ) <= 720
                    ? value
                    : null;
              }

              if (takeOff2) {
                price =
                  value.TotalPrice >= valslider.min &&
                  value.TotalPrice <= valslider.max &&
                  DateConverterSabre(
                    value.ScheduleDescription[0].Departure.Time
                  ) >= 720 &&
                  DateConverterSabre(
                    value.ScheduleDescription[0].Departure.Time
                  ) <= 1080
                    ? value
                    : null;
              }
            }
            return price;
          }

          if (value.GDS === "Galileo") {
            let depTime =
              value["air:AirPricingInfo"][0]["air:FlightOptionsList"][
                "air:FlightOption"
              ][0]["air:Option"][0][
                "air:BookingInfo"
              ][0].SegmentDetails.DepartureTime.split("T")[1];

            depTime = depTime.split(".")[0];

            if (
              (oneStopFlight || nonStopFlight || oneplusStopFlight) &&
              (takeOff1 || takeOff2)
            ) {
              if (takeOff1) {
                let takeOffValue = GalTakeOffOne(depTime, value);
                let non;
                let oneStop;
                let onePlusStop;

                if (nonStopFlight) {
                  non = GalNonStopFlight(value, isRoundTrip);
                }

                if (oneStopFlight) {
                  oneStop = GalOneStopFlight(value, isRoundTrip);
                }

                if (oneplusStopFlight) {
                  onePlusStop = GalOnePlusStopFlight(value, isRoundTrip);
                }

                if (takeOffValue && (non || oneStop || onePlusStop)) {
                  price =
                    value.TotalPrice >= valslider.min &&
                    value.TotalPrice <= valslider.max
                      ? value
                      : null;
                }
              }

              if (takeOff2) {
                let takeOffValue = GalTakeOftwo(depTime, value);
                let non;
                let oneStop;
                let onePlusStop;

                if (nonStopFlight) {
                  non = GalNonStopFlight(value, isRoundTrip);
                }

                if (oneStopFlight) {
                  oneStop = GalOneStopFlight(value, isRoundTrip);
                }

                if (oneplusStopFlight) {
                  onePlusStop = GalOnePlusStopFlight(value, isRoundTrip);
                }

                if (takeOffValue && (non || oneStop || onePlusStop)) {
                  price =
                    value.TotalPrice >= valslider.min &&
                    value.TotalPrice <= valslider.max
                      ? value
                      : null;
                }
              }
            } else {
              if (nonStopFlight) {
                price = GalNonStopFlight(value, isRoundTrip);
              } else if (oneStopFlight) {
                price = GalOneStopFlight(value, isRoundTrip);
              } else if (oneplusStopFlight) {
                price = GalOnePlusStopFlight(value, isRoundTrip);
              }

              if (
                !nonStopFlight &&
                !oneStopFlight &&
                !oneplusStopFlight &&
                !takeOff1 &&
                !takeOff2
              ) {
                price =
                  value.TotalPrice >= valslider.min &&
                  value.TotalPrice <= valslider.max
                    ? value
                    : null;
              }

              if (takeOff1) {
                price = GalTakeOffOne(depTime, value);
              }

              if (takeOff2) {
                price = GalTakeOftwo(depTime, value);
              }
            }

            return price;
          }
        }
      })
    );
  }, [
    tickets,
    refresh,
    nonStopFlight,
    oneStopFlight,
    oneplusStopFlight,
    takeOff1,
    takeOff2,
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const [priceFilterCheap, setPriceFilterCheap] = useState();
  const [priceFilterFast, setPriceFilterFast] = useState();
  const [fastest, setFastest] = useState(false);
  const [cheapest, setCheapest] = useState(false);
  const [earliar, setEarliar] = useState(false);
  const [earliarFilterData, setEarliarFilterData] = useState();

  const sortFlights = (order) => {
    const sortedFlights = [...filteredAirlines].sort((a, b) => {
      if (order === "lowToHigh") {
        return a.fare_details.total_fare - b.fare_details.total_fare;
      } else {
        return b.fare_details.total_fare - a.fare_details.total_fare;
      }
    });
    setPriceFilterCheap(sortedFlights);
  };

  // sort by duration just one

  useEffect(() => {
    setCheapest(true);
    setFastest(false);
    const minFare = Math.min(
      ...filteredAirlines.map((flight) => flight.fare_details.total_fare)
    );
    const cheapestFlights = filteredAirlines.find(
      (flight) => flight.fare_details.total_fare === minFare
    );
    setPriceFilterCheap(cheapestFlights);
  }, [filteredAirlines]);

  useEffect(() => {
    setFastest(true);
    setCheapest(false);
    const minDuration = Math.min(
      ...filteredAirlines.map((flight) => parseDuration(flight.flight_duration))
    );
    const fastestFlights = filteredAirlines.find(
      (flight) => parseDuration(flight.flight_duration) === minDuration
    );
    setPriceFilterFast(fastestFlights);
  }, [filteredAirlines]);

  const cheapestFilter = () => {
    setCheapest(true);
    setFastest(false);
    setEarliar(false);
    const minFare = Math.min(
      ...filteredAirlines.map((flight) => flight.fare_details.total_fare)
    );
    const cheapestFlights = filteredAirlines.find(
      (flight) => flight.fare_details.total_fare === minFare
    );
    setPriceFilterCheap(cheapestFlights);
  };

  const fastestFilter = () => {
    setFastest(true);
    setCheapest(false);
    setEarliar(false);
    const minDuration = Math.min(
      ...filteredAirlines.map((flight) => parseDuration(flight.flight_duration))
    );
    const fastestFlights = filteredAirlines.find(
      (flight) => parseDuration(flight.flight_duration) === minDuration
    );
    setPriceFilterFast(fastestFlights);
  };

  const timeConv = (time) => {
    const formattedTime = time
      .split("+")[0]
      .split(":")
      .map((part) => part.padStart(2, "0"))
      .join(":");
    console.log("formattedTime", formattedTime);
    return formattedTime;
  };
  const earliarFilter = () => {
    setEarliar(true);
    setCheapest(false);
    setFastest(false);
    const earliestDeparture = Math.min(
      ...filteredAirlines?.map((flight) =>
        timeConv(flight?.schedules[0].departure_time)
      )
    );
    const earliestFlights = filteredAirlines?.find(
      (flight) =>
        timeConv(flight?.schedules[0].departure_time) === earliestDeparture
    );
    setEarliarFilterData(earliestFlights);
  };

  console.log("earliarFilterData", earliarFilterData);

  const parseDuration = (duration) => {
    const parts = duration.split(" ");
    let totalMinutes = 0;

    parts.forEach((part) => {
      if (part.includes("hr")) {
        totalMinutes += parseInt(part.replace("hr", "")) * 60;
      }
      if (part.includes("min")) {
        totalMinutes += parseInt(part.replace("min", ""));
      }
    });

    return totalMinutes;
  };

  return (
    <div>
      {<ReSearchBar />}

      {isError ? (
        <>
          <div
            className="no-result-found"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60vh",
              flexDirection: "column",
              padding: 0,
              margin: 0,
            }}
          >
            <p>Sorry. No Flight is Available for this day.</p>
            <p>Please Search for different Date.</p>
            <p>Thank You</p>
            <Link className="btn btn-warning border-left" to="/">
              Research
            </Link>
          </div>
        </>
      ) : (
        <>
          {loader ? (
            // <div
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     minHeight: "80vh",

            //     overflow: "hidden",
            //   }}
            // >
            //   <img src={LoaderImg} alt="loader" />
            // </div>
            <>
              <FlightCardSkeleton />
            </>
          ) : (
            <>
              {flight?.length > 0 ? (
                <div className="container pt-4" style={{}}>
                  {" "}
                  <Row className="gap-3 gap-lg-0">
                    <Col lg={3}>
                      <SearchSidebar
                        setValslider={setValslider}
                        valslider={valslider}
                        maxPrice={maxPrice}
                        minPrice={minPrice}
                        flight={flight}
                        setFlight={setFlight}
                        filteredAirlines={filteredAirlines}
                        setFilteredAirlines={setFilteredAirlines}
                      />
                    </Col>

                    <Col lg={9}>
                      {/* <button onClick={filterCheapest}>Cheapest</button>
                  <button onClick={filterFastest}>Fastest</button> */}
                      <div className="w-full">
                        <Row>
                          <nav className="nav nav-justified  py-2 w-full ">
                            <a
                              style={{
                                borderRadius: "5px ",
                              }}
                              className={`nav-link  nav_type ${
                                cheapest && "active"
                              }`}
                              aria-current="page"
                              onClick={cheapestFilter}
                            >
                              <span className="fw-bold">Cheapest</span> <br />
                              <span>
                                {" "}
                                <span className="fs-17">
                                  BDT{" "}
                                  {priceFilterCheap?.fare_details.total_fare} |
                                </span>{" "}
                                {priceFilterCheap?.flight_duration}
                              </span>
                            </a>

                            <a
                              style={{
                                borderRadius: "5px ",
                              }}
                              className={`nav-link  nav_type ${
                                fastest && "active"
                              }`}
                              onClick={fastestFilter}
                            >
                              <span className="fw-bold">Fastest</span> <br />
                              <span>
                                <span className="fs-17">
                                  BDT {priceFilterFast?.fare_details.total_fare}{" "}
                                  |
                                </span>{" "}
                                {priceFilterFast?.flight_duration}
                              </span>
                            </a>
                          </nav>
                        </Row>
                        <FlightCard
                          legDescription={legDescription}
                          flight={flight}
                          setFlight={setFlight}
                          filteredAirlines={filteredAirlines}
                          setFilteredAirlines={setFilteredAirlines}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <>
                  <div
                    className="no-result-found"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "60vh",
                      flexDirection: "column",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <p>Sorry. No Flight is Available for this day.</p>
                    <p>Please Search for different Date.</p>
                    <p>Thank You</p>
                    <Link className="btn btn-warning border-left" to="/">
                      Research
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </>
        // <>
        //   {isLoaded && (
        //     <Sorting
        //       planeFound={refinedTickets}
        //       isRoundTrip={isRoundTrip}
        //       airlinesCheckController={airlinesCheckController}
        //     />
        //   )}
        //   {!isLoaded && <div style={{ marginTop: "50px" }}></div>}
        //   <div>
        //     <ToastContainer
        //       position="top-right"
        //       autoClose={3000}
        //       hideProgressBar={false}
        //       newestOnTop={false}
        //       closeOnClick={true}
        //       pauseOnFocusLoss={false}
        //       draggable={false}
        //       pauseOnHover={false}
        //     />
        //     <div className="py-2">
        //       <div className="container">
        //         <div className="row">
        //           <div className="col-md-3 d-none d-lg-block leftSidebar mb-3">
        //             <div className="mb-3">
        //               <div className="mb-3 d-flex justify-content-between">
        //                 <h4 className="fs-16 mb-0">Preferred Airlines</h4>
        //                 <a
        //                   href="#!"
        //                   className="reset fs-12 font-weight-500"
        //                   onClick={() => {
        //                     setrefresh(true);
        //                     ResetCheckBox(!resetAirlines);
        //                     setresetAirlines(!resetAirlines);
        //                   }}
        //                 >
        //                   {resetAirlines ? "Reset" : "Select All"}
        //                 </a>
        //               </div>
        //               {tickets.length === 0 ? (
        //                 <Loader />
        //               ) : (
        //                 tickets.map((ticket, index) => {
        //                   if (ticket.GDS === "Sabre") {
        //                     if (
        //                       ticket.ScheduleDescription.length === 1 &&
        //                       !isRoundTrip
        //                     ) {
        //                       numberOfNonStopFlights++;
        //                     }

        //                     if (
        //                       ticket.ScheduleDescription.length === 2 &&
        //                       isRoundTrip
        //                     ) {
        //                       numberOfNonStopFlights++;
        //                     }

        //                     if (
        //                       isRoundTrip &&
        //                       ticket.ScheduleDescription.length > 2
        //                     ) {
        //                       let length = 0;
        //                       let startTrip = 0;
        //                       let returnTrip = -1;
        //                       let startTripStart = true;
        //                       let returnTripStart = false;

        //                       ticket.ScheduleDescription.map((d, k) => {
        //                         if (
        //                           k !=
        //                           ticket.ScheduleDescription.length - 1
        //                         ) {
        //                           if (d.Arrival.Airport === to) {
        //                             returnTripStart = true;
        //                             startTripStart = false;
        //                           }

        //                           if (startTripStart) {
        //                             startTrip++;
        //                           } else {
        //                             returnTrip++;
        //                           }
        //                         }
        //                       });

        //                       length =
        //                         startTrip > returnTrip ? startTrip : returnTrip;

        //                       if (length === 1 && isRoundTrip) {
        //                         numberOfOneStopFlights++;
        //                       }

        //                       if (length > 1 && isRoundTrip) {
        //                         numberOfOnePlusStopFlights++;
        //                       }
        //                     }

        //                     if (
        //                       !isRoundTrip &&
        //                       ticket.ScheduleDescription.length === 2
        //                     ) {
        //                       numberOfOneStopFlights++;
        //                     }

        //                     if (
        //                       !isRoundTrip &&
        //                       ticket.ScheduleDescription.length > 2
        //                     ) {
        //                       numberOfOnePlusStopFlights++;
        //                     }

        //                     if (
        //                       !airlinesMap.has(
        //                         ticket.ScheduleDescription[0].Carrier
        //                           .OperatingCarrierCode
        //                       )
        //                     ) {
        //                       airlinesMap.set(
        //                         ticket.ScheduleDescription[0].Carrier
        //                           .OperatingCarrierCode,
        //                         true
        //                       );
        //                       return (
        //                         <div
        //                           className="custom-control custom-checkbox mb-2 fs-14"
        //                           key={index}
        //                         >
        //                           <div className="form-check">
        //                             <input
        //                               type="checkbox"
        //                               onChange={(e) => {
        //                                 setrefresh(true);
        //                                 HandleCheckBox(e);
        //                               }}
        //                               className="form-check-input"
        //                               checked={
        //                                 airlinesCheckController.get(
        //                                   ticket.ScheduleDescription[0].Carrier
        //                                     .OperatingCarrierCode
        //                                 ) || ""
        //                               }
        //                               id={
        //                                 ticket.ScheduleDescription[0].Carrier
        //                                   .OperatingCarrierCode
        //                               }
        //                             />
        //                             <label
        //                               className="form-check-label ms-2 d-flex align-items-center"
        //                               htmlFor={
        //                                 ticket.ScheduleDescription[0].Carrier
        //                                   .OperatingCarrierCode
        //                               }
        //                             >
        //                               <span className="checkbox-img d-flex align-items-center position-relative overflow-hidden me-2 d-flex align-items-center position-relative overflow-hidden me-2">
        //                                 <img
        //                                   src={
        //                                     "https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/" +
        //                                     ticket.ScheduleDescription[0]
        //                                       .Carrier.OperatingCarrierCode +
        //                                     ".png"
        //                                   }
        //                                   alt="tbbd"
        //                                 />
        //                               </span>
        //                               <span className="airlines-name">
        //                                 {
        //                                   ticket.ScheduleDescription[0].Carrier
        //                                     .OperatingCarrierCode
        //                                 }
        //                               </span>
        //                               <span className="text-black-50 fs-16 ms-auto">
        //                                 ৳ {ticket.TotalFare.totalPrice}
        //                               </span>
        //                             </label>
        //                           </div>
        //                         </div>
        //                       );
        //                     }
        //                   }
        //                   if (ticket.GDS === "Galileo") {
        //                     if (isRoundTrip) {
        //                       let totalFlight =
        //                         ticket["air:AirPricingInfo"][0][
        //                           "air:FlightOptionsList"
        //                         ]["air:FlightOption"][0]["air:Option"][0][
        //                           "air:BookingInfo"
        //                         ].length >
        //                         ticket["air:AirPricingInfo"][0][
        //                           "air:FlightOptionsList"
        //                         ]["air:FlightOption"][1]["air:Option"][0][
        //                           "air:BookingInfo"
        //                         ].length
        //                           ? ticket["air:AirPricingInfo"][0][
        //                               "air:FlightOptionsList"
        //                             ]["air:FlightOption"][0]["air:Option"][0][
        //                               "air:BookingInfo"
        //                             ].length
        //                           : ticket["air:AirPricingInfo"][0][
        //                               "air:FlightOptionsList"
        //                             ]["air:FlightOption"][1]["air:Option"][0][
        //                               "air:BookingInfo"
        //                             ].length;

        //                       if (totalFlight === 1) {
        //                         numberOfNonStopFlights++;
        //                       }

        //                       if (totalFlight === 2) {
        //                         numberOfOneStopFlights++;
        //                       }

        //                       if (totalFlight > 2) {
        //                         numberOfOnePlusStopFlights++;
        //                       }
        //                     } else {
        //                       if (
        //                         ticket["air:AirPricingInfo"][0][
        //                           "air:FlightOptionsList"
        //                         ]["air:FlightOption"][0]["air:Option"][0][
        //                           "air:BookingInfo"
        //                         ].length === 1
        //                       ) {
        //                         numberOfNonStopFlights++;
        //                       }

        //                       if (
        //                         ticket["air:AirPricingInfo"][0][
        //                           "air:FlightOptionsList"
        //                         ]["air:FlightOption"][0]["air:Option"][0][
        //                           "air:BookingInfo"
        //                         ].length === 2
        //                       ) {
        //                         numberOfOneStopFlights++;
        //                       }

        //                       if (
        //                         ticket["air:AirPricingInfo"][0][
        //                           "air:FlightOptionsList"
        //                         ]["air:FlightOption"][0]["air:Option"][0][
        //                           "air:BookingInfo"
        //                         ].length > 2
        //                       ) {
        //                         numberOfOnePlusStopFlights++;
        //                       }
        //                     }

        //                     if (
        //                       !airlinesMap.has(
        //                         ticket["air:AirPricingInfo"][0].PlatingCarrier
        //                       )
        //                     ) {
        //                       airlinesMap.set(
        //                         ticket["air:AirPricingInfo"][0].PlatingCarrier,
        //                         true
        //                       );
        //                       return (
        //                         <div
        //                           className="custom-control custom-checkbox mb-2 fs-14"
        //                           key={index}
        //                         >
        //                           <div className="form-check">
        //                             <input
        //                               type="checkbox"
        //                               onChange={(e) => {
        //                                 setrefresh(true);
        //                                 HandleCheckBox(e);
        //                               }}
        //                               className="form-check-input"
        //                               checked={
        //                                 airlinesCheckController.get(
        //                                   ticket["air:AirPricingInfo"][0]
        //                                     .PlatingCarrier
        //                                 ) || ""
        //                               }
        //                               id={
        //                                 ticket["air:AirPricingInfo"][0]
        //                                   .PlatingCarrier
        //                               }
        //                             />
        //                             <label
        //                               className="form-check-label ms-2 d-flex align-items-center"
        //                               htmlFor={
        //                                 ticket["air:AirPricingInfo"][0]
        //                                   .PlatingCarrier
        //                               }
        //                             >
        //                               <span className="checkbox-img d-flex align-items-center position-relative overflow-hidden me-2 d-flex align-items-center position-relative overflow-hidden me-2">
        //                                 {ticket["air:AirPricingInfo"][0]
        //                                   .PlatingCarrier ? (
        //                                   <img
        //                                     src={
        //                                       "https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/" +
        //                                       ticket["air:AirPricingInfo"][0]
        //                                         .PlatingCarrier +
        //                                       ".png"
        //                                     }
        //                                     alt="tbbd"
        //                                   />
        //                                 ) : (
        //                                   <>
        //                                     {planeCheckForGal(
        //                                       ticket["air:AirPricingInfo"][0][
        //                                         "air:FlightOptionsList"
        //                                       ]["air:FlightOption"][0][
        //                                         "air:Option"
        //                                       ][0]["air:BookingInfo"]
        //                                     )}
        //                                   </>
        //                                 )}
        //                               </span>
        //                               <span className="airlines-name">
        //                                 {ticket["air:AirPricingInfo"][0]
        //                                   .PlatingCarrier
        //                                   ? ticket["air:AirPricingInfo"][0]
        //                                       .PlatingCarrier
        //                                   : ticket["air:AirPricingInfo"][0][
        //                                       "air:FareInfoRef"
        //                                     ][0].SupplierCode}
        //                               </span>
        //                               <span className="text-black-50 fs-16 ms-auto">
        //                                 ৳ {ticket.TotalPrice}
        //                               </span>
        //                             </label>
        //                           </div>
        //                         </div>
        //                       );
        //                     }
        //                   }
        //                 })
        //               )}
        //             </div>
        //             <hr />

        //             <div className="mb-3">
        //               <div className="mb-3 d-flex justify-content-between align-items-end">
        //                 <h4 className="fs-16 mb-0">Price Range</h4>
        //               </div>

        //               <div className="row no-gutters">
        //                 <div className="col">
        //                   <div className="">
        //                     <span>
        //                       {" "}
        //                       {valslider.min} - {valslider.max}{" "}
        //                     </span>

        //                     {maxPrice > 0 && (
        //                       <InputRange
        //                         formatLabel={(value) => null}
        //                         draggableTrack={false}
        //                         allowSameValues={false}
        //                         maxValue={maxPrice}
        //                         minValue={minPrice}
        //                         value={valslider}
        //                         onChange={setValslider}
        //                         onChangeComplete={(args) => {
        //                           setrefresh(false);
        //                           setrefresh(true);
        //                         }}
        //                       />
        //                     )}
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //             <hr />
        //             <div className="mb-3">
        //               <div className="mb-3 d-flex justify-content-between align-items-end">
        //                 <h4 className="fs-16 mb-0">Stops Type</h4>
        //                 <a
        //                   href="#!"
        //                   className="reset fs-12 font-weight-500"
        //                   onClick={(e) => {
        //                     setNonStopFlight(e.target.checked);
        //                     setoneStopFlight(e.target.checked);
        //                     setoneplusStopFlight(e.target.checked);
        //                     setrefresh(true);
        //                   }}
        //                 >
        //                   Reset
        //                 </a>
        //               </div>

        //               <div className="row no-gutters">
        //                 <div className="form-check">
        //                   <input
        //                     className="form-check-input"
        //                     type="checkbox"
        //                     id="defaultCheck1"
        //                     onChange={(e) => {
        //                       setNonStopFlight(e.target.checked);
        //                       setoneStopFlight(false);
        //                       setoneplusStopFlight(false);
        //                       setrefresh(true);
        //                     }}
        //                     checked={nonStopFlight}
        //                   />
        //                   <label
        //                     className="form-check-label ms-2 d-flex justify-content-between"
        //                     htmlFor="stops1"
        //                   >
        //                     <span>
        //                       Non Stop
        //                       <span className="count text-muted fs-12 ms-1">
        //                         ({numberOfNonStopFlights})
        //                       </span>
        //                     </span>
        //                   </label>
        //                 </div>

        //                 <div className="form-check">
        //                   <input
        //                     className="form-check-input"
        //                     type="checkbox"
        //                     id="defaultCheck1"
        //                     onChange={(e) => {
        //                       setoneStopFlight(e.target.checked);
        //                       setNonStopFlight(false);
        //                       setoneplusStopFlight(false);
        //                       setrefresh(true);
        //                     }}
        //                     checked={oneStopFlight}
        //                   />
        //                   <label
        //                     className="form-check-label ms-2 d-flex justify-content-between"
        //                     htmlFor="stops1"
        //                   >
        //                     <span>
        //                       One Stop
        //                       <span className="count text-muted fs-12 ms-1">
        //                         ({numberOfOneStopFlights})
        //                       </span>
        //                     </span>
        //                   </label>
        //                 </div>

        //                 <div className="form-check">
        //                   <input
        //                     className="form-check-input"
        //                     type="checkbox"
        //                     id="defaultCheck1"
        //                     onChange={(e) => {
        //                       setoneplusStopFlight(e.target.checked);
        //                       setNonStopFlight(false);
        //                       setoneStopFlight(false);
        //                       setrefresh(true);
        //                     }}
        //                     checked={oneplusStopFlight}
        //                   />
        //                   <label
        //                     className="form-check-label ms-2 d-flex justify-content-between"
        //                     htmlFor="stops1"
        //                   >
        //                     <span>
        //                       Many Stop
        //                       <span className="count text-muted fs-12 ms-1">
        //                         ({numberOfOnePlusStopFlights})
        //                       </span>
        //                     </span>
        //                   </label>
        //                 </div>
        //               </div>
        //             </div>
        //             <hr />

        //             <div className="mb-3">
        //               <div className="mb-3 d-flex justify-content-between align-items-end">
        //                 <h4 className="fs-16 mb-0">Take off</h4>
        //                 <a
        //                   href="#!"
        //                   className="reset fs-12 font-weight-500"
        //                   onClick={() => {
        //                     setTakeOff1(false);
        //                     setTakeOff2(false);
        //                     setrefresh(true);
        //                   }}
        //                 >
        //                   Reset
        //                 </a>
        //               </div>

        //               <div className="row no-gutters">
        //                 <div className="form-check custom-checkbox fs-14">
        //                   <input
        //                     className="form-check-input"
        //                     type="checkbox"
        //                     id="takeOff1"
        //                     onChange={(e) => {
        //                       setTakeOff1(e.target.checked);
        //                       setrefresh(true);
        //                     }}
        //                     checked={takeOff1}
        //                   />
        //                   <label
        //                     className="form-check-label ms-2"
        //                     htmlFor="stops1"
        //                   >
        //                     <span className="font-weight-bold">6</span>
        //                     &nbsp;AM -
        //                     <span className="font-weight-bold">12</span>
        //                     &nbsp;PM
        //                   </label>
        //                 </div>

        //                 <div className="form-check custom-checkbox fs-14">
        //                   <input
        //                     className="form-check-input"
        //                     type="checkbox"
        //                     id="takeOff2"
        //                     onChange={(e) => {
        //                       setTakeOff2(e.target.checked);
        //                       setrefresh(true);
        //                     }}
        //                     checked={takeOff2}
        //                   />
        //                   <label
        //                     className="form-check-label ms-2"
        //                     htmlFor="stops1"
        //                   >
        //                     <span className="font-weight-bold">12</span>
        //                     &nbsp;PM -
        //                     <span className="font-weight-bold">6</span>
        //                     &nbsp;AM
        //                   </label>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>

        //           {/*  ↓ ↓ ↓ ----------  ↓ ↓ ↓ */}
        //           {/*  ↓ ↓ ↓ ----------  ↓ ↓ ↓ */}
        //           {/*  ↓ ↓ ↓ ----------  ↓ ↓ ↓ */}

        //           <div className="col-lg-9 mainContent">
        //             <nav className="nav nav-pills nav-justified">
        //               <a
        //                 className="nav-link active nav_type"
        //                 aria-current="page"
        //                 onClick={(e) => {
        //                   customFilter("", to, e, tickets);
        //                 }}
        //               >
        //                 <span className="fw-bold">Cheapest</span> <br />
        //                 {minPrice > 0 && (
        //                   <>
        //                     <span className="price-filter">
        //                       BDT &nbsp;
        //                       {localStorage.getItem("cheapest_rate")}
        //                       <span className="border-filter"> | </span>
        //                       <span className="duration_flight">
        //                         {localStorage.getItem("cheapest_duration")}
        //                       </span>
        //                     </span>
        //                   </>
        //                 )}
        //               </a>
        //               <a
        //                 className="nav-link nav_type"
        //                 onClick={(e) => customFilter("Fastest", to, e, tickets)}
        //                 style={{ margin: "0 3px", cursor: "pointer" }}
        //               >
        //                 <span className="fw-bold">Fastest</span> <br />
        //                 {minPriceFast > 0 && (
        //                   <>
        //                     <span className="price-filter">
        //                       BDT &nbsp;
        //                       {localStorage.getItem("fastest_rate")}
        //                       <span className="border-filter"> | </span>
        //                       <span className="duration_flight">
        //                         {localStorage.getItem("fastest_duration")}
        //                       </span>
        //                     </span>
        //                   </>
        //                 )}
        //               </a>
        //             </nav>

        //             <br />

        //             {/* --------- */}
        //             {isLoaded ? (
        //               refinedTickets.map((ticket, index) => {
        //                 /* sabre oneway jsx */
        //                 /* -d */
        //                 if (ticket.GDS === "Sabre" && !isRoundTrip) {
        //                   if (
        //                     airlinesCheckController.get(
        //                       ticket.ScheduleDescription[0].Carrier
        //                         .OperatingCarrierCode
        //                     )
        //                   ) {
        //                     return (
        //                       <OneWaySabre
        //                         index={index}
        //                         ticket={ticket}
        //                         planeCheck={planeCheck}
        //                         isRoundTrip={isRoundTrip}
        //                         from={from}
        //                         to={to}
        //                         legDescription={legDescription}
        //                         pathname={pathname}
        //                         date={date}
        //                         totalDisSab={totalDisSab}
        //                       />
        //                     );
        //                   }
        //                 }

        //                 /* -d */
        //                 if (ticket.GDS === "Galileo" && !isRoundTrip) {
        //                   if (
        //                     airlinesCheckController.get(
        //                       ticket["air:AirPricingInfo"][0].PlatingCarrier &&
        //                         ticket["air:AirPricingInfo"][0].PlatingCarrier
        //                     )
        //                   ) {
        //                     return (
        //                       <OneWayGalileo
        //                         index={index}
        //                         ticket={ticket}
        //                         planeCheckForGal={planeCheckForGal}
        //                         isRoundTrip={isRoundTrip}
        //                         from={from}
        //                         to={to}
        //                         totalDisGal={totalDisGal}
        //                         legDescription={legDescription}
        //                         pathname={pathname}
        //                         date={date}
        //                       />
        //                     );
        //                   }
        //                 }

        //                 /* Round trip jsx starts here... */
        //                 /* -d */
        //                 if (ticket.GDS === "Sabre" && isRoundTrip) {
        //                   if (
        //                     airlinesCheckController.get(
        //                       ticket.ScheduleDescription[0].Carrier
        //                         .OperatingCarrierCode
        //                     )
        //                   ) {
        //                     return (
        //                       <RoundTripSabre
        //                         index={index}
        //                         ticket={ticket}
        //                         planeCheck={planeCheck}
        //                         isRoundTrip={isRoundTrip}
        //                         from={from}
        //                         to={to}
        //                         legDescription={legDescription}
        //                         pathname={pathname}
        //                         date={date}
        //                         // totalDisSab={totalDisSab}
        //                       />
        //                     );
        //                   }
        //                 }

        //                 /* -d */
        //                 if (ticket.GDS === "Galileo" && isRoundTrip) {
        //                   if (
        //                     airlinesCheckController.get(
        //                       ticket["air:AirPricingInfo"][0].PlatingCarrier &&
        //                         ticket["air:AirPricingInfo"][0].PlatingCarrier
        //                     )
        //                   ) {
        //                     return (
        //                       <RoundTripGalileo
        //                         index={index}
        //                         ticket={ticket}
        //                         planeCheckForGal={planeCheckForGal}
        //                         isRoundTrip={isRoundTrip}
        //                         from={from}
        //                         to={to}
        //                         // totalDisGal={totalDisGal}
        //                         legDescription={legDescription}
        //                         pathname={pathname}
        //                         date={date}
        //                       />
        //                     );
        //                   }
        //                 }
        //               })
        //             ) : (
        //               <div
        //                 style={{
        //                   fontSize: "10px",
        //                   minHeight: "70vh",
        //                 }}
        //               >
        //                 <Loader />
        //               </div>
        //             )}
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
      )}
    </div>
  );
};

const Sorting = ({ planeFound, isRoundTrip, airlinesCheckController }) => {
  let totalPlane = 0;

  planeFound.forEach((ticket) => {
    if (ticket.GDS === "Sabre" && !isRoundTrip) {
      if (
        airlinesCheckController.get(
          ticket.ScheduleDescription[0].Carrier.OperatingCarrierCode
        )
      ) {
        totalPlane++;
      }
    }

    if (ticket.GDS === "Galileo" && !isRoundTrip) {
      if (
        airlinesCheckController.get(
          ticket["air:AirPricingInfo"][0].PlatingCarrier &&
            ticket["air:AirPricingInfo"][0].PlatingCarrier
        )
      ) {
        totalPlane++;
      }
    }

    if (ticket.GDS === "Sabre" && isRoundTrip) {
      if (
        airlinesCheckController.get(
          ticket.ScheduleDescription[0].Carrier.OperatingCarrierCode
        )
      ) {
        totalPlane++;
      }
    }

    if (ticket.GDS === "Galileo" && isRoundTrip) {
      if (
        airlinesCheckController.get(
          ticket["air:AirPricingInfo"][0].PlatingCarrier &&
            ticket["air:AirPricingInfo"][0].PlatingCarrier
        )
      ) {
        totalPlane++;
      }
    }
  });

  return (
    <>
      <div className="container">
        <div className="sorting row align-items-center mt-4 mb-n4">
          <div className="col-md-6 mb-3 mb-md-0">
            Found
            <span className="font-weight-500 ms-1">{totalPlane}</span> Flights
          </div>
          <div className="col-md-6 align-self-lg-end text-md-right"></div>
        </div>
      </div>
    </>
  );
};

export default SearchResult;
