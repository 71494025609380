import React from 'react';
import { Link } from 'react-router-dom';
import im from './404.png';

const FourOeroFour = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '25px',
        paddingBottom: '75px',
      }}
    >
      <img
        src={im}
        alt="404 not found"
        style={{ maxWidth: '100vw', maxHeight: '600px' }}
      />
      <Link to="/">
        <button className="btn btn-primary">Back To Home</button>
      </Link>
    </div>
  );
};

export default FourOeroFour;
