import './onDevstyle.css'

const OnDev = () => {
  return (
    <div className='__container'>
      <div className='_coming_soon'>
        <p>
          App is <b>Coming Soon ...</b>{' '}
        </p>
        <p className='_uc'>
          our app is under consturction.
          <br />
          Thanks for you patience....
        </p>
      </div>
    </div>
  )
}

export default OnDev
