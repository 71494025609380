import React from "react";
import Skeleton from "react-loading-skeleton";

function VisaListSkeleton() {
  return (
    <div className="container my-3">
      <h4>
        <Skeleton width={`60%`} />
      </h4>
      <Skeleton height={40} width={`100%`} />
    </div>
  );
}

export default VisaListSkeleton;
