import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function PackagesSkeleton() {
  return (
    <div className="container my-5 pe-5 ps-5">
      <div className="text-center p-3">
        <Skeleton height={30} width={`60%`} className="mb-2" />
      </div>

      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card">
            <Skeleton height={200} />
            <div className="card-body text-center">
              <Skeleton height={30} width={`40%`} className="mb-2" />
              <Skeleton height={20} width={`30%`} className="mb-2" />
              <Skeleton height={20} width={`60%`} className="mb-2" />
              <Skeleton height={50} width={`30%`} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card">
            <Skeleton height={200} />
            <div className="card-body text-center">
              <Skeleton height={30} width={`40%`} className="mb-2" />
              <Skeleton height={20} width={`30%`} className="mb-2" />
              <Skeleton height={20} width={`60%`} className="mb-2" />
              <Skeleton height={50} width={`30%`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackagesSkeleton;
