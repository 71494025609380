import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import userSVG from "./user.svg";
import moment from "moment";
import X_loader from "./X_loader.gif";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Countries from "../Data/Countries.json";
import { useQuery } from "@tanstack/react-query";
import {
  TotalFareAfterComission,
  AfterCouponSLLCharg,
} from "../../utils/TicketPrice";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import BookingFormSkeleton from "../Skeleton/BookingFormSkeleton";
import MakeBookingSkeleton from "../Skeleton/MakeBookingSkeleton";

const BookTicket = ({ flight, legDescription }) => {
  // const legDescriptionPerFlight = flight?.itinerary_leg_descs;
  console.log(flight, "leg");
  let schDes = localStorage.getItem("schD");
  const legDescriptionPerFlight = JSON.parse(schDes);

  const convertDate = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    return newDate;
  };
  let customLeg = [];
  legDescriptionPerFlight?.forEach((leg) => {
    console.log(customLeg, "customLeg");

    customLeg.push({
      DepartureDate: convertDate(leg?.DepartureDateTime),
      DepartureLocation: leg.OriginLocation.LocationCode,
      ArrivalLocation: leg?.DestinationLocation.LocationCode,
    });
  });

  const [cookie, setCookie] = useCookies(["auth_info"]);

  schDes = JSON.parse(schDes);
  const [fullFareInfo, setFullFareInfo] = useState({});
  const [totalFare, setTotalFare] = useState(0);
  const [baseFare, setBaseFare] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [fareUpdated, setFareUpdated] = useState(false);
  const [allItineraries, setAllItineraries] = useState([]);
  const [revalidating, setRevalidating] = useState(true);
  const [passengerInfo, setPassengerInfo] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currency, setCurrency] = useState("BDT");
  const navigate = useNavigate();
  const [chargeDetails, setChargeDetails] = useState({
    admChargeType: "",
    admChargeAmount: "",
    sslChargeAmount: "",
    sslChargeType: "",
    commission_percentage: "",
    commission_Type: "",
    updated: false,
  });
  const [adminCharge, setAdminCharge] = useState(0);
  const [sslCharge, setSslCharge] = useState(0);
  const [finalCost, setFinalCost] = useState(0);
  const [finalCostWithCommission, setFinalCostWithCommission] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [discountObject, setDiscountObject] = useState(null);
  const isMounted = useRef(true);

  const [couponAccepted, setCouponAccepted] = useState(false);
  const [customFlights, setCustomFlights] = useState([]);

  let passengerIndex = 0;

  var yesterday = moment().add(10, "day");
  var valid = function (current) {
    return current.isAfter(yesterday);
  };

  let customFlightFilter = [];
  allItineraries?.itinerary_leg_descs?.filter((leg) => {
    customFlightFilter.push(leg?.departure_location);
  });

  let customFlightInfo = [];
  customFlightFilter?.map((item) => {
    customFlightInfo?.push(
      allItineraries.schedules?.find(
        (schedule) => schedule?.departure_airport === item
      )
    );
  });

  useEffect(() => {
    setCustomFlights(customFlightInfo);
  }, [allItineraries]);

  const revalidateAtFirst = async () => {
    let schDes = localStorage.getItem("schD");
    schDes = JSON.parse(schDes);

    const {
      gds,
      destination_code,
      origin_code,
      origin_airport_name,
      destination_airport_name,
      departure_date,
      arrival_date,
      departure_time,
      arrival_time,
      total_stop,
      flight_duration,
      airline_code,
      airline_name,
      airline_logo,
      refundable,
      total_seats,
      schedules,
      passenger_infos,
      fare_details,
      itinerary_leg_descs,
    } = flight;
    const revalidate_body = {
      RequireUTILS: false,
      RequestLOG: true,
      RequestBody: {
        OriginDestinationInformation: schDes,
        DirectFlightsOnly: false,
        AvailableFlightsOnly: false,
        LegDescription: customLeg,
        TargetItinerary: {
          gds: gds,
          destination_code: destination_code,
          origin_code: origin_code,
          origin_airport_name: origin_airport_name,
          destination_airport_name: destination_airport_name,
          departure_date: departure_date,
          arrival_date: arrival_date,
          departure_time: departure_time,
          arrival_time: arrival_time,
          total_stop: total_stop,
          flight_duration: flight_duration,
          airline_code: airline_code,
          airline_name: airline_name,
          airline_logo: airline_logo,
          refundable: refundable,
          total_seats: total_seats,
          schedules: schedules,
          passenger_infos: passenger_infos,
          fare_details: fare_details,
          itinerary_leg_descs: itinerary_leg_descs,
        },
      },
    };

    fetch(`${process.env.REACT_APP_DEV_API_URL}/gds/revalidate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(revalidate_body),
    })
      .then((res) => res.json())
      .then((data) => {
        // if (data.data.responseData.Itineraries === undefined) {
        //   context.red(true);
        //   navigate(rpathname);
        //   return;
        // }
        // setLoading(false);
        // localStorage.setItem(
        //   "is_domestic",
        //   data.body.responseData.Itineraries[0].isDomestic
        // );
        // localStorage.setItem(
        //   "is_soto_ticket",
        //   data.body.responseData.Itineraries[0].isSotoTicket
        // );
        // setCabinClass(
        //   data.body.responseData.Itineraries[0].PassengerInfo[0]
        //     .FareDescription[0].Segment[0].CabinClass
        // );

        // localStorage.setItem(
        //   "cabin",
        //   data.body.responseData.Itineraries[0].PassengerInfo[0]
        //     .FareDescription[0].Segment[0].CabinClass
        // );
        if (data?.success == true) {
          setRevalidating(false);
          setPassengerInfo(data?.data?.sortedItineraries?.passenger_infos);
          setAllItineraries(data?.data?.sortedItineraries);
          setFullFareInfo(data.data?.sortedItineraries?.fare_details);

          setTotalFare(data.data?.sortedItineraries?.fare_details?.total_fare);
          setBaseFare(data.data?.sortedItineraries?.fare_details?.base_fare);
          setTotalTaxAmount(
            data.data?.sortedItineraries?.fare_details?.tax_fare
          );
          if (
            data.data?.sortedItineraries?.fare_details?.total_fare !=
              fare_details?.total_fare ||
            data.data?.sortedItineraries?.fare_details?.base_fare !=
              fare_details?.base_fare ||
            data.data?.sortedItineraries?.passenger_infos?.[0]?.cabin_class !=
              passenger_infos?.[0]?.cabin_class
          ) {
            setFareUpdated(true);
          }
        } else {
          toast.error(data.message);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      });
  };

  useEffect(() => {
    if (totalFare !== undefined) {
      revalidateAtFirst();
    }
  }, [totalFare]);

  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${process.env.REACT_APP_CERT_GET_APP_SETTING_API}`,
  //     headers: {
  //       "x-api-key": `${process.env.REACT_APP_CERT_GET_APP_SETTING_X_API}`,
  //     },
  //   })
  //     .then(({ data }) => {
  //       const _d = data.body.responseData;
  //       setChargeDetails({
  //         admChargeType: _d.AdminChargeType,
  //         admChargeAmount: _d.AdminCharge,
  //         sslChargeAmount: _d.PaymentGatewayCharge,
  //         sslChargeType: _d.PaymentGatewayChargeType,
  //         commission_percentage: ticket?.isSotoTicket
  //           ? 0
  //           : _d.commission_percentage,
  //         commission_Type: _d.commission_type,
  //         updated: true,
  //       });
  //     })
  //     .catch((err) => {});
  // }, []);

  const {
    data: settings,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_DEV_API_URL}/application-settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const _d = data.data;
          setChargeDetails({
            admChargeType: _d.admin_charge_type,
            admChargeAmount: _d.admin_charge,
            sslChargeAmount: _d.payment_gateway_charge,
            sslChargeType: _d.payment_gateway_charge_type,
            commission_percentage: _d.commission_percentage,
            commission_Type: _d.commission_type,
            updated: true,
          });
          setCurrency(_d.currency);
        }),
  });

  useEffect(() => {
    if (chargeDetails.updated) {
      if (chargeDetails.admChargeType === "percent") {
        setAdminCharge(
          Math.ceil((totalFare * chargeDetails.admChargeAmount) / 100)
        );
      } else {
        setAdminCharge(chargeDetails.admChargeAmount);
      }
    }
  }, [chargeDetails, totalFare]);

  useEffect(() => {
    if (chargeDetails.updated) {
      if (chargeDetails.sslChargeType === "percent") {
        let total;
        if (chargeDetails.commission_Type == "percent") {
          total =
            (totalFare +
              adminCharge -
              baseFare * (chargeDetails.commission_percentage / 100)) *
            (chargeDetails.sslChargeAmount / 100);
        } else {
          total =
            (totalFare + adminCharge - chargeDetails.commission_percentage) *
            (chargeDetails.sslChargeAmount / 100);
        }
        setSslCharge(Math.ceil(total));
      } else {
        setSslCharge(Math.ceil(chargeDetails.sslChargeAmount));
      }
    }
  }, [chargeDetails, totalFare, adminCharge, baseFare]);

  useEffect(() => {
    if (chargeDetails.updated) {
      setFinalCost(adminCharge + sslCharge + totalFare);
      if (chargeDetails.commission_Type == "percent") {
        setFinalCostWithCommission(
          adminCharge +
            sslCharge +
            TotalFareAfterComission({
              equivalentAmount: baseFare,
              totalTaxAmount: totalTaxAmount,
            })
        );
      } else {
        setFinalCostWithCommission(
          adminCharge +
            sslCharge +
            totalFare -
            chargeDetails.commission_percentage
        );
      }
    }
  }, [
    adminCharge,
    sslCharge,
    totalTaxAmount,
    totalFare,
    baseFare,
    chargeDetails.updated,
  ]);

  console.log("finalCostWithCommission", finalCost);
  const [passengerData, setPassengerData] = useState([]);
  const [passengerCount, setPassengerCount] = useState(0);
  const passengerTypedData = localStorage.getItem("s-***");

  console.log(passengerData, "passengerData");

  const passengerInputtedData = JSON.parse(passengerTypedData);

  // const handlePassengerVariants = () => {
  //   let totalPassengerData = [];
  //   for (let i = 0; i < passengerCount; ++i) {
  //     if (
  //       passengerInfo[i]?.passenger_type === "ADT" ||
  //       (passengerInfo[i]?.passenger_type === "CNN" &&
  //         passengerInfo[i]?.passenger_number > 1)
  //     ) {
  //       totalPassengerData.push({
  //         passenger_type: passengerInfo[i]?.passenger_type,
  //         available_seats: passengerInfo[i]?.available_seats,
  //         baggage_info: passengerInfo[i]?.baggage_info,
  //         passenger_number: 1,
  //         total_fare: passengerInfo[i]?.total_fare,
  //         base_fare: passengerInfo[i]?.base_fare,
  //         tax_fare: passengerInfo[i]?.tax_fare,
  //         booking_code: passengerInfo[i]?.booking_code,
  //         cabin_class: passengerInfo[i]?.cabin_class,
  //         currency: passengerInfo[i]?.currency,
  //         fare_basis_code: passengerInfo[i]?.fare_basis_code,
  //         meal_code: passengerInfo[i]?.meal_code,
  //         refundable: passengerInfo[i]?.refundable,
  //       });
  //     } else{

  //     }

  //     console.log(totalPassengerData, "totalPassengerData");
  //     // totalPassengerData.push({
  //     //   passenger_info[0]
  //     // });
  //   }
  // };
  const handlePassengerCount = () => {
    let totalPassengerCount = 0;
    for (let i = 0; i < passengerInfo?.length; ++i) {
      totalPassengerCount =
        totalPassengerCount + Number(passengerInfo[i]?.passenger_number);
    }
    setPassengerCount(Number(totalPassengerCount));
  };
  console.log(legDescription);

  const sendRequestForDiscount = useCallback(async () => {
    const requestBody = (function () {
      const LegDescription = [];

      let legData = {
        OriginLocation: legDescription.DepartureLocation,
        DestinationLocation: legDescription.ArrivalLocation,
      };
      LegDescription.push(legData);

      return LegDescription;
    })();
    if (isSending) return;
    setIsSending(true);

    await fetch(`${process.env.REACT_APP_DEV_API_URL}/coupon-values`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        LegDescription: requestBody,
        CouponCode: couponCode,
        BaseFare: parseFloat(baseFare),
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setCouponCode("");
        if (result.success === true) {
          setCouponAccepted(true);
          setDiscountObject(result);
          setCouponCode(result.data?.coupon?.code);
          localStorage.setItem("cpn", true);
          toast.success("Thanks for using the cupon", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.warning("Invalid Coupon ID.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    if (isMounted.current) setIsSending(false);
  }, [isSending, couponCode]);
  const doSomething = () => {
    let newArr = [];

    passengerInfo?.map((traveler) => {
      for (let i = 0; i < traveler["passenger_number"]; ++i) {
        newArr.push({
          userType: "Mr.",
          givenName: "",
          surName: "",
          DocType: "P",
          DOB: moment(new Date()).format("YYYY-MM-DD"),
          DocumentNo: "",
          IssueCountry: "BGD",
          NationalityCountry: "BGD",
          passengerType: "ADT",
          ExpirationDate: moment(moment().add(1, "day")).format("YYYY-MM-DD"),
        });
        setPassengerData(newArr);
      }
      return true;
    });
  };
  useEffect(() => {
    // handlePassengerVariants();
    handlePassengerCount();
    doSomething();
  }, [passengerInfo, passengerCount]);

  const passengerDropDownValueHandler = (index, e) => {
    let newArr = [...passengerData];

    if (e.target.value == 1) {
      newArr[index].DocType = "P";
    }

    if (e.target.value == 2) {
      newArr[index].DocType = "I";
    }

    if (e.target.value == 11) {
      newArr[index].userType = "Mr.";
    }

    if (e.target.value == 22) {
      newArr[index].userType = "Mrs.";
    }

    if (e.target.value == 33) {
      newArr[index].userType = "Ms.";
    }

    setPassengerData(newArr);
  };

  const passengerInputValueHandler = (index, e) => {
    let newArr = [...passengerData];

    if (e.target.id === "givenName") {
      newArr[index].givenName = e.target.value.replace(".", "");
    } else {
      newArr[index].surName = e.target.value.replace(".", "");
    }

    setPassengerData(newArr);
  };

  const IssueCountry = (index, e) => {
    let newArr = [...passengerData];
    if (e.target.value !== "undf") {
      newArr[index].IssueCountry = e.target.value;
    }
    setPassengerData(newArr);
  };

  const NationalityCountry = (index, e) => {
    let newArr = [...passengerData];
    if (e.target.value !== "undf") {
      newArr[index].NationalityCountry = e.target.value;
    }

    setPassengerData(newArr);
  };

  const ExpirationDate = (index, e) => {
    let newArr = [...passengerData];

    newArr[index].ExpirationDate = moment(e).format("YYYY-MM-DD");

    setPassengerData(newArr);
  };
  const passengerDocumentValueHandler = (index, e) => {
    let newArr = [...passengerData];

    newArr[index].DocumentNo = e.target.value;

    setPassengerData(newArr);
  };

  const travelersQuantityHandler = () => {
    let travelers = "";

    passengerInfo?.map((passenger) => {
      travelers =
        travelers +
        " " +
        passenger.passenger_number +
        " " +
        passenger.passenger_type +
        ",";
    });

    travelers = travelers.slice(0, travelers.length - 1);

    return travelers;
  };

  const passengerBirthDayHandler = (index, event, passenger_type) => {
    let newArr = [...passengerData];

    var init = moment();

    let year_age = 1;
    /*** */
    try {
      let today = new Date();
      let birthday = new Date(event.year(), event.month(), event.date());

      let differenceInMilisecond = today.valueOf() - birthday.valueOf();
      year_age = Math.floor(differenceInMilisecond / 31536000000);
      console.log(passenger_type, year_age, birthday);
    } catch (error) {}

    /** */

    if (passenger_type === "ADT") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age >= 12) {
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        } else {
          toast.error("Adult Age Should be more than 12");
          newArr[index].DOB = "";
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "INF") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age >= 0 && year_age <= 2) {
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        } else {
          toast.error("Infant Age Must be between 0 to 2");
          newArr[index].DOB = "";
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C02") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 2) {
          console.log("show me date", moment(event).format("YYYY-MM-DD"));
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 2;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;

          toast.error("Child Age Should be 02");
          newArr[index].DOB = formattedDate;
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C03") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 3) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 3;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;

          toast.error("Child Age Should be 03");
          newArr[index].DOB = formattedDate;
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C04") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 4) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 4;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 04");
          newArr[index].DOB = formattedDate;
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C05") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 5) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 5;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 05");
          newArr[index].DOB = formattedDate;
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C06") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 6) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 6;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 06");
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = formattedDate;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C07") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 7) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 7;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 07");
          newArr[index].DOB = formattedDate;
          newArr[index].passengerType = passenger_type;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C08") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 8) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 8;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 08");
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = formattedDate;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C09") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 9) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 9;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 09");
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = formattedDate;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C10") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 10) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 10;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 10");
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = formattedDate;
          setPassengerData(newArr);
        }
      }
    }

    if (passenger_type === "C11") {
      if (moment(event).format("YYYY-MM-DD") !== "Invalid date") {
        if (year_age === 11) {
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = moment(event).format("YYYY-MM-DD");
          setPassengerData(newArr);
        } else {
          const date = new Date();

          const year = date.getFullYear() - 11;
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          toast.error("Child Age Should be 11");
          newArr[index].passengerType = passenger_type;
          newArr[index].DOB = formattedDate;
          setPassengerData(newArr);
        }
      }
    }
  };

  const userTypeHelper = (type) => {
    if (type === "ADT") {
      return "Adult";
    }
    if (type === "INF") {
      return "Infant";
    }

    if (type === "C02") {
      return "Child(Age 02)";
    }

    if (type === "C03") {
      return "Child(Age 03)";
    }

    if (type === "C04") {
      return "Child(Age 04)";
    }

    if (type === "C05") {
      return "Child(Age 05)";
    }

    if (type === "C06") {
      return "Child(Age 06)";
    }

    if (type === "C07") {
      return "Child(Age 07)";
    }

    if (type === "C08") {
      return "Child(Age 08)";
    }

    if (type === "C09") {
      return "Child(Age 09)";
    }

    if (type === "C10") {
      return "Child(Age 10)";
    }

    if (type === "C11") {
      return "Child(Age 11)";
    }

    return type;
  };

  const [loader, setLoader] = useState(false);
  const [pnrLoading, setPnrLoading] = useState(false);

  useEffect(() => {
    if (phone?.length > 15) {
      toast.error("Phone Number is too long");
    }
  }, [phone]);

  const Booking = (e) => {
    e.preventDefault();
    setLoader(true);
    setPnrLoading(true);
    // const {
    //   gds,
    //   destination_code,
    //   origin_code,
    //   origin_airport_name,
    //   destination_airport_name,
    //   departure_date,
    //   arrival_date,
    //   departure_time,
    //   arrival_time,
    //   total_stop,
    //   flight_duration,
    //   airline_code,
    //   airline_name,
    //   airline_logo,
    //   refundable,
    //   total_seats,
    //   schedules,
    //   passenger_infos,
    //   fare_details,
    //   itinerary_leg_descs,
    // } = allItineraries;

    // // setLoading(true);
    // const revalidate_body = {
    //   RequireUTILS: false,
    //   RequestLOG: true,
    //   RequestBody: {
    //     OriginDestinationInformation: schDes,
    //     DirectFlightsOnly: false,
    //     AvailableFlightsOnly: false,
    //     LegDescription: customLeg,
    //     TargetItinerary: {
    //       gds: gds,
    //       destination_code: destination_code,
    //       origin_code: origin_code,
    //       origin_airport_name: origin_airport_name,
    //       destination_airport_name: destination_airport_name,
    //       departure_date: departure_date,
    //       arrival_date: arrival_date,
    //       departure_time: departure_time,
    //       arrival_time: arrival_time,
    //       total_stop: total_stop,
    //       flight_duration: flight_duration,
    //       airline_code: airline_code,
    //       airline_name: airline_name,
    //       airline_logo: airline_logo,
    //       refundable: refundable,
    //       total_seats: total_seats,
    //       schedules: schedules,
    //       passenger_infos: passenger_infos,
    //       fare_details: fare_details,
    //       itinerary_leg_descs: itinerary_leg_descs,
    //     },
    //   },
    // };
    // fetch(`${process.env.REACT_APP_DEV_API_URL}/gds/revalidate`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },

    //   body: JSON.stringify(revalidate_body),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data, "data");

    //     if (data.success === true) {
    //       reserveTicket();
    //     } else {
    //       return;
    //     }
    //   });
    const token = localStorage.getItem("token");

    if (!token) {
      fetch(`${process.env.REACT_APP_DEV_API_URL}/user/minimal-register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: passengerData[0].givenName,
          last_name: passengerData[0].surName,
          email: email,
          phone: phone,
          verify_by: "email",
        }),
      }).then((res) => {
        res.json().then((data) => {
          if (data.success === true) {
            localStorage.setItem("token", data.authorization.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("user_id", data.user.id);
            localStorage.setItem("user_type", data.user.user_type);
            setCookie("auth_info", data, { path: "/" });
            setCookie("token", data?.authorization?.token, { path: "/" });

            makeBooking();
          } else {
            toast.error(data?.errors[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setPnrLoading(false);
            setLoader(false);
          }
        });
      });
    } else {
      makeBooking();
    }
  };

  const userTypeMaking = () => {};

  useEffect(() => {
    userTypeMaking();
  }, [passengerData]);

  const makeBooking = () => {
    const {
      gds,
      destination_code,
      origin_code,
      origin_airport_name,
      destination_airport_name,
      departure_date,
      arrival_date,
      departure_time,
      arrival_time,
      total_stop,
      flight_duration,
      airline_code,
      airline_name,
      airline_logo,
      refundable,
      total_seats,
      schedules,
      passenger_infos,
      fare_details,
      itinerary_leg_descs,
      air_pricing_solution_key,
    } = allItineraries;

    // let passengerInput = [];

    let pxn_type = [];
    let first_name = [];
    let last_name = [];
    let dob = [];
    let doc_type = [];
    let doc_number = [];
    let doc_expire_date = [];
    let doc_issue_country = [];
    let nationality = [];

    let pxn_title = {};

    passengerData.forEach((item, index) => {
      pxn_title[`pxn_title_${index + 1}`] = item.userType;
    });

    passengerData?.map((data) => {
      pxn_type.push(data.passengerType);
      first_name.push(data.givenName);
      last_name.push(data.surName);
      doc_type.push(data.DocType);
      dob.push(data.DOB);
      doc_number.push(data.DocumentNo);
      doc_expire_date.push(data.ExpirationDate);
      doc_issue_country.push(data.IssueCountry);
      nationality.push(data.NationalityCountry);

      // passengerInput.push({
      //   email: email,
      //   phone_no: phone,
      //   pxn_title_1: data.userType,
      //   pxn_type: data.passengerType,
      //   first_name: data.givenName,
      //   last_name: data.surName,
      //   dob: data.DOB,
      //   doc_type: data.DocType,
      //   doc_number: data.DocumentNo,
      //   doc_expire_date: data.ExpirationDate,
      //   doc_issue_country: data.IssueCountry,
      //   nationality: data.NationalityCountry,
      // });
    });

    let obj = {
      email: email,
      phone_no: phone,
      pxn_type: pxn_type,
      first_name: first_name,
      last_name: last_name,
      dob: dob,
      doc_type: doc_type,
      doc_number: doc_number,
      doc_expire_date: doc_expire_date,
      doc_issue_country: doc_issue_country,
      nationality: nationality,
    };

    passengerData.forEach((item, index) => {
      obj[`pxn_title_${index + 1}`] = item.userType;
    });

    const sendBody = {
      Amount: couponAccepted
        ? finalCostWithCommission - discountObject?.data?.amount
        : finalCostWithCommission,
      Coupon: couponAccepted,
      CouponCode: discountObject?.coupon?.code
        ? discountObject?.coupon?.code
        : "",
      RequireUTILS: false,
      RequestLOG: true,
      RequestBody: {
        OriginDestinationInformation: schDes,
        DirectFlightsOnly: false,
        AvailableFlightsOnly: false,
        LegDescription: customLeg,
        TargetItinerary: {
          gds: gds,
          air_pricing_solution_key: air_pricing_solution_key,
          destination_code: destination_code,
          origin_code: origin_code,
          origin_airport_name: origin_airport_name,
          destination_airport_name: destination_airport_name,
          departure_date: departure_date,
          arrival_date: arrival_date,
          departure_time: departure_time,
          arrival_time: arrival_time,
          total_stop: total_stop,
          flight_duration: flight_duration,
          airline_code: airline_code,
          airline_name: airline_name,
          airline_logo: airline_logo,
          refundable: refundable,
          total_seats: total_seats,
          schedules: schedules,
          passenger_infos: passenger_infos,
          fare_details: fare_details,
          itinerary_leg_descs: itinerary_leg_descs,
        },
        PassengerInformation: obj,
      },
    };
    console.log(sendBody, "sendBody");
    localStorage.setItem("s-***", JSON.stringify(sendBody));
    fetch(`${process.env.REACT_APP_DEV_API_URL}/gds/make-booking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(sendBody),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == true) {
          return (window.location.href = result.data.redirect_url);
        } else {
          setLoader(false);
          setPnrLoading(false);

          toast.error(result.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const reserveTicket = async () => {
    // const token = localStorage.getItem("token");
    // if (!token) {
    //   fetch(`${process.env.REACT_APP_DEV_API_URL}/user/minimal-register`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       first_name: passengerData[0].givenName,
    //       last_name: passengerData[0].surName,
    //       email: email,
    //       phone: phone,
    //       verify_by: "email",
    //     }),
    //   }).then((res) => {
    //     res.json().then((data) => {
    //       if (data.success === true) {
    //         localStorage.setItem("token", data.authorization.token);
    //         localStorage.setItem("user", JSON.stringify(data.user));
    //         localStorage.setItem("user_id", data.user.id);
    //         localStorage.setItem("user_type", data.user.user_type);
    //         setCookie("auth_info", data, { path: "/" });
    //         setCookie("token", data?.authorization?.token, { path: "/" });
    //         makeBooking();
    //       } else {
    //         setLoader(false);
    //       }
    //     });
    //   });
    // } else {
    //   makeBooking();
    // }
    // let pass = true;
    // passengerData.map((data) => {
    //   if (
    //     moment(new Date()).format("YYYY-MM-DD") === data.DOB ||
    //     data.DOB === ""
    //   ) {
    //     pass = false;
    //     return;
    //   }
    // });
    // if (email.length < 10) {
    //   pass = false;
    // }
  };

  const timeCalc = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    if (hours === 0) return minutes + "min";
    return hours + "hr " + minutes + "min";
  };

  const removeCountryCode = (time) => {
    // make it just time not country code
    return time.replace(/.*?(\d{2}:\d{2}).*/, "$1");
  };

  return (
    <>
      {revalidating ? (
        <>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "750px",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <img src={X_loader} alt="" />
            <p>Loading</p>
          </div> */}
          <BookingFormSkeleton />
        </>
      ) : (
        <>
          {pnrLoading ? (
            <MakeBookingSkeleton />
          ) : (
            <div>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
              />
              <div className="py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9 mainContent">
                      <div className="card shadow border-0 mb-3">
                        <div className="card-body">
                          <div>
                            <div className="flight-info border rounded">
                              {allItineraries.schedules ? (
                                allItineraries.schedules?.map((stop, index) => {
                                  return (
                                    <>
                                      <div>
                                        <Row className="row review-article my-3 align-items-center no-gutters position-relative">
                                          <Col
                                            sm={3}
                                            lg={2}
                                            className="text-sm-center mb-4 mb-sm-0 __image-name"
                                          >
                                            <div className="brand-img">
                                              <img
                                                src={`https://pics.avs.io/200/200/${stop?.operating_code}@2x.png`}
                                                alt=""
                                                className="img-fluid _img"
                                              />{" "}
                                            </div>
                                            <div className="airline-box">
                                              <div className="fs-13 fs-13-1">
                                                {stop?.operating_code} -{" "}
                                                {stop?.flight_number}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col sm={9} lg={10}>
                                            <Row>
                                              <Col
                                                md={3}
                                                sm={12}
                                                className="_vax text-center"
                                              >
                                                <div className="fs-13 font-weight-500">
                                                  {stop?.departure_airport}
                                                </div>
                                                <span className="fs-14 font-weight-400 d-block">
                                                  {stop?.departure_date}{" "}
                                                </span>
                                                <span>
                                                  {removeCountryCode(
                                                    stop?.departure_time
                                                  )}{" "}
                                                  (Local time)
                                                </span>
                                                <span className=" fs-13 d-block">
                                                  Terminal -{" "}
                                                  {stop?.departure_terminal}
                                                </span>
                                                <span className="text-muted fs-13">
                                                  {/* {airportNameFind(
                                          stop?.departure_airport
                                        )} */}
                                                  {stop.departure_city}
                                                </span>
                                              </Col>
                                              {/* eslint-disable-next-line */}
                                              <Col
                                                md={6}
                                                className="text-center fs-14 mt-4 mt-md-0"
                                              >
                                                <div
                                                  className="d-sm-flex align-items-center justify-content-center"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <span className="d-inline-flex align-items-center">
                                                    <img
                                                      src="/assets/img/clock.svg"
                                                      alt="clock"
                                                      className="img-fluid service-icon mr-1"
                                                    />
                                                    {timeCalc(
                                                      stop?.elapsed_time
                                                    )}
                                                    &nbsp; Duration
                                                    {/* {durationCalculator(
                                            dateNormalizer(
                                              stop.SegmentDetails.ArrivalTime
                                            ),
                                            dateNormalizer(
                                              stop.SegmentDetails.DepartureTime
                                            )
                                          )} */}
                                                  </span>
                                                  <span className="d-inline-flex align-items-center">
                                                    &nbsp;
                                                    <span className="text-muted">
                                                      |
                                                    </span>
                                                    &nbsp;
                                                    <img
                                                      src="/assets/img/meal-1.svg"
                                                      alt=""
                                                      className="img-fluid service-icon mr-1"
                                                    />
                                                    {
                                                      flight.passenger_infos[0]
                                                        .meal_code
                                                    }
                                                  </span>
                                                  <span className="d-inline-flex align-items-center ">
                                                    &nbsp;
                                                    <span className="text-muted">
                                                      |
                                                    </span>
                                                    &nbsp;
                                                    <img
                                                      src="/assets/img/economy.svg"
                                                      alt=""
                                                      className="img-fluid service-icon mr-1"
                                                    />
                                                    {flight.passenger_infos[0]
                                                      .cabin_class == "Y"
                                                      ? "Economy"
                                                      : flight
                                                          .passenger_infos[0]
                                                          .cabin_class == "C"
                                                      ? "Business"
                                                      : flight
                                                          .passenger_infos[0]
                                                          .cabin_class == "S"
                                                      ? "Premium Economy"
                                                      : flight
                                                          .passenger_infos[0]
                                                          .cabin_class == "J"
                                                      ? "Premium Business"
                                                      : flight
                                                          .passenger_infos[0]
                                                          .cabin_class == "F"
                                                      ? "First Class"
                                                      : flight
                                                          .passenger_infos[0]
                                                          .cabin_class == "P"
                                                      ? "Premium First"
                                                      : flight
                                                          .passenger_infos[0]
                                                          .cabin_class}
                                                  </span>
                                                </div>
                                                <div className="two-dots my-3 text-muted position-relative border-top">
                                                  <span className="flight-service">
                                                    <img
                                                      src="/assets/img/airplane.svg"
                                                      alt=""
                                                      className="img-fluid service-icon service-icon_flight mr-1"
                                                    />
                                                    <span className="type-text px-2 position-relative">
                                                      Flight
                                                    </span>
                                                  </span>
                                                </div>

                                                <div className="d-sm-flex align-items-center justify-content-center">
                                                  {allItineraries?.refundable ? (
                                                    <span className="text-success">
                                                      Refundable
                                                    </span>
                                                  ) : (
                                                    <span className="text-danger">
                                                      Non-Refundable
                                                    </span>
                                                  )}
                                                </div>
                                              </Col>
                                              <Col
                                                md={3}
                                                sm={12}
                                                className="_vax text-center"
                                              >
                                                <div className="fs-13 font-weight-500">
                                                  {stop?.arrival_airport}
                                                </div>
                                                <span className="fs-14 font-weight-400 d-block">
                                                  {stop?.arrival_date}{" "}
                                                </span>
                                                <span>
                                                  {removeCountryCode(
                                                    stop?.arrival_time
                                                  )}{" "}
                                                  (Local time)
                                                </span>
                                                <span className=" fs-13 d-block">
                                                  Terminal -{" "}
                                                  {stop?.arrival_terminal}
                                                </span>
                                                <span className="text-muted fs-13">
                                                  {/* {airportNameFind(stop?.arrival_airport)} */}
                                                  {stop.arrival_city}
                                                </span>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                        {index ===
                                        allItineraries.schedules.length -
                                          1 ? null : (
                                          <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                                            <span className="tri-right"></span>

                                            <span className="tri-left"></span>
                                          </div>
                                        )}

                                        {/* {index === fly.schedules.length - 1 ? null : (
                                <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                                  <span className="tri-right"></span>
                                  <span className="tri-left"></span>
                                </div>
                              )} */}

                                        {/* {(() => {
                                if (stop.SegmentDetails.DepartureTime.split('T')[0] != date) {
                                  
                                }
                              })()} */}
                                        {/* {isRoundTrip &&
                          stop.SegmentDetails.Destination === to && (
                            <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                              <span className="tri-right"></span>
                              <span className="fs-11 layover">
                                {stop.SegmentDetails.Destination !== to
                                  ? layovers[index]
                                  : `↓↓↓ ${date.split("T")[0]} ↓↓↓`}
                              </span>
                              <span className="tri-left"></span>
                            </div>
                          )}
                        {index ===
                        data["air:Option"][0]["air:BookingInfo"].length -
                          1 ? null : (
                          <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                            <span className="tri-right"></span>
                            <span className="fs-11 layover">
                              {stop.SegmentDetails.Destination !== to
                                ? layovers[index + hox]
                                : `↓↓↓ ${date.split("T")[0]} ↓↓↓`}
                            </span>
                            <span className="tri-left"></span>
                          </div>
                        )} */}
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <div>
                                  <h2>Not found </h2>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow border-0 mb-3">
                        {fareUpdated && (
                          <li class="m-3 list-group-item list-group-item-warning text-center">
                            Your Fare has been updated, Please Check new Fare
                            and Cabin Class.
                          </li>
                        )}

                        <form onSubmit={Booking}>
                          <div className="card-body p-md-5">
                            <div className="content-header d-flex mb-3">
                              <div className=" card-icon d-flex align-items-center justify-content-center rounded text-white me-3">
                                <img src={userSVG} alt="" />
                              </div>
                              <div className="media-body">
                                <h3 className="content-header_title fs-23 mb-0">
                                  Traveler Details
                                </h3>
                                <p>
                                  Please provide real information, otherwise
                                  ticket will not issue.
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className=" col-sm-12 col-md-3 font-weight-500 text-left text-md-right mb-3 mb-md-0 pr-3">
                                Contact Details
                              </div>
                              <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                <input
                                  type="email"
                                  className="form-control bg-white shadow-none"
                                  placeholder="Email address"
                                  value={email}
                                  onChange={(eve) => setEmail(eve.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                <input
                                  type="number"
                                  className="form-control bg-white shadow-none"
                                  placeholder="Phone Number"
                                  value={phone}
                                  onChange={(eve) => setPhone(eve.target.value)}
                                  required
                                />
                              </div>
                              <div className="offset-md-3 col-md-9">
                                <p className="mt-2">
                                  Your booking details will be sent to this
                                  email address.
                                </p>
                              </div>
                            </div>
                            <hr />
                            <p className="mb-4">
                              <span className="fs-18 font-weight-600">
                                Traveler Information
                              </span>
                            </p>

                            {passengerInfo?.map((traveler, i) => {
                              let travelerDiv = [];
                              for (
                                let i = 0;
                                i < traveler.passenger_number;
                                ++i
                              ) {
                                let currentPassengerIndex = passengerIndex;
                                travelerDiv.push(
                                  <>
                                    <div className="row" key={i}>
                                      <div className="col-sm-12 col-md-3 font-weight-500 text-left text-md-right mb-3 mb-md-0 pr-3">
                                        {"Passenger-"}{" "}
                                        {userTypeHelper(
                                          traveler?.passenger_type
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                        <div className="input-select position-relative">
                                          <span className="select-wrapper">
                                            {passengerData[
                                              currentPassengerIndex
                                            ] !== undefined ? (
                                              <span className="input-text">
                                                {
                                                  passengerData[
                                                    currentPassengerIndex
                                                  ].userType
                                                }
                                              </span>
                                            ) : null}
                                            {/* {traveler?.passenger_type ==
                                                      "ADT" ? (
                                                        <span className="input-text">
                                                          Adult
                                                        </span>
                                                      ) : traveler?.passenger_type ==
                                                        "CNN" ? (
                                                        <span className="input-text">
                                                          Child
                                                        </span>
                                                      ) : traveler?.passenger_type ==
                                                        "INF" ? (
                                                        <span className="input-text">
                                                          Infant
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )} */}

                                            <select
                                              className="select-dropdown"
                                              style={{
                                                color: "black",
                                              }}
                                              onChange={(event) =>
                                                passengerDropDownValueHandler(
                                                  currentPassengerIndex,
                                                  event
                                                )
                                              }
                                            >
                                              <option
                                                value={11}
                                                style={{
                                                  color: "black",
                                                }}
                                              >
                                                Mr.
                                              </option>
                                              <option value={22}>Mrs.</option>
                                              <option value={33}>Ms.</option>
                                            </select>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control bg-white shadow-none"
                                            id="givenName"
                                            placeholder="First Name"
                                            onChange={(event) =>
                                              passengerInputValueHandler(
                                                currentPassengerIndex,
                                                event
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                        <input
                                          type="text"
                                          className="form-control bg-white shadow-none"
                                          placeholder="Last Name"
                                          id="surName"
                                          onChange={(event) =>
                                            passengerInputValueHandler(
                                              currentPassengerIndex,
                                              event
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" col-sm-12 col-md-3 font-weight-500 text-left text-md-right mb-3 mb-md-0 pr-3 ">
                                        Date Of Birth
                                      </div>
                                      <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                        <Datetime
                                          initialValue={new Date()}
                                          dateFormat="DD-MM-YYYY"
                                          timeFormat={false}
                                          onChange={(event) => {
                                            passengerBirthDayHandler(
                                              currentPassengerIndex,
                                              event,
                                              traveler?.passenger_type
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                        <select
                                          class="form-select bg-white shadow-none"
                                          onChange={(eve) => {
                                            NationalityCountry(
                                              currentPassengerIndex,
                                              eve
                                            );
                                          }}
                                          required
                                          name="Nationality"
                                          id="nationality"
                                        >
                                          <option value="">
                                            Select Your Nationality
                                          </option>

                                          {Countries.map((country) => (
                                            <option
                                              selected={
                                                country.alpha_3_code == "BGD"
                                              }
                                              key={country.en_short_name}
                                              value={country.alpha_3_code}
                                            >
                                              {country.en_short_name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className=" col-sm-12 col-md-3 font-weight-500 text-left text-md-right mb-3 mb-md-0 pr-3 ">
                                        Document Type{" "}
                                      </div>
                                      <div className="col-sm-6 col-md-8 mb-3 mb-sm-3">
                                        <div className="input-selectx position-relative">
                                          <span
                                            className="select-wrapper"
                                            style={{ width: "115px" }}
                                          >
                                            {passengerData[
                                              currentPassengerIndex
                                            ] !== undefined ? (
                                              <span className="input-text">
                                                {passengerData[
                                                  currentPassengerIndex
                                                ].DocType === "P"
                                                  ? "Passport"
                                                  : "National ID"}
                                              </span>
                                            ) : null}

                                            <select
                                              className="select-dropdown"
                                              onChange={(event) =>
                                                passengerDropDownValueHandler(
                                                  currentPassengerIndex,
                                                  event
                                                )
                                              }
                                            >
                                              <option value={1}>
                                                Passport
                                              </option>
                                              <option value={2}>
                                                National ID
                                              </option>
                                            </select>
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control bg-white shadow-none"
                                            placeholder="Document Number"
                                            onChange={(event) =>
                                              passengerDocumentValueHandler(
                                                currentPassengerIndex,
                                                event
                                              )
                                            }
                                            // required={!isDomestic}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className=" col-sm-12 col-md-3 font-weight-500 text-left text-md-right mb-3 mb-md-0 pr-3 ">
                                        Document Expiration
                                      </div>
                                      <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                        <Datetime
                                          initialValue={moment().add(10, "day")}
                                          dateFormat="DD-MM-YYYY"
                                          timeFormat={false}
                                          isValidDate={valid}
                                          onChange={(event) =>
                                            ExpirationDate(
                                              currentPassengerIndex,
                                              event
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-sm-6 col-md-4 mb-3 mb-sm-3">
                                        <select
                                          class="form-select bg-white shadow-none"
                                          onChange={(event) =>
                                            IssueCountry(
                                              currentPassengerIndex,
                                              event
                                            )
                                          }
                                        >
                                          <option value="undf">
                                            Document Issue Country
                                          </option>
                                          {Countries.map((country) => (
                                            <option
                                              selected={
                                                country.alpha_3_code == "BGD"
                                              }
                                              key={country.en_short_name}
                                              value={country.alpha_3_code}
                                            >
                                              {country.en_short_name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <hr
                                      style={{
                                        width: "50%",
                                        textAlign: "center",
                                        marginLeft: "25%",
                                      }}
                                    />
                                  </>
                                );
                                passengerIndex += 1;
                              }

                              return travelerDiv;
                            })}

                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                I Agree with terms & Conditions and Policy
                              </label>
                            </div>
                            <div
                              className="text-right d-lg-block"
                              style={{ textAlign: "right" }}
                            >
                              <button
                                type="button"
                                className="btn btn-danger me-2"
                                onClick={() => {
                                  navigate(-1);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn"
                                style={{
                                  backgroundColor: "#001787",
                                  border: "none",
                                  color: "#fff",
                                }}
                              >
                                Make Payment
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="card shadow border-0 mb-3 bg-gray">
                        <div className="card-body py-5 px-5">
                          <div className="fs-14 mb-0 text-dark">
                            <h6>Mandatory check-list for passengers:</h6>
                            <ul className="list-unstyled list-style-1 list-articles m-0 p-0">
                              <li>
                                Please download and activate the Aarogya Setu
                                app on your phone.
                              </li>
                              <li>
                                Certify your health status through the Aarogya
                                Setu app or the self-declaration form.
                              </li>
                              <li>
                                Remember to do web check-in before arriving at
                                the airport. Please do carry an e-boarding pass
                                on your mobile. Alternatively, you can carry the
                                printout of the boarding pass.
                              </li>
                              <li>
                                Please reach at least 2 hours prior to flight
                                departure
                              </li>
                              <li>
                                No meal service will be available on-board.
                              </li>
                              <li>
                                Face masks are compulsory. We urge you to carry
                                your own
                              </li>
                              <li>
                                You are requested to print and paste the baggage
                                tag attached to your booking confirmation.
                                Alternatively, you can write your name, PNR and
                                flight number on an A4 sheet and affix on your
                                bag.
                              </li>
                              <li>
                                Aliquam rhoncus ante a neque molestie, sed
                                euismod odio facilisis.
                              </li>
                              <li>Nunc eu arcu ut odio varius dapibus.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <>
                      <div className="col-lg-3 rightSidebar">
                        <div className="card shadow border-0 mb-3">
                          <div className="card-body">
                            <h3 className="fs-17 mb-0">Fare Summary</h3>
                            <p className="fs-14">
                              Travelers {travelersQuantityHandler()}
                            </p>
                            <hr />
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="summary-text">
                                <div className="font-weight-500">Base Fare</div>
                                <div className="fs-13 text-black-50">
                                  {travelersQuantityHandler()}
                                </div>
                              </div>
                              <div className="fs-19 font-weight-500 base-fare">
                                {
                                  new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: currency,
                                  })
                                    .format(baseFare)
                                    .split(".")[0]
                                }
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="summary-text">
                                <div className="font-weight-500">Taxes</div>
                              </div>
                              <div className="fs-19 font-weight-500">
                                {
                                  new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: currency,
                                  })
                                    .format(totalTaxAmount)
                                    .split(".")[0]
                                }
                              </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="summary-text">
                                <div className="font-weight-500">
                                  AIT Charge
                                </div>
                              </div>
                              <div className="fs-19 font-weight-500">
                                {
                                  new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: currency,
                                  })
                                    .format(adminCharge)
                                    .split(".")[0]
                                }
                              </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="summary-text">
                                <div className="font-weight-500">
                                  SSL Charge
                                </div>
                              </div>
                              <div className="fs-19 font-weight-500">
                                {
                                  new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: currency,
                                  })
                                    .format(sslCharge)
                                    .split(".")[0]
                                }
                              </div>
                            </div>

                            <hr />
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="">
                                <div className="fs-19 font-weight-500 _t_amount">
                                  Total Amount:
                                </div>
                              </div>
                              {discountObject !== null ? (
                                <div className="fs-23 font-weight-500 text-danger">
                                  <del>{finalCost}</del> ৳
                                </div>
                              ) : (
                                <div className="fs-23 font-weight-500">
                                  {chargeDetails.commission_percentage > 0 && (
                                    <p
                                      style={{
                                        textDecoration: "line-through",
                                        color: "red",
                                        fontSize: "18px",
                                        textAlign: "center",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {
                                        new Intl.NumberFormat("en-IN", {
                                          style: "currency",
                                          currency: currency,
                                        })
                                          .format(finalCost)
                                          .split(".")[0]
                                      }
                                    </p>
                                  )}

                                  <p className="m-0">
                                    {
                                      new Intl.NumberFormat("en-IN", {
                                        style: "currency",
                                        currency: currency,
                                      })
                                        .format(finalCostWithCommission)
                                        .split(".")[0]
                                    }
                                  </p>
                                </div>
                              )}
                            </div>
                            {discountObject !== null ? (
                              <div className="d-flex align-items-center justify-content-between">
                                <div className>
                                  <div className="fs-19 font-weight-500">
                                    After Discount:
                                  </div>
                                </div>
                                <div className="fs-23 font-weight-500 text-success">
                                  <>
                                    {discountObject?.data?.amount > 0 ? (
                                      <>
                                        {
                                          new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: currency,
                                          })
                                            .format(
                                              finalCostWithCommission -
                                                discountObject?.data?.amount
                                            )
                                            .split(".")[0]
                                        }
                                      </>
                                    ) : null}
                                  </>
                                  ৳
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="card shadow border-0 mb-3">
                          <div className="card-body">
                            <h3 className="fs-18 mb-1">Apply Discount</h3>
                            <p className="fs-14">
                              Have a discount/ promo code to redeem
                            </p>

                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control bg-white shadow-none me-1"
                                placeholder="Promo Code"
                                onChange={(eve) =>
                                  setCouponCode(eve.target.value)
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    backgroundColor: "#001787",
                                    border: "none",
                                  }}
                                  type="button"
                                  disabled={
                                    couponCode.length === 0 ? true : false
                                  }
                                  onClick={sendRequestForDiscount}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BookTicket;
