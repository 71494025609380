export const TotalFareAfterComission = (fare) => {
  const comission = parseFloat(localStorage.getItem("_com_pc"));
  const comissionType = localStorage.getItem("commission_type");

  if (comissionType === "percent") {
    let baseFare = fare.equivalentAmount;
    let totaltaxAmount = fare.totalTaxAmount;
    let newFare = Math.ceil(
      baseFare - (baseFare * comission) / 100 + totaltaxAmount
    );
    return newFare;
  } else {
    let baseFare = fare.equivalentAmount;
    let totaltaxAmount = fare.totalTaxAmount;
    let newFare = Math.ceil(baseFare - comission + totaltaxAmount);
    return newFare;
  }
};

export const TotalFareAfterComissionGalileo = (bases, taxss) => {
  const comission = parseFloat(localStorage.getItem("_com_pc"));
  const comissionType = localStorage.getItem("commission_type");

  const base = localStorage.getItem("galileo_baseprice");
  const taxs = localStorage.getItem("galileo_TotalTaxAmount");

  if (comissionType === "percent") {
    let baseFare = parseFloat(base.split("BDT")[1]);
    let totaltaxAmount = parseFloat(taxs.split("BDT")[1]);
    let newFare = Math.ceil(
      baseFare - (baseFare * comission) / 100 + totaltaxAmount
    );
    return newFare;
  } else {
    let baseFare = parseFloat(base.split("BDT")[1]);
    let totaltaxAmount = parseFloat(taxs.split("BDT")[1]);
    let newFare = Math.ceil(baseFare - comission + totaltaxAmount);
    return newFare;
  }
};

export const TotalFareAfterComissionGalileo_2 = (base, taxs) => {
  const comission = parseFloat(localStorage.getItem("_com_pc"));
  const comissionType = localStorage.getItem("commission_type");

  // const base = localStorage.getItem('galileo_baseprice')
  // const taxs = localStorage.getItem('galileo_TotalTaxAmount')

  if (comissionType === "percent") {
    let baseFare = parseFloat(base.split("BDT")[1]);
    let totaltaxAmount = parseFloat(taxs.split("BDT")[1]);
    let newFare = Math.ceil(
      baseFare - (baseFare * comission) / 100 + totaltaxAmount
    );
    return newFare;
  } else {
    let baseFare = parseFloat(base.split("BDT")[1]);
    let totaltaxAmount = parseFloat(taxs.split("BDT")[1]);
    let newFare = Math.ceil(baseFare - comission + totaltaxAmount);
    return newFare;
  }
};

export const AfterCouponSLLCharg = (
  DiscountedFair,
  totalTaxAmount,
  adminCharge,
  sslChargeType,
  sslChargeAmount
) => {
  if (sslChargeType == "percent") {
    return (
      ((parseFloat(DiscountedFair) + totalTaxAmount + adminCharge) *sslChargeAmount) / 100
    );
  } else {
    return sslChargeAmount;
  }
};
