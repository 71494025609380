import ContentLoader from 'react-content-loader'

const Loader = () => {
  return (
    <div>
      <div>
        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>

        <ContentLoader
          speed={1}
          backgroundColor={'#d2d2d2'}
          foregroundColor={'#f8f8f8'}
          viewBox='0 0 380 70'
        >
          {/* Only SVG shapes */}
          <rect x='15' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='15' y='45' rx='4' ry='4' width='30' height='13' />

          <rect x='55' y='30' rx='4' ry='4' width='200' height='13' />
          <rect x='55' y='45' rx='4' ry='4' width='200' height='13' />

          <rect x='315' y='30' rx='4' ry='4' width='30' height='13' />
          <rect x='315' y='45' rx='4' ry='4' width='30' height='13' />
        </ContentLoader>
      </div>
    </div>
  )
}

export default Loader
