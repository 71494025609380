import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader.gif";
import "./listStyle.css";
import { useQuery } from "@tanstack/react-query";
import PackagesSkeleton from "../Skeleton/PackagesSkeleton";

export default function PackageList() {
  const [packageData, setPackageData] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const url = `${process.env.REACT_APP_DEV_API_URL}/package-list`;
  const navigate = useNavigate();

  const {
    data: packageList,
    isLoading,
    error,
  } = useQuery({
    queryKey: "package-list",
    queryFn: async () => {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        setIsLoading(false);
      }

      return response.json();
    },
  });

  // useEffect(() => {
  //   const packages = localStorage.getItem("packages");

  //   if (!packages) {
  //     setPackageData(JSON.parse(packages));
  //     SetIsLoading(false);
  //   } else {
  //     fetch(url, {
  //       method: "GET",
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         const responseData = data.data;
  //         SetIsLoading(false);
  //         setPackageData(responseData);
  //         localStorage.setItem("packages", JSON.stringify(responseData));
  //       })
  //       .catch((error) => {});
  //   }
  // }, []);

  // useEffect(() => {
  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const responseData = data.body.responseData;
  //       setPackageData(responseData);
  //       SetIsLoading(false);
  //       localStorage.setItem("packages", JSON.stringify(responseData));
  //     });
  // }, []);

  return (
    <>
      {IsLoading ? (
        // <div
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     minHeight: "80vh",
        //     flexDirection: "column",
        //   }}
        // >
        //   <img src={Loader} alt="loading..." />
        //   <p>Loading The Best Packages</p>
        // </div>
        <PackagesSkeleton />
      ) : (
        <>
          <div className="container" style={{ minHeight: "70vh" }}>
            <h2
              className="text-center mt-5 mb-3"
              style={{ textTransform: "uppercase" }}
            >
              Enjoy with our best packages
            </h2>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
              className="row"
            >
              {packageList?.data?.map((dx, index) => {
                return (
                  <>
                    <div
                      className="card shadow mt-3 _card mx-2 col-md-4"
                      key={index}
                      style={{
                        display: "inline-block",
                        maxWidth: "420px",
                        minWidth: "420px",
                      }}
                    >
                      <div
                        className="card-header"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={dx.image}
                          alt="bg"
                          style={{
                            maxHeight: "168px",
                            objectFit: "cover",
                            minWidth: "-webkit-fill-available",
                          }}
                        />
                      </div>
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <header
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          {dx.package_name}
                        </header>
                        <br />
                        <p>Regular Price - {dx.amount}</p>
                        <p>
                          After Discount Current Price -{" "}
                          <span
                            className="badge "
                            style={{
                              fontSize: "14px",
                              backgroundColor: "#001787",
                              color: "#fff",
                            }}
                          >
                            {dx.amount - dx.cut_off_amount}.00
                          </span>
                        </p>
                        <button
                          style={{
                            backgroundColor: "#001787",
                            color: "#fff",
                          }}
                          className="btn "
                          onClick={() => {
                            navigate("/package-details/", {
                              state: {
                                package_name: dx.package_name,
                                summary: dx.summary,
                                src: dx.image,
                              },
                            });
                          }}
                        >
                          See Details
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "30vh",
              }}
            >
              {/* --- */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
