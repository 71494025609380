import React from "react";
import Skeleton from "react-loading-skeleton";

function VisaSkeleton() {
  return (
    <div className="container my-5">
      <Skeleton height={30} width={`40%`} className="mb-2" />
      <Skeleton height={20} width={`30%`} className="mb-4" />
      <Skeleton count={15} height={20} className="mb-2" />
    </div>
  );
}

export default VisaSkeleton;
