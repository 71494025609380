import { useEffect, useState } from "react";
import "./style.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import InfoBox from "../InfoBox";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

const screenWidth = window.screen.width;

let items = 4;

if (screenWidth <= 1024 && screenWidth > 768) {
  items = 3;
} else if (screenWidth <= 768 && screenWidth > 425) {
  items = 2;
} else if (screenWidth <= 425) {
  items = 1;
}

const HomePackage = () => {
  const [carouselData, setCarouselData] = useState([]);
  const [sliderRows, setSliderRows] = useState(2);

  const { data, isLoading, error } = useQuery({
    queryKey: "latest-flight-list",
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/latest-flight-list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    },
  });

  // useEffect(() => {
  //   fetch("http://139.59.69.198/admin/api/latest-flight-list", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.statusCode === 200) {
  //         const { responseData } = data.body;
  //         const length = responseData.length;
  //         if (length >= 8) {
  //           const _responseData = responseData;
  //           const half = Math.ceil(length / sliderRows);
  //           const _data = _responseData.splice(half, half);
  //           setCarouselData([_responseData, _data]);
  //         } else {
  //           setCarouselData([responseData]);
  //         }
  //       }
  //     })
  //     .catch((error) => {});
  // }, []);

  const infoBoxData = [
    {
      id: 1,
      title: "Simple Booking System",
      img: "/assets/img/icon/icon-1.svg",
      description:
        "Feel the flexibility and simplicity throughout your booking process.",
    },
    {
      id: 2,
      title: "Available Flight Schedule",
      img: "/assets/img/icon/icon-2.svg",
      description:
        "Skytrip has provided a lot of flight schedule Since our establishment.",
    },
    {
      id: 3,
      title: "Lowest Prices",
      img: "/assets/img/icon/icon-3.svg",
      description:
        "Skytrip is offering the lowest price in the OTA industry. Discount available for customers. ",
    },
    {
      id: 4,
      title: "24/7 Customer Support",
      img: "/assets/img/icon/icon-4.svg",
      description:
        "End to end, convenient customer support to make the travel path easy.",
    },
    {
      id: 5,
      title: "Domestic and International Flights",
      img: "/assets/img/icon/icon-5.svg",
      description:
        "Skytrip is offering all domestic and international flights from multiple GDS",
    },
    {
      id: 6,
      title: "Secure Payment",
      img: "/assets/img/icon/icon-6.svg",
      description:
        "Secure payment gateway for customers including multiple banks and MFS.",
    },
  ];

  return (
    <div>
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="section-title text-center mb-5">
                <h2 className="block-title mb-2">Our Latest Flight Details</h2>
                <div className="sub-title fs-18">
                  Book cheap flights on your favorite airlines
                </div>
              </div>
            </div>
          </div>

          {data?.data.length && (
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              dots={false}
              rows={2}
              items={items}
              autoplay={true}
              responsiveClass={true}
            >
              {data?.data.map((d, index) => (
                <Link
                  to={
                    d.trip_type == "Round Trip"
                      ? "/search-results/" +
                        d.origin +
                        "-" +
                        d.destination +
                        "--" +
                        d.start_date +
                        "--12-0-0--" +
                        d?.end_date +
                        "--12-0-0--1-0-0-Y"
                      : "/search-results/" +
                        d.origin +
                        "-" +
                        d.destination +
                        "--" +
                        d.start_date +
                        "--12-0-0--1-0-0-Y"
                  }
                  className="city-card d-flex flex-column bg-white mb-4"
                  style={{ cursor: "pointer" }}
                  key={index}
                >
                  <figure
                    className="
                  img-holder
                  flex-grow-1
                  position-relative
                  overflow-hidden
                  mb-0
                "
                  >
                    <img
                      src={d.image}
                      className="image position-absolute top-0 left-0 w-100 h-100"
                      alt="..."
                    />
                    <div className="flying-form-to">
                      <div className="d-flex align-items-center">
                        <div className="col">
                          {d.origin_city}
                          <br />
                          {d.origin_country}
                        </div>
                        <div className="card-icon px-3">
                          <svg
                            id="Component_2_1"
                            data-name="Component 2 – 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.36"
                            height="14.36"
                            viewBox="0 0 14.36 14.36"
                          >
                            <path
                              id="XMLID_17_"
                              d="M13.707,3.263H2.228L4.378,1.114A.653.653,0,1,0,3.454.191L.191,3.454a.653.653,0,0,0,0,.923L3.454,7.641a.653.653,0,1,0,.923-.923L2.228,4.569H13.707a.653.653,0,0,0,0-1.305Z"
                              transform="translate(0 0.001)"
                              fill="#fff"
                            />
                            <path
                              id="XMLID_18_"
                              d="M10.905,150.191a.653.653,0,0,0-.923.923l2.149,2.149H.653a.653.653,0,1,0,0,1.305H12.132l-2.149,2.149a.653.653,0,1,0,.923.923l3.264-3.264a.653.653,0,0,0,0-.923Z"
                              transform="translate(0 -143.473)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <div className="col">
                          {d.destination_city}
                          <br />
                          {d.destination_country}
                        </div>
                      </div>
                    </div>
                  </figure>
                  {/* <div className="city-card_inner">
                      <div className="city-card_tags">
                        {' '}
                        {d.TripType}{' '}
                      </div>
                      <h3 className="city-card_heading h5 mb-0">
                        {' '}
                        {d.Fare ? '৳ ' + d.Fare : ''}
                      </h3>
                    </div> */}
                </Link>
              ))}
            </OwlCarousel>
          )}
        </div>
      </section>

      <section className="bg-white py-5">
        <div class="container">
          <h2 class="text-center mb-5">Why SkyTrip?</h2>
          <div class="row g-4 g-md-5">
            {infoBoxData &&
              infoBoxData.map((box) => (
                <InfoBox
                  key={box.id}
                  title={box.title}
                  img={box.img}
                  description={box.description}
                />
              ))}
          </div>
        </div>
      </section>

      <section className="app-section">
        <div className="container">
          <div className="row justify-content-between align-items-center download-app">
            <div className="col-lg-5  d-lg-block text-center">
              <img src="/app.png" alt="" className="img-fluid-custom" />
            </div>
            <div className="col-lg-7 order-lg-first">
              <div className="fw-m fw-semibold text-danger">Download App</div>
              <h2 className="mb-4">
                Download Our Skytrip Mobile App To Get All Features
              </h2>
              <p className="lead fw-normal">
                Download the Skytrip mobile app for one-touch access to your
                next travel adventure. With the Skytrip.com mobile app you’ll
                get access to hidden features and special offers.
                <br />
                <br />
                <img
                  src="/assets/img/aboutApp.png"
                  alt=""
                  className="img-fluid"
                />
              </p>
              <div className="g-3 g-sm-4 row mt-1">
                <div className="col-6 col-sm-auto">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.skytrip"
                    className="d-block mb-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/google-play.png" alt="" className="img-fluid" />
                  </a>
                </div>
                <div className="col-6 col-sm-auto">
                  <a
                    href="https://apps.apple.com/to/app/skytrip-app/id1627468388"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/app-store.png" alt="" className="img-fluid" />
                  </a>
                  {/* <QRCode value="https://play.google.com/store/apps/details?id=com.skytrip" size={135} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePackage;
