import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function BookingInfoSkeleton() {
  return (
    <div className="mt-5">
      <Container>
        <Row className="mb-3">
          <Col>
            <Skeleton width="100%" height={30} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <Skeleton width="100%" height={20} />
          </Col>
          <Col xs={6}>
            <Skeleton width="100%" height={20} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <Skeleton width="100%" height={20} />
          </Col>
          <Col xs={6}>
            <Skeleton width="100%" height={20} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Skeleton width="100%" height={100} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <Skeleton width="100%" height={40} />
          </Col>
          <Col xs={6}>
            <Skeleton width="100%" height={40} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BookingInfoSkeleton;
