import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function MakeBookingSkeleton() {
  return (
    <div
      className="container d-flex justify-content-center "
      style={{
        height: "80vh",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          background: "#f1f1f1",
          color: "#000",
          marginTop: "80px",
          marginBottom: "30px",
          height: "550px",
          width: "350px",
          marginBottom: "10px",
          paddingTop: "50px",
          position: "relative",
        }}
        className="p-1 p-md-4 "
      >
        <div
          className="d-flex justify-content-center "
          style={{
            position: "absolute",
            left: "35%",
            top: "-50px",
          }}
        >
          <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
            <Skeleton
              style={{
                borderRadius: "50%",
                width: "100px",
                height: "100px",
              }}
            />
          </SkeletonTheme>
        </div>
        <div className="mt-5">
          <div
            className="d-flex justify-content-between flex-wrap"
            style={{
              padding: "10px 70px",
            }}
          >
            {" "}
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />
            </SkeletonTheme>
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />
            </SkeletonTheme>
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />
            </SkeletonTheme>
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />
            </SkeletonTheme>
          </div>{" "}
          <div className="d-flex justify-content-between flex-wrap">
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  width: "100px",

                  height: "30px",
                }}
              />
            </SkeletonTheme>
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  width: "100px",

                  height: "30px",
                }}
              />
            </SkeletonTheme>
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  width: "100px",

                  height: "30px",
                }}
              />
            </SkeletonTheme>
          </div>{" "}
          <div className="mt-2 d-none d-lg-block">
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  width: "100%",

                  height: "10px",
                }}
              />
            </SkeletonTheme>{" "}
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  width: "100%",

                  height: "40px",
                }}
              />
            </SkeletonTheme>
          </div>
          <div className="mt-2 d-none d-lg-block">
            <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
              <Skeleton
                style={{
                  width: "100%",

                  height: "10px",
                }}
              />
            </SkeletonTheme>{" "}
            <div className="d-flex justify-content-between">
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "140px",

                    height: "40px",
                  }}
                />
              </SkeletonTheme>
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "140px",

                    height: "40px",
                  }}
                />
              </SkeletonTheme>
            </div>{" "}
            <div className="mt-2">
              {" "}
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "100%",

                    height: "40px",
                  }}
                />
              </SkeletonTheme>
            </div>
            <div className="mt-2">
              {" "}
              <SkeletonTheme baseColor="#e4e4e4" highlightColor="#d8d6d6">
                <Skeleton
                  style={{
                    width: "100%",

                    height: "100px",
                  }}
                />
              </SkeletonTheme>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MakeBookingSkeleton;
