import React, { useState, useEffect } from "react";

import "./Visa.css";
import { Spinner } from "react-bootstrap";
import Loader from "./Loader.gif";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import VisaSkeleton from "../Skeleton/VisaSkeleton";
import VisaListSkeleton from "../Skeleton/VisaListSkeleton";

export default function Visa() {
  const [currentID, setCurrentID] = useState();
  const [isButtonClicked] = useState(false);
  const [visaInfo, setVisaInfo] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryListLoading, setCountryListLoading] = useState(true);

  const countryHandler = (e) => {
    console.log(e.target.value);
    setCurrentID(e.target.value);
  };

  const url = `${process.env.REACT_APP_DEV_API_URL}/visa-info-list`;
  // const {
  //   data: countryList,
  //   isLoading,
  //   error,
  // } = useQuery({
  //   queryKey: "package-list",
  //   queryFn: async () => {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.json();
  //   },
  // });

  useEffect(() => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCountryListLoading(false);
        setCountryList(data);
      })
      .catch((error) => {
        setCountryListLoading(false);
      });
  }, []);

  console.log(countryList);

  useEffect(() => {
    if (!currentID) return;
    else {
      setLoading(true);
      const dataUrl = `${process.env.REACT_APP_DEV_API_URL}/visa-details/${currentID}`;
      fetch(dataUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setVisaInfo(data.data);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        });
    }
  }, [currentID]);

  return (
    <div>
      <div className="search-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              {countryListLoading ? (
                // <div
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     minHeight: "80vh",
                //     flexDirection: "column",
                //   }}
                // >
                //   <img src={Loader} alt="loading..." />
                //   <p>Loading visa info...</p>
                // </div>
                <VisaListSkeleton />
              ) : (
                <div>
                  <h4>Select your desired country</h4>
                  <div className="wrapper">
                    <select className="brand-input" onChange={countryHandler}>
                      {countryList?.data?.map((data) => {
                        return (
                          <option key={data?.id} value={data.id}>
                            {data.country}
                          </option>
                        );
                      })}
                    </select>
                    <div className="btn-wrapper">
                      {/* <button className="check-btn brand-btn" onClick={() => {
                    setIsButtonClicked(!isButtonClicked);
                    console.log(isButtonClicked);
                  }}>
                    <span>Check</span>
                  </button> */}
                    </div>
                  </div>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ minHeight: "70vh" }}>
        {loading ? (
          // <div
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     minHeight: "50vh",
          //   }}
          // >
          //   <img src={Loader} alt="loader" />
          // </div>
          <VisaSkeleton />
        ) : visaInfo !== undefined ? (
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: visaInfo.information }}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
