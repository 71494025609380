import { useEffect, useState } from "react";
import InputRange from "react-input-range";
import { Link } from "react-router-dom";

const SearchSidebar = ({
  flight,
  filteredAirlines,
  setFilteredAirlines,
  minPrice,
  maxPrice,
  valslider,
  setValslider,
}) => {
  const [airlineFilter, setAirlineFilter] = useState([]);
  const [nonStopFlight, setNonStopFlight] = useState(false);
  const [oneStopFlight, setoneStopFlight] = useState(false);
  const [oneplusStopFlight, setoneplusStopFlight] = useState(false);

  const [takeOff1, setTakeOff1] = useState(false);
  const [refresh, setrefresh] = useState(true);
  const [takeOff2, setTakeOff2] = useState(false);

  const [minPriceFast, setminPriceFast] = useState(0);
  const [stopLength, setStopLength] = useState(0);

  let airlines = [];

  flight?.map((item) => {
    if (!airlines.some((el) => el.airlineName === item.airline_name)) {
      airlines.push({
        airlineImg: item.airline_logo,
        airlineName: item.airline_name,
        airlineCode: item.airline_code,
      });
    }
  });

  const timeFormatter = (time) => {
    const timeNumber = parseInt(time);
    return timeNumber;
  };

  // useEffect(() => {
  //   if (airlineFilter.length > 0) {
  //     const filteredFlight = flight?.filter((item) => {
  //       return airlineFilter.includes(item.airline_code);
  //     });
  //     if (takeOff1) {
  //       const takeOff1Filter = filteredFlight?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 6 &&
  //           timeFormatter(item?.departure_time) <= 12
  //         );
  //       });
  //       setFilteredAirlines(takeOff1Filter);
  //       setStopLength(takeOff1Filter.length);
  //     } else if (takeOff2) {
  //       const takeOff2Filter = filteredFlight?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 12 &&
  //           timeFormatter(item?.departure_time) <= 18
  //         );
  //       });
  //       setFilteredAirlines(takeOff2Filter);
  //       setStopLength(takeOff2Filter.length);
  //     } else if (nonStopFlight) {
  //       const nonStopFilter = filteredFlight?.filter((item) => {
  //         return item.total_stop === 0;
  //       });
  //       if (takeOff1) {
  //         const takeOff1Filter = nonStopFilter?.filter((item) => {
  //           return (
  //             timeFormatter(item?.departure_time) >= 6 &&
  //             timeFormatter(item?.departure_time) <= 12
  //           );
  //         });
  //         setFilteredAirlines(takeOff1Filter);
  //         setStopLength(takeOff1Filter.length);
  //       } else if (takeOff2) {
  //         const takeOff2Filter = nonStopFilter?.filter((item) => {
  //           return (
  //             timeFormatter(item?.departure_time) >= 12 &&
  //             timeFormatter(item?.departure_time) <= 18
  //           );
  //         });
  //         setFilteredAirlines(takeOff2Filter);
  //         setStopLength(takeOff2Filter.length);
  //       } else {
  //         setFilteredAirlines(nonStopFilter);
  //         setStopLength(nonStopFilter.length);
  //       }
  //     } else if (oneStopFlight) {
  //       const oneStopFilter = filteredFlight?.filter((item) => {
  //         return item.total_stop === 1;
  //       });
  //       if (takeOff1) {
  //         const takeOff1Filter = oneStopFilter?.filter((item) => {
  //           return (
  //             timeFormatter(item?.departure_time) >= 6 &&
  //             timeFormatter(item?.departure_time) <= 12
  //           );
  //         });
  //         setFilteredAirlines(takeOff1Filter);
  //         setStopLength(takeOff1Filter.length);
  //       } else if (takeOff2) {
  //         const takeOff2Filter = oneStopFilter?.filter((item) => {
  //           return (
  //             timeFormatter(item?.departure_time) >= 12 &&
  //             timeFormatter(item?.departure_time) <= 18
  //           );
  //         });
  //         setFilteredAirlines(takeOff2Filter);
  //         setStopLength(takeOff2Filter.length);
  //       } else {
  //         setFilteredAirlines(oneStopFilter);
  //         setStopLength(oneStopFilter.length);
  //       }
  //     } else if (oneplusStopFlight) {
  //       const oneplusStopFilter = filteredFlight?.filter((item) => {
  //         return item.total_stop >= 2;
  //       });
  //       if (takeOff1) {
  //         const takeOff1Filter = oneplusStopFilter?.filter((item) => {
  //           return (
  //             timeFormatter(item?.departure_time) >= 6 &&
  //             timeFormatter(item?.departure_time) <= 12
  //           );
  //         });
  //         setFilteredAirlines(takeOff1Filter);
  //         setStopLength(takeOff1Filter.length);
  //       } else if (takeOff2) {
  //         const takeOff2Filter = oneplusStopFilter?.filter((item) => {
  //           return (
  //             timeFormatter(item?.departure_time) >= 12 &&
  //             timeFormatter(item?.departure_time) <= 18
  //           );
  //         });
  //         setFilteredAirlines(takeOff2Filter);
  //         setStopLength(takeOff2Filter.length);
  //       } else {
  //         setFilteredAirlines(oneplusStopFilter);
  //         setStopLength(oneplusStopFilter.length);
  //       }
  //     } else {
  //       setFilteredAirlines(filteredFlight);
  //     }
  //   } else if (nonStopFlight) {
  //     const nonStopFilter = flight?.filter((item) => {
  //       return item.total_stop === 0;
  //     });
  //     if (takeOff1) {
  //       const takeOff1Filter = nonStopFilter?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 6 &&
  //           timeFormatter(item?.departure_time) <= 12
  //         );
  //       });
  //       setFilteredAirlines(takeOff1Filter);
  //       setStopLength(takeOff1Filter.length);
  //     } else if (takeOff2) {
  //       const takeOff2Filter = nonStopFilter?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 12 &&
  //           timeFormatter(item?.departure_time) <= 18
  //         );
  //       });
  //       setFilteredAirlines(takeOff2Filter);
  //       setStopLength(takeOff2Filter.length);
  //     } else {
  //       setFilteredAirlines(nonStopFilter);
  //       setStopLength(nonStopFilter.length);
  //     }
  //   } else if (oneStopFlight) {
  //     const oneStopFilter = flight?.filter((item) => {
  //       return item.total_stop === 1;
  //     });
  //     if (takeOff1) {
  //       const takeOff1Filter = oneStopFilter?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 6 &&
  //           timeFormatter(item?.departure_time) <= 12
  //         );
  //       });
  //       setFilteredAirlines(takeOff1Filter);
  //       setStopLength(takeOff1Filter.length);
  //     } else if (takeOff2) {
  //       const takeOff2Filter = oneStopFilter?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 12 &&
  //           timeFormatter(item?.departure_time) <= 18
  //         );
  //       });
  //       setFilteredAirlines(takeOff2Filter);
  //       setStopLength(takeOff2Filter.length);
  //     } else {
  //       setFilteredAirlines(oneStopFilter);
  //       setStopLength(oneStopFilter.length);
  //     }
  //   } else if (oneplusStopFlight) {
  //     const oneplusStopFilter = flight?.filter((item) => {
  //       return item.total_stop >= 2;
  //     });
  //     if (takeOff1) {
  //       const takeOff1Filter = oneplusStopFilter?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 6 &&
  //           timeFormatter(item?.departure_time) <= 12
  //         );
  //       });
  //       setFilteredAirlines(takeOff1Filter);
  //       setStopLength(takeOff1Filter.length);
  //     } else if (takeOff2) {
  //       const takeOff2Filter = oneplusStopFilter?.filter((item) => {
  //         return (
  //           timeFormatter(item?.departure_time) >= 12 &&
  //           timeFormatter(item?.departure_time) <= 18
  //         );
  //       });
  //       setFilteredAirlines(takeOff2Filter);
  //       setStopLength(takeOff2Filter.length);
  //     } else {
  //       setFilteredAirlines(oneplusStopFilter);
  //       setStopLength(oneplusStopFilter.length);
  //     }
  //   } else if (takeOff1) {
  //     const takeOff1Filter = flight?.filter((item) => {
  //       return (
  //         timeFormatter(item?.departure_time) >= 6 &&
  //         timeFormatter(item?.departure_time) <= 12
  //       );
  //     });
  //     setFilteredAirlines(takeOff1Filter);
  //     setStopLength(takeOff1Filter.length);
  //   } else if (takeOff2) {
  //     const takeOff2Filter = flight?.filter((item) => {
  //       return (
  //         timeFormatter(item?.departure_time) >= 12 &&
  //         timeFormatter(item?.departure_time) <= 18
  //       );
  //     });
  //     setFilteredAirlines(takeOff2Filter);
  //     setStopLength(takeOff2Filter.length);
  //   } else {
  //     setFilteredAirlines(flight);
  //   }
  // }, [
  //   airlineFilter,
  //   flight,
  //   nonStopFlight,
  //   oneStopFlight,
  //   oneplusStopFlight,
  //   takeOff1,
  //   takeOff2,
  //   refresh,
  // ]);
  useEffect(() => {
    const filterByAirline = (flights) => {
      return airlineFilter.length > 0
        ? flights?.filter((item) => airlineFilter.includes(item.airline_code))
        : flights;
    };

    const filterByStops = (flights) => {
      if (nonStopFlight) {
        return flights?.filter((item) => item.total_stop === 0);
      } else if (oneStopFlight) {
        return flights?.filter((item) => item.total_stop === 1);
      } else if (oneplusStopFlight) {
        return flights?.filter((item) => item.total_stop >= 2);
      } else {
        return flights;
      }
    };

    const filterByTakeOffTime = (flights) => {
      if (takeOff1) {
        return flights?.filter((item) => {
          const departureTime = timeFormatter(item?.departure_time);
          return departureTime >= 6 && departureTime <= 12;
        });
      } else if (takeOff2) {
        return flights?.filter((item) => {
          const departureTime = timeFormatter(item?.departure_time);
          return departureTime >= 12 && departureTime <= 18;
        });
      } else {
        return flights;
      }
    };

    const filterByPrice = (flights, min, max) => {
      return flights?.filter(
        (item) =>
          item?.fare_details?.total_fare >= min &&
          item?.fare_details?.total_fare <= max
      );
    };

    const applyFilters = () => {
      let filteredFlight = filterByAirline(flight);
      filteredFlight = filterByStops(filteredFlight);
      filteredFlight = filterByTakeOffTime(filteredFlight);
      filteredFlight = filterByPrice(
        filteredFlight,
        valslider.min,
        valslider.max
      );

      setFilteredAirlines(filteredFlight);
      setStopLength(filteredFlight?.length || 0);
    };

    applyFilters();
  }, [
    airlineFilter,
    flight,
    nonStopFlight,
    oneStopFlight,
    oneplusStopFlight,
    takeOff1,
    takeOff2,
    valslider,
    refresh,
  ]);

  return (
    <div className="sticky-sidebar">
      <div>
        <div className="m-1 ">
          <span>
            {filteredAirlines?.length > 0
              ? filteredAirlines?.length + " " + "Flights found"
              : ""}{" "}
          </span>
        </div>{" "}
        <div
          style={{
            backgroundColor: "white",
            // height: "100vh",
            borderRadius: "10px",
            boxShadow: "0 8px 24px hsla(210, 8%, 62%, .2)",
          }}
          className="p-3 "
        >
          <div className="">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="fs-16 mb-0 d-inline-block">Airlines </h4>
                <span className="text-muted fs-14 ms-1">
                  {airlines?.length > 0 ? <>({airlines?.length})</> : null}{" "}
                </span>
              </div>

              {/* <p
              style={{
                fontSize: "13px",
                fontWeight: "500",
                color: "#007bff",
                cursor: "pointer",
              }}
            >
              Clear
            </p> */}
              <a
                href="#!"
                className="reset fs-12 font-weight-500"
                onclick={() => {
                  setAirlineFilter([]);
                }}
              >
                Reset
              </a>
            </div>
            <div>
              <div
                className="row no-gutters "
                style={{
                  gap: "10px",
                  padding: "20px 5px",
                  maxHeight: "300px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                  scrollBehavior: "smooth",
                  scrollbarColor: "rgba(0, 0, 0, 0.1) white",
                }}
              >
                {airlines?.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAirlineFilter([
                            ...airlineFilter,
                            item.airlineCode,
                          ]);
                        } else {
                          setAirlineFilter(
                            airlineFilter.filter(
                              (el) => el !== item.airlineCode
                            )
                          );
                        }
                      }}
                      type="checkbox"
                      className="form-check-input"
                      name="airlineCode"
                      id=""
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <img
                      src={item?.airlineImg}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {item?.airlineName}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <div className="mb-3 d-flex justify-content-between align-items-end">
              <h4 className="fs-16 mb-0">Price Range</h4>
            </div>

            <div className="row no-gutters">
              <div className="col">
                <div className="">
                  <span>
                    {" "}
                    {valslider.min} - {valslider.max}{" "}
                  </span>
                  {maxPrice > 0 && (
                    <InputRange
                      formatLabel={(value) => null}
                      draggableTrack={false}
                      allowSameValues={false}
                      maxValue={maxPrice}
                      minValue={minPrice}
                      value={valslider}
                      onChange={setValslider}
                      onChangeComplete={(args) => {
                        setrefresh(false);
                        setrefresh(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="mb-3 ">
            <div className="mb-3 d-flex justify-content-between align-items-end">
              <h4 className="fs-16 mb-0">Stops Type</h4>
              <Link
                to="#!"
                className="reset fs-12 font-weight-500"
                onClick={(e) => {
                  setNonStopFlight(false);
                  setoneStopFlight(false);
                  setoneplusStopFlight(false);
                  setrefresh(true);
                }}
              >
                Reset
              </Link>
            </div>

            <div
              className="row no-gutters "
              style={{
                padding: "15px",
              }}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  name="stops"
                  type="checkbox"
                  id="defaultCheck1"
                  style={{
                    cursor: "pointer",
                  }}
                  onChange={(e) => {
                    setNonStopFlight(e.target.checked);
                    setoneStopFlight(false);
                    setoneplusStopFlight(false);
                    setrefresh(true);
                  }}
                  checked={nonStopFlight}
                />
                <label
                  className="form-check-label ms-2 d-flex justify-content-between"
                  htmlFor="stops1"
                >
                  <span>
                    Non Stop
                    <span className="count text-muted fs-12 ms-1">
                      {/* ({nonStopLength}) */}
                    </span>
                  </span>
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="stops"
                  id="defaultCheck1"
                  style={{
                    cursor: "pointer",
                  }}
                  onChange={(e) => {
                    setoneStopFlight(e.target.checked);
                    setNonStopFlight(false);
                    setoneplusStopFlight(false);
                    setrefresh(true);
                  }}
                  checked={oneStopFlight}
                />
                <label
                  className="form-check-label ms-2 d-flex justify-content-between"
                  htmlFor="stops1"
                >
                  <span>
                    One Stop
                    <span className="count text-muted fs-12 ms-1">
                      {/* ({oneStopLength}) */}
                    </span>
                  </span>
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  name="stops"
                  type="checkbox"
                  id="defaultCheck1"
                  style={{
                    cursor: "pointer",
                  }}
                  onChange={(e) => {
                    setoneplusStopFlight(e.target.checked);
                    setNonStopFlight(false);
                    setoneStopFlight(false);
                    setrefresh(true);
                  }}
                  checked={oneplusStopFlight}
                />
                <label
                  className="form-check-label ms-2 d-flex justify-content-between"
                  htmlFor="stops1"
                >
                  <span>
                    Many Stop
                    <span className="count text-muted fs-12 ms-1">
                      {/* ({oneplusStopLength}) */}
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <div className="mb-3 d-flex justify-content-between align-items-end">
              <h4 className="fs-16 mb-0">Take off</h4>
              <a
                href="#!"
                className="reset fs-12 font-weight-500"
                onClick={() => {
                  setTakeOff1(false);
                  setTakeOff2(false);
                  setrefresh(true);
                }}
              >
                Reset
              </a>
            </div>

            <div
              className="row no-gutters"
              style={{
                padding: "15px",
              }}
            >
              <div className="form-check  fs-14">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="takeOff1"
                  name="takeOff"
                  style={{
                    cursor: "pointer",
                  }}
                  onChange={(e) => {
                    setTakeOff1(e.target.checked);
                    setrefresh(true);
                  }}
                  checked={takeOff1}
                />
                <label className="form-check-label ms-2" htmlFor="stops1">
                  <span className="font-weight-bold">6</span>
                  &nbsp;AM -<span className="font-weight-bold">12</span>
                  &nbsp;PM
                </label>
              </div>

              <div className="form-check custom-checkbox fs-14">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="takeOff2"
                  name="takeOff"
                  style={{
                    cursor: "pointer",
                  }}
                  onChange={(e) => {
                    setTakeOff2(e.target.checked);
                    setrefresh(true);
                  }}
                  checked={takeOff2}
                />
                <label className="form-check-label ms-2" htmlFor="stops1">
                  <span className="font-weight-bold">12</span>
                  &nbsp;PM -<span className="font-weight-bold">6</span>
                  &nbsp;AM
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSidebar;
