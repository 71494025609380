import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { mainContextApi } from "../App";

const RequireAuth = ({ component: Component, ...rest }) => {
  const userContext = useContext(mainContextApi);

  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" replace={true} />;
  } else {
    return <Outlet />;
  }
};

export default RequireAuth;
