/* eslint-disable */
import React, { useRef, useEffect } from "react";
import "./assets/css/style.css";
import unlock from "./assets/img/unlock.svg";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  const iref1 = useRef();
  const iref2 = useRef();
  const iref3 = useRef();
  const iref4 = useRef();
  const iref5 = useRef();

  const navigate = useNavigate();
  const [isDisable, setIsDisable] = React.useState(false);
  const [one, setOne] = React.useState("");
  const [two, setTwo] = React.useState("");
  const [three, setThree] = React.useState("");
  const [four, setFour] = React.useState("");
  const [five, setFive] = React.useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["_jwt"]);

  useEffect(() => {
    if (cookies._jwt === undefined) {
      window.location.assign("/");
    }

    toast.success("Please Check your mail", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const verification_code = one + two + three + four + five;

    const bodyData = JSON.stringify({
      token: cookies._jwt,
      verification_code: verification_code,
    });

    setIsDisable(true);
    fetch(process.env.REACT_APP_USER_REGISTER_VERIFICATION_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_USER_REGISTER_VERIFICATION_X_API,
      },
      body: bodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.statusCode === 200) {
          toast.success("Your Account Has Been Confirmed, please login", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/login");
          removeCookie("_jwt");
          localStorage.removeItem("_info");
        }
        if (res.statusCode !== 200) {
          setIsDisable(false);
          toast.error("Please Provide Right Pin", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOne("");
          setTwo("");
          setThree("");
          setFour("");
          setFive("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resendOTP = () => {
    const body = localStorage.getItem("_info");
    console.log(body);
    fetch(process.env.REACT_APP_USER_REGISTRATION_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_USER_REGISTRATION_X_API,
      },
      body: body,
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        console.log();
        if (response.statusCode === 200) {
          toast.success("Please Check your email and confirm the code", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCookie("_jwt", response.body.token, { path: "/" });
        } else {
          setIsDisable(false);
          setOne("");
          setTwo("");
          setThree("");
          setFour("");
          setFive("");
          toast.warning("Check your Internet", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="verification-content">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="container h-100vh d-flex align-items-center">
        <div className="col p-0">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-7 col-xl-5">
              <div className="verification-card card my-4">
                <div className="card-body px-sm-5 py-sm-5">
                  <div className="mb-5 text-center">
                    <div className="mb-4">
                      <img
                        className="avatar avatar-xxl avatar-4by3"
                        src={unlock}
                        alt="Image Description"
                      />
                    </div>
                    <h6 className="fs-21">
                      Please enter the 5-digit OTP code we sent via email:
                    </h6>
                    <p className="text-muted mb-0">
                      (We want to make sure it's you before you contact our
                      Skytrip)
                    </p>
                  </div>
                  <form
                    className="digit-group"
                    data-group-name="digits"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={onSubmitHandler}
                  >
                    <div className="otp-form d-flex justify-content-center">
                      <input
                        type="text"
                        maxLength="1"
                        id="digit-1"
                        name="digit-1"
                        data-next="digit-2"
                        value={one}
                        onChange={(e) => {
                          setOne(e.target.value);
                          iref2.current.focus();
                        }}
                        ref={iref1}
                        required
                        autoFocus
                      />
                      <input
                        type="text"
                        maxLength="1"
                        id="digit-2"
                        name="digit-2"
                        data-next="digit-3"
                        data-previous="digit-1"
                        value={two}
                        onChange={(e) => {
                          setTwo(e.target.value);
                          iref3.current.focus();
                        }}
                        ref={iref2}
                        required
                      />
                      <input
                        type="text"
                        maxLength="1"
                        id="digit-3"
                        name="digit-3"
                        data-next="digit-4"
                        data-previous="digit-2"
                        value={three}
                        onChange={(e) => {
                          setThree(e.target.value);
                          iref4.current.focus();
                        }}
                        ref={iref3}
                        required
                      />
                      <input
                        type="text"
                        maxLength="1"
                        id="digit-4"
                        name="digit-4"
                        data-previous="digit-3"
                        value={four}
                        onChange={(e) => {
                          setFour(e.target.value);
                          iref5.current.focus();
                        }}
                        ref={iref4}
                        required
                      />
                      <input
                        type="text"
                        maxLength="1"
                        id="digit-5"
                        name="digit-6"
                        data-previous="digit-4"
                        value={five}
                        onChange={(e) => {
                          setFive(e.target.value);
                        }}
                        ref={iref5}
                        required
                      />
                    </div>
                    <div className="mt-4">
                      <button
                        type="text"
                        maxLength="1"
                        className="btn btn-primary btn-lg btn-block custom-btn"
                        disabled={isDisable}
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                  <div className="text-center mt-4">
                    Didn't receive the code?
                    <br />
                    <a href="#!" onClick={resendOTP}>
                      Resend OTP
                    </a>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
