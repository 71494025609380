/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import "./topbarstyle.css";

const TopBar = () => {
  const [IsProduction, setIsProduction] = useState(true);
  const [cookie, setCookie] = useCookies(["auth_info"]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_DEV_API_URL}/application-settings`,
    })
      .then(({ data }) => {
        localStorage.setItem("_com_pc", data.data.commission_percentage);
        // localStorage.setItem(
        //   'skytripAddress',
        //   data.body.responseData.SkytripAddress
        // )
        // localStorage.setItem(
        //   'fbChatPageID',
        //   data.body.responseData.FacebookPageIDForChat
        // )
        // localStorage.setItem(
        //   'fbChatPageAppID',
        //   data.body.responseData.FacebookAppIDForChat
        // )
        // localStorage.setItem(
        //   'googleClient',
        //   data.body.responseData.GoogleClientID
        // )
        // localStorage.setItem(
        //   'googleClient',
        //   data.body.responseData.GoogleClientID
        // )
        // localStorage.setItem(
        //   'FacebookAppID',
        //   data.body.responseData.FacebookAppID
        // )
        // setIsProduction(data.body.responseData.IsProduction)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (cookie.auth_info !== undefined) {
      if (cookie.auth_info.google_info !== undefined) {
        if (localStorage.getItem("_user_id_") === null) {
          fetch(
            "https://ff12d97ufe.execute-api.ap-southeast-1.amazonaws.com/default/py_user_login_production",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": "N3IlqYYYDj2VS3wUmboT39VoEiFzDHlT9vgiB74J",
              },
              body: JSON.stringify({
                first_name: cookie.auth_info.google_info.profileObj.name,
                last_name: "",
                email: cookie.auth_info.google_info.profileObj.email,
                phone: "",
                ip_address: "",
                user_type: "user",
                login_medium: "google",
                password: "",
              }),
            }
          )
            .then((res) => res.json())
            .then((response) => {
              if (response.statusCode === 200) {
                localStorage.setItem("_user_id_", response.body.uid);
                localStorage.setItem("_user_id_slug", response.body.slug);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (cookie.auth_info.fb_info !== undefined) {
        if (localStorage.getItem("_user_id_") === null) {
          fetch(
            "https://ff12d97ufe.execute-api.ap-southeast-1.amazonaws.com/default/py_user_login_production",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": "N3IlqYYYDj2VS3wUmboT39VoEiFzDHlT9vgiB74J",
              },
              body: JSON.stringify({
                first_name: cookie.auth_info.fb_info.name,
                last_name: "",
                email: cookie.auth_info.fb_info.email,
                phone: "",
                ip_address: "",
                user_type: "user",
                login_medium: "facebook",
                password: "",
              }),
            }
          )
            .then((res) => res.json())
            .then((response) => {
              if (response.statusCode === 200) {
                localStorage.setItem("_user_id_", response.body.uid);
                localStorage.setItem("_user_id_slug", response.body.slug);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }, []);

  return (
    <div id="rakesh">
      {" "}
      <div className="topbar">
        {/* {IsProduction ? null : (
          <ul className="list-group mb-3 text-center">
            <li className="list-group-item list-group-item-warning">
              Running On Certification Mode
            </li>
          </ul>
        )} */}

        <div className="container d-flex align-items-center justify-content-between">
          <div className="topbar-text text-nowrap d-none d-md-flex align-items-center">
            <i data-feather="headphones" className="me-2"></i>
            <span className="me-1">Available 24/7 at</span>
            <a className="topbar-link" href="tel:+8801636470161">
              (880) 183-070-1422
            </a>
          </div>
          <div className="d-flex justify-content-between justify-content-md-end w-100">
            <Link className="topbar-link d-flex align-items-center" to="#">
              {" "}
            </Link>
            <div className="topbar-text dropdown disable-autohide ms-3">
              <a
                className="topbar-link"
                href="https://agent.skytrip.com/"
                target="_blank"
                rel="noreferrer"
              >
                Agent Login{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
