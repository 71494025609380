import React from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function PnrTableSkeleton() {
  const rows = Array.from({ length: 12 });
  return (
    <div className="container">
      <Table >
        <thead>
          <tr>
            <th>#</th>
            <th>PNR</th>
            <th>Transaction Id.</th>
            <th>Amount</th>
            <th>Route</th>
            <th>Date & Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((_, index) => (
            <tr key={index}>
              <td>
                <Skeleton width={20} />
              </td>
              <td>
                <Skeleton width={150} />
              </td>
              <td>
                <Skeleton width={150} />
              </td>
              <td>
                <Skeleton width={80} />
              </td>
              <td>
                <Skeleton width={50} />
              </td>
              <td>
                <Skeleton width={150} />
              </td>
              <td>
                <Skeleton width={100} height={35} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default PnrTableSkeleton;
