import SearchBar from '../Components/SerachSpace/SearchBar/SearchBar'
import HomePackage from '../Components/HomePackages/HomePackage'

const HomePage = () => {
  return (
    <div>
      <SearchBar />
      <HomePackage />
    </div>
  )
}

export default HomePage
